import { CommonService, formatTypes } from 'dashboard-services';

import ExcelUtils from './ExcelUtils';

export default (() => {
  const MAX_PARAM_LENGTH = 255
  const MAX_FORMULA_LENGTH = 8_192

  const addAndValidateParam = ({ paramName, paramValue, paramsExceedingLength, withComma = true }) => {
    const paramContent = `${paramName}=${paramValue}`
    if(paramContent.length > MAX_PARAM_LENGTH) {
      const id = CommonService.generateUID()
      paramsExceedingLength.push({ name: paramName, value: paramContent.replace(/""/g, '"'), id })
      return (withComma ? ", " : "") + id
    }
    return (withComma ? ", " : "") + `"${paramContent}"`
  }

  const formulaValidator = async ({ formula, paramsExceedingLength, address, setShowTooLongFormula, setAddress }) => {
    if(formula.length > MAX_FORMULA_LENGTH) {
      setShowTooLongFormula(true)
      CommonService.copyToClipboard(formula)
    } else {
      setShowTooLongFormula(false)
      const addressLocal = await ExcelUtils.addToFormulaCell({ formula, address, paramsExceedingLength })
      if(addressLocal) {
        setAddress(addressLocal)
      }
    }
  }

  const prepColumnHeaders = ({ csvHeaders, formatType }) => {
    if(formatType === formatTypes.NCSV) {
      return ""
    }
    return csvHeaders?.map(header => `{${Object.entries(header).filter(([, value]) => value !== undefined).map(([key, value]) => `""${key}"":""${value}""`).join(", ")}}`).join(", ")
  }

  return {
    formulaValidator,
    addAndValidateParam,
    prepColumnHeaders
  }
})()