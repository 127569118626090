import { LOG_LEVEL, LOGGER, LogsApi, prototypes } from 'dashboard-services';

import getApiConfig from 'api/ApiConfig';
import properties from 'resources/constants/properties.json';

import { AuthSingleton } from './AuthSingleton';
import GAUtils from './GAUtils';

if("".capitaliseFirstLetter === undefined) {
  prototypes.init()
}

export default (() => {

  // const disableLogInButton = async () => {
  //   await ExcelUtils.switchButton({ tabId: "NG.Tab1", groupId: "NG.Tab1.Group3", buttonId: "NG.LogIn", enabled: false })
  //   await ExcelUtils.switchButton({ tabId: "NG.Tab1", groupId: "NG.Tab1.Group3", buttonId: "NG.LogOut", enabled: true })
  //   await ExcelUtils.switchButton({ tabId: "NG.Tab1", groupId: "NG.Tab1.Group1", buttonId: "NG.DataLakeButton", enabled: true })
  //   await ExcelUtils.switchButton({ tabId: "NG.Tab1", groupId: "NG.Tab1.Group1", buttonId: "NG.TimeSeriesButton", enabled: true })
  //   await ExcelUtils.switchButton({ tabId: "NG.Tab1", groupId: "NG.Tab1.Group2", buttonId: "NG.RefreshCell", enabled: true })
  //   await ExcelUtils.switchButton({ tabId: "NG.Tab1", groupId: "NG.Tab1.Group2", buttonId: "NG.RefreshSheet", enabled: true })
  //   await ExcelUtils.switchButton({ tabId: "NG.Tab1", groupId: "NG.Tab1.Group2", buttonId: "NG.RefreshAll", enabled: true })
  //   await ExcelUtils.switchButton({ tabId: "NG.Tab1", groupId: "NG.Tab1.Group2", buttonId: "NG.UploadButton", enabled: true })
  // }
  // const enableLogInButton = async () => {
  //   await ExcelUtils.switchButton({ tabId: "NG.Tab1", groupId: "NG.Tab1.Group3", buttonId: "NG.LogIn", enabled: true })
  //   await ExcelUtils.switchButton({ tabId: "NG.Tab1", groupId: "NG.Tab1.Group3", buttonId: "NG.LogOut", enabled: false })
  //   await ExcelUtils.switchButton({ tabId: "NG.Tab1", groupId: "NG.Tab1.Group1", buttonId: "NG.DataLakeButton", enabled: false })
  //   await ExcelUtils.switchButton({ tabId: "NG.Tab1", groupId: "NG.Tab1.Group1", buttonId: "NG.TimeSeriesButton", enabled: false })
  //   await ExcelUtils.switchButton({ tabId: "NG.Tab1", groupId: "NG.Tab1.Group2", buttonId: "NG.RefreshCell", enabled: false })
  //   await ExcelUtils.switchButton({ tabId: "NG.Tab1", groupId: "NG.Tab1.Group2", buttonId: "NG.RefreshSheet", enabled: false })
  //   await ExcelUtils.switchButton({ tabId: "NG.Tab1", groupId: "NG.Tab1.Group2", buttonId: "NG.RefreshAll", enabled: false })
  //   await ExcelUtils.switchButton({ tabId: "NG.Tab1", groupId: "NG.Tab1.Group2", buttonId: "NG.UploadButton", enabled: false })
  // }

  const hasAccessToExcel = ({ uiEntitlements }) => {
    if(uiEntitlements?.system) { // if the user is a system, full access
      return true;
    }
    if(!uiEntitlements?.components) { // if the entitlements does not exist - no access
      return false;
    }
    return uiEntitlements.components.findIndex(item => item.name === "Excel" && (item.access === "*" || String(item.access).includes("R"))) > -1
  }

  const hasTesterAccess = ({ uiEntitlements }) => {
    if(uiEntitlements?.system) { // if the user is a system, full access
      return true;
    }
    if(!uiEntitlements?.components) { // if the entitlements does not exist - no access
      return false;
    }
    const excelComponent =  uiEntitlements.components.find(item => item.name === "Excel" && (item.access === "*" || String(item.access).includes("R")))
    if(!excelComponent) {
      return false;
    }
    return excelComponent?.subComponents?.findIndex(item => item.name === "Tester" && (item.access === "*" || String(item.access).includes("R"))) > -1

  }

  const validateToken = () => (dispatch, getState) => {
    const token = getState().primaryComponentsState?.loginState?.token
    return AuthSingleton.getInstance().refreshNGToken
      .catch(e => {
        dispatch(sendError({ error: "(ValidateToken)" + e.stack }))
      })
      .then(() => {
        if(token != null && typeof token != "undefined" && token !== "") {
          return Promise.resolve().then(() => token)
        } else {
          throw new Error("Please login before using NG custom functions")
        }
      })
  }

  const sendError = ({ error, level = LOG_LEVEL.ERROR }) => dispatch => {
    if(properties.version === "LOCALHOST") {
      return Promise.resolve();
    }
    GAUtils.sendEvent({ category: "exception", "description": error, fatal: level === LOG_LEVEL.ERROR, action: "excel_exception" })
    return new LogsApi(dispatch(getApiConfig()))
        .post({ message: error, logger: LOGGER.EXCEL, level })
        .noLogOut(true)
        .noFetching(true)
        .noErrorMessage(true)
        .build()
        .call()
        .catch(() => {})
  }

  return {
    hasAccessToExcel,
    hasTesterAccess,
    validateToken,
    sendError
  }
})()
