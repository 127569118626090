import { useEffect } from 'react';

import { CurvesIcon } from 'basic-components';

import { routing } from 'routing';
import { GAUtils } from 'utils';

import HeaderNoSearch from 'primary/HeaderNoSearch';
import AddNewCurve from './AddNewCurve';

const Curves = () => {

  useEffect(() => {
    GAUtils.sendEvent({
      category: "open",
      action: "open_curves",
      label: "open"
    })
    GAUtils.sendPageView(routing.CURVES.name)
  }, [])

  return (
    <div className="ng-office-app__authed__content ng-office-app__authed__content--curves">
      <HeaderNoSearch
          icon={(
            <CurvesIcon
                color="grey-medium"
                height={12}
                width={12}
            />
          )}
          title="CURVES CREATOR"
      />
      <div className="ng-office-app__authed__content__body">
        <AddNewCurve/>
      </div>
    </div>
  )
}

export default Curves;