import { ReduxService } from 'dashboard-services';

import { settingsCurvesActions } from 'actions/curves';
import { LocalStorageUtils } from 'utils';

export const getSettingsInitialState = () => ReduxService.updateObject(settingsCurvesActions.defaultState, LocalStorageUtils.getCurveDefaultSettings())

export default (state = getSettingsInitialState(), action) => {
  switch (action.type) {
    case settingsCurvesActions.ON_CHANGE:
      return ReduxService.updateObject(state, { [action.name]: action.value })
    case settingsCurvesActions.CLEAR: 
      return getSettingsInitialState();
    default:
      return state;
  }
}