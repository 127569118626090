import PropTypes from 'prop-types';

import { Clear, ContextMenu, Item, Menu, MenuButton, Settings as SettingsIcon } from 'basic-components';

import './ExpandedSettings.scss';
import './Components.scss';

const ExpandedSettings = ({ closeSettings, children, onClearDefaultSettings }) => (
  <div className="ng-office-app__authed__content__body__item__expanded-settings">
    <div className="ng-office-app__authed__content__body__item__expanded-settings__top">
      <div className="ng-office-app__authed__content__body__item__expanded-settings__top__left">
        <div className="ng-office-app__authed__content__body__item__expanded-settings__top__left__icon">
          <SettingsIcon
              color="grey-medium"
              height={16}
              width={16}
          />
        </div>
        <div className="ng-office-app__authed__content__body__item__expanded-settings__top__left__label">
          Settings
        </div>
      </div>
      <div className="ng-office-app__authed__content__body__item__expanded-settings__top__right">
        <ContextMenu
            align="end"
            itemsSize="large"
            menuButton={(
              <MenuButton
                  className="ng-office-app__authed__content__body__item__expanded-settings__top__right__context"
                  key="MenuButton"
              >
                <Menu
                    color="blue-bright"
                    height={16}
                    width={16}
                />
              </MenuButton>
            )}
            offsetY={16}
            portal
        >
          <Item onClick={onClearDefaultSettings}>
            Reset saved defaults
          </Item>
        </ContextMenu>
        <div 
            className="ng-office-app__authed__content__body__item__expanded-settings__top__right__clear"
            onClick={closeSettings}
        >
          <Clear
              color="grey-medium"
              height={10}
              width={10}
          />
        </div>
      </div>
    </div>
    <div className="ng-office-app__authed__content__body__item__expanded-settings__bottom">
      {children}
    </div>
  </div>
)

ExpandedSettings.propTypes = {
  children: PropTypes.any.isRequired,
  closeSettings: PropTypes.func.isRequired,
  onClearDefaultSettings: PropTypes.func.isRequired,
}

export default ExpandedSettings;