import { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Dropdown } from 'basic-components';
import { DataStorageApi, HooksService } from 'dashboard-services';

import { creatorCurvesActions } from 'actions/curves';
import getApiConfig from 'api/ApiConfig';
import { CurvesUtils } from 'utils';

import './Columns.scss';

const emptyArray = []
const Columns = () => {
  const dispatch = useDispatch(),
        selectedCurves = useSelector(state => state.curvesState.creatorState.selectedCurves) || emptyArray,
        selectedColumns = useSelector(state => state.curvesState.creatorState.selectedColumns) || undefined,
        [columns, setColumns] = useState([]),
        calls = useRef([]),
        onChange = useCallback((value, { name }) => dispatch(creatorCurvesActions.onChange(value, name)), [dispatch]),
        prevColumns = HooksService.usePrevious(columns),
        getColumns = useCallback(() => {
          const promises = []
          selectedCurves.forEach(curve => {
              const cancelablePromise = 
                new DataStorageApi(dispatch(getApiConfig()))
                  .getColumns(curve.groupName, undefined, { metadatas: { [CurvesUtils.ROOT]: curve.root, [CurvesUtils.PRODUCT]: curve.product }, symbols: { [CurvesUtils.SYMBOL]: `${curve.root}_*` }, from: 0, size: 50, metadata: true, useEs: true })
                  .noFetching(true)
                  .cancelable(true)
                  .build()
                  .call()
              calls.current.push(cancelablePromise)
              promises.push(
                cancelablePromise.promise
                  .then(response => (response?.items || []).map(item => item?.name))
                  .catch(e => {
                    console.error(e)
                    return []
                  })
                )
          })
          return Promise.all(promises).then(promisesResponses => {
            const columns = promisesResponses.flat().toUnique().sort((a, b) => a.localeCompare(b)).map(v => ({ label: v, value: v }))
            return columns;
          })
        }, [dispatch, selectedCurves]),
        prevSelectedColumns = HooksService.usePrevious(selectedColumns),
        columnsRef = useRef()

  useEffect(() => {
    if((!prevColumns || prevColumns?.length === 0) && columns[0] && (!selectedColumns || selectedColumns?.length === 0) && (!prevSelectedColumns || prevSelectedColumns?.length === 0)) {
      columnsRef.current?.dropdown.current?.setValue(columns[0])
    }
  }, [columns, onChange, prevColumns, prevSelectedColumns, selectedColumns])

  useEffect(() => {
    getColumns().then(items => setColumns(items))
  }, [getColumns])

  useEffect(() => () => {
    calls.current?.forEach(c => c?.cancel?.())
  }, [])

  return (
    <div className="ng-office-app__authed__content__basket__body__data__columns">
      <span className="ng-office-app__authed__content__basket__body__data__columns__label">Column</span>
      <div className="ng-office-app__authed__content__basket__body__data__columns__dropdown">
        <Dropdown
            creatable
            defaultValue={selectedColumns}
            disabled={selectedCurves.length === 0}
            fixed
            menuPlacement="bottom"
            menuPortalTarget={document.querySelector('.ng-office-app')}
            name="selectedColumns"
            noError
            onChange={onChange}
            options={columns}
            placeholder="Columns"
            ref={columnsRef}
            variant="border"
        />
      </div>
    </div>
  )
}

export default Columns;