import { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';

import { ListRow } from 'basic-components';

import { creatorSeriesActions } from 'actions/series';

import Pipi from 'primary/Pipi';
import SymbolCell from './SymbolCell';

import { When } from 'react-if';

import classNames from 'classnames';
import './SymbolRow.scss';

const SymbolRow = ({ scrollContRef, isSelected, isPatternSelected, metadata, symbols, groupName, userDefinedFilters, onRowClick, symbolIndex }) => {
  const filtersValues = useMemo(() => userDefinedFilters.map(udf => {
    if(udf.type === creatorSeriesActions.FILTER_TYPE.GROUP_NAME) {
      return groupName;
    } else if(udf.type === creatorSeriesActions.FILTER_TYPE.METADATA) {
      return metadata[udf.filterKey]
    } else if(udf.type === creatorSeriesActions.FILTER_TYPE.SYMBOL) {
      return symbols[udf.filterKey]
    }
    return ""
  }), [groupName, metadata, symbols, userDefinedFilters]),
  onClick = useCallback(() => onRowClick(symbolIndex), [onRowClick, symbolIndex])

  return (
    <ListRow
        beforeChildren={<Pipi isPatternSelected={isPatternSelected}/>}
        className={classNames(
          "ng-office-app__authed__content__results__body__scroll__row",
          { "ng-office-app__authed__content__results__body__scroll__row--is-selected": isSelected }
        )}
        height={32}
        offset={80 * 20} // it is also a horizontal offset - right now max 20 columns
        onClick={onClick}
        scrollContRef={scrollContRef}
    >
      {filtersValues.map((fV, fvIndex) => (
        <SymbolCell key={`Filter${fvIndex}`}>
          {fV}
        </SymbolCell>
      ))}
      {Object.entries(symbols).filter(([symbolKey,]) => userDefinedFilters.findIndex(udf => udf.type === creatorSeriesActions.FILTER_TYPE.SYMBOL && udf.filterKey === symbolKey) === -1).map(([symbolKey,symbolValue]) => (
        <SymbolCell key={symbolKey}>
          {symbolValue}
        </SymbolCell>
      ))}
      {Object.entries(metadata).filter(([metadataKey,]) => userDefinedFilters.findIndex(udf => udf.type === creatorSeriesActions.FILTER_TYPE.METADATA && udf.filterKey === metadataKey) === -1).map(([metadataKey,metadataValue]) => (
        <SymbolCell key={metadataKey}>
          {metadataValue}
        </SymbolCell>
      ))}
      <When condition={userDefinedFilters.findIndex(udf => udf.type === creatorSeriesActions.FILTER_TYPE.GROUP_NAME) === -1}>
        {() => (
          <SymbolCell>
            {groupName}
          </SymbolCell>
        )}
      </When>
    </ListRow>
  )
}

SymbolRow.defaultProps = {
  groupName: undefined,
  isPatternSelected: false,
  isSelected: false,
  metadata: {},
  scrollContRef: undefined,
  symbolIndex: undefined,
  symbols: {},
  userDefinedFilters: []
}

SymbolRow.propTypes = {
  groupName: PropTypes.string, 
  isPatternSelected: PropTypes.bool, 
  isSelected: PropTypes.bool, 
  metadata: PropTypes.object, 
  onRowClick: PropTypes.func.isRequired,
  scrollContRef: PropTypes.any, 
  symbolIndex: PropTypes.number,
  symbols: PropTypes.object,
  userDefinedFilters: PropTypes.array
}

export default SymbolRow;