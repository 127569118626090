import { getColor } from 'basic-components';
import { ActionService, CSV_HEADERS_TYPES, DataStorageApi, dateBetweenTypes, formatTypes, tsDataRangesObject, tsKeysTypes, tsLastTypesObject, tsOrder } from 'dashboard-services';
import { globalActions } from 'primary-components';

import getApiConfig from 'api/ApiConfig';
import { LocalStorageUtils, TimeSeriesUtils } from 'utils';

import { MetadataLabel } from 'primary/TsSettings';
import { basicTimeseriesActions, keyTimeseriesActions } from './';

export default (() => {

  const defaultState = {
    keys: [],
    
    csvHeaders: TimeSeriesUtils.DEFAULT_CSV_HEADERS,
    dateFormat: TimeSeriesUtils.DAILY_DATE_FORMAT,
    formatType: formatTypes.CSV,
    order: tsOrder.DESC,
    shouldTranspose: "false",
    timeZone: undefined,
    
    range: tsDataRangesObject.last,
    lastTypeAmount: "500",
    lastType: tsLastTypesObject.value,
    
    fromSelected: undefined,
    from: undefined,
    fromTime: undefined,
    
    toSelected: undefined,
    to: undefined,
    toTime: undefined,
    
    sortKeys: "false",
    sortKeysBy: undefined,
    sortKeysOrder: tsOrder.ASC,
  }
  
  const ON_CHANGE = 'ON_CHANGE_LIST_TIME_SERIES'
  const onChange = ActionService.makeActionCreator(ON_CHANGE, 'value', 'name')

  const CLEAR = 'CLEAR_LIST_TIME_SERIES'
  const clear = ActionService.makeActionCreator(CLEAR)

  const CLEAR_SETTINGS = 'CLEAR_SETTINGS_LIST_TIME_SERIES'
  const clearSettings = ActionService.makeActionCreator(CLEAR_SETTINGS)

  const ADD_KEY = 'ADD_KEY_LIST_TIME_SERIES'
  const addKey = ActionService.makeActionCreator(ADD_KEY, 'key')

  const REMOVE_KEY = 'REMOVE_KEY_LIST_TIME_SERIES'
  const removeKey = ActionService.makeActionCreator(REMOVE_KEY, 'index')

  const DUPLICATE_KEY = 'DUPLICATE_KEY_LIST_TIME_SERIES'
  const duplicateKey = ActionService.makeActionCreator(DUPLICATE_KEY, 'index')

  const REPLACE_KEY = 'REPLACE_KEY_LIST_TIME_SERIES'
  const replaceKey = ActionService.makeActionCreator(REPLACE_KEY, 'key', 'index')

  const ON_REORDER_KEYS = 'ON_REORDER_KEYS_TIME_SERIES'
  const onReorderKeys = ActionService.makeActionCreator(ON_REORDER_KEYS, "from", "to")

  const submitKeyChanges = () => (dispatch, getState) => {
    const { columns, symbols, metadatas, groupName, index } = getState().timeseriesState.keyState,
          keyObj = { columns, symbols, metadatas, groupName }
    if(index !== undefined) {
      dispatch(replaceKey(keyObj, index))
    } else {
      dispatch(addKey(keyObj))
    }
    dispatch(keyTimeseriesActions.clear())
    dispatch(basicTimeseriesActions.onChange(false, "isEditingKey"))
  }

  const editFormula = ({ params, keys, address }) => dispatch => {
    dispatch(clear())
    dispatch(basicTimeseriesActions.clear())
    dispatch(keyTimeseriesActions.clear())
    TimeSeriesUtils.PLAIN_PARSED_TS_PARAMS.filter(param => params[param]).forEach(param => dispatch(onChange(params[param], param)))
    if(params.startDate === dateBetweenTypes.today) {
      dispatch(onChange(dateBetweenTypes.today, "fromSelected"))
      dispatch(onChange(undefined, "from"))
      dispatch(onChange(undefined, "fromTime"))
    } else if(params.startDate) {
      dispatch(onChange(dateBetweenTypes.date, "fromSelected"))
      dispatch(onChange(String(params.startDate).split("T")[0], "from"))
      dispatch(onChange(String(params.startDate).split("T")[1], "fromTime"))
    }
    if(params.endDate === dateBetweenTypes.today) {
      dispatch(onChange(dateBetweenTypes.today, "toSelected"))
      dispatch(onChange(undefined, "to"))
      dispatch(onChange(undefined, "toTime"))
    } else if(params.endDate) {
      dispatch(onChange(dateBetweenTypes.date, "toSelected"))
      dispatch(onChange(String(params.endDate).split("T")[0], "to"))
      dispatch(onChange(String(params.endDate).split("T")[1], "toTime"))
    }
    dispatch(onChange(keys, "keys"))
    dispatch(basicTimeseriesActions.onChange(false, "isEditingKey"))
    dispatch(basicTimeseriesActions.onChange(address, "address"))
  }

  const getUniqueGroupNames = keys => [...new Set(keys.map(item => item.groupName))]
  const KEYS_TYPE_ORDER = [CSV_HEADERS_TYPES.SYMBOL_KEY, CSV_HEADERS_TYPES.METADATA_KEY];
  const prepareKeysResponse = response => response
          .flatMap(p => p)
          .reduce((acc, current) => {
            if (!acc.some(obj => obj.value === current.value)) {
              acc.push(current);
            }
            return acc;
          }, [])
          .sort((a,b) => {
            const typeA = KEYS_TYPE_ORDER.indexOf(a.type)
            const typeB = KEYS_TYPE_ORDER.indexOf(b.type)
            if (typeA < typeB) return -1
            if (typeA > typeB) return 1
  
            if (a.value < b.value) return -1
            if (a.value > b.value) return 1
  
            return 0
          })

  const getKeysForGroups = ({ input, callsRefs }) => (dispatch, getState) => {
    const keys = getState().timeseriesState.listState.keys

    const uniqueGroupNames = getUniqueGroupNames(keys),
          promises = uniqueGroupNames.map((groupName, index) => {
            callsRefs.current[index]?.cancel()
            callsRefs.current[index] = new DataStorageApi(dispatch(getApiConfig()))
              .getSymbolKeys(groupName, input ? `*${input}*` :  "*", { from: 0, size: 100, metadata: true, useEs: true })
              .cancelable(true)
              .noFetching()
              .build()
              .call()

            return callsRefs.current[index].promise.then((response = {}) => 
              (response?.items || []).map(item => ({ 
                value: item.name, 
                label: item.type === tsKeysTypes.METADATA ? (<MetadataLabel name={item.name}/>) : item.name,
                color: item.type === tsKeysTypes.METADATA ? getColor("grey-dark") : undefined,
                type: `${item.type}_KEY`
              }))
            )
          })
    return Promise.all(promises).then(response => prepareKeysResponse(response))
  }

  const saveDefaultSettings = () => (_, getState) => {
    // eslint-disable-next-line no-unused-vars
    const { keys, ...itemsToSave } = getState().timeseriesState.listState
    LocalStorageUtils.setTsDefaultSettings(itemsToSave)
  }

  const clearDefaultSettings = () => dispatch => {
    // eslint-disable-next-line no-unused-vars
    const { keys, ...itemsToSave } = defaultState
    LocalStorageUtils.setTsDefaultSettings(itemsToSave)
    dispatch(clearSettings())
    dispatch(globalActions.getMessageStore()).success("Default settings has been restored.")
  }

  const getParams = () => (_, getState) => getState().timeseriesState.listState

  return {
    onChange,
    ON_CHANGE,
    CLEAR,
    clear,
    addKey,
    ADD_KEY,
    removeKey,
    REMOVE_KEY,
    REPLACE_KEY,
    submitKeyChanges,
    editFormula,
    getKeysForGroups,
    ON_REORDER_KEYS,
    onReorderKeys,
    DUPLICATE_KEY,
    duplicateKey,
    saveDefaultSettings,
    getParams,
    clearDefaultSettings,
    CLEAR_SETTINGS,
    defaultState
  }
})()