import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { ListRow } from 'basic-components';

import { creatorSeriesActions } from 'actions/series';

import Pipi from 'primary/Pipi';

import { When } from 'react-if';

import classNames from 'classnames';
import './RecentRow.scss';

const RecentRow = ({ symbol, addToBasket, isSelected, isPatternSelected }) => {
  const userDefinedFilters = useSelector(state => state.seriesState.creatorState.userDefinedFilters),
        taskPaneWidth = useSelector(state => state.globalState.taskPaneWidth),
        [isOverflowed, setIsOverflowed] = useState(false),
        addToBasketLocal = useCallback(() => addToBasket(symbol), [addToBasket, symbol]),
        getFiltersValues = useCallback(recentItem => userDefinedFilters.map(udf => {
          if(udf.type === creatorSeriesActions.FILTER_TYPE.METADATA) {
            return { value: recentItem?.metadata[udf.filterKey], label: udf.filterKey }
          } else if(udf.type === creatorSeriesActions.FILTER_TYPE.SYMBOL) {
            return { value: recentItem?.symbols[udf.filterKey], label: udf.filterKey }
          }
          return undefined
        }), [userDefinedFilters]),
        timeoutRef = useRef(),
        ref = useRef(),
        contentProps = useMemo(() => ({ ref }), [])

  useEffect(() => {
    if(ref.current) {
      setIsOverflowed(ref.current.scrollWidth > ref.current.clientWidth)
    } else {
      window.clearTimeout(timeoutRef.current)
      timeoutRef.current = window.setTimeout(() => setIsOverflowed(ref.current.scrollWidth > ref.current.clientWidth), 5)
    }
  }, [taskPaneWidth])

  useEffect(() => () => {
    window.clearTimeout(timeoutRef.current)
  }, [])

  return (
    <ListRow
        beforeChildren={<Pipi isPatternSelected={isPatternSelected}/>}
        className={classNames(
          "ng-office-app__authed__content__starting__block__content__recent-row",
          { "ng-office-app__authed__content__starting__block__content__recent-row--is-selected": isSelected },
          { "ng-office-app__authed__content__starting__block__content__recent-row--is-overflowed": isOverflowed },
        )}
        contentProps={contentProps}
        height={44}
        offset={1000}
        onClick={addToBasketLocal}
        unmountIfInvisible={false}
    >
      <div className="ng-office-app__authed__content__starting__block__content__recent-row__content">
        <div className="ng-office-app__authed__content__starting__block__content__recent-row__cell">
          {symbol.groupName}
        </div>
        <When condition={symbol.query}>
          {() => (
            <div className="ng-office-app__authed__content__starting__block__content__recent-row__cell ng-office-app__authed__content__starting__block__content__recent-row__cell--query">
              <div className="ng-office-app__authed__content__starting__block__content__recent-row__cell__label">
                Query:
              </div>
              <div className="ng-office-app__authed__content__starting__block__content__recent-row__cell__value">
                {symbol.query}
              </div>
            </div>
          )}
        </When>
        {getFiltersValues(symbol).filter(fv => !!fv && fv.value).map((fV, fvIndex) => (
          <div 
              className="ng-office-app__authed__content__starting__block__content__recent-row__cell ng-office-app__authed__content__starting__block__content__recent-row__cell--symbol"
              key={`Filter${fvIndex}`}
          >
            <div className="ng-office-app__authed__content__starting__block__content__recent-row__cell__label">
              {fV.label}:
            </div>
            <div className="ng-office-app__authed__content__starting__block__content__recent-row__cell__value">
              {fV.value}
            </div>
          </div>
        ))}
        {Object.entries(symbol.symbols || {}).filter(([symbolKey,]) => userDefinedFilters.findIndex(udf => udf.type === creatorSeriesActions.FILTER_TYPE.SYMBOL && udf.filterKey === symbolKey) === -1).map(([symbolKey,symbolValue]) => (
          <div 
              className="ng-office-app__authed__content__starting__block__content__recent-row__cell ng-office-app__authed__content__starting__block__content__recent-row__cell--symbol"
              key={symbolKey}
          >
            <div className="ng-office-app__authed__content__starting__block__content__recent-row__cell__label">
              {symbolKey}:
            </div>
            <div className="ng-office-app__authed__content__starting__block__content__recent-row__cell__value">
              {symbolValue}
            </div>
          </div>
        ))}
        {Object.entries(symbol.metadata || {}).filter(([metadataKey,]) => userDefinedFilters.findIndex(udf => udf.type === creatorSeriesActions.FILTER_TYPE.METADATA && udf.filterKey === metadataKey) === -1).map(([metadataKey,metadataValue]) => (
          <div 
              className="ng-office-app__authed__content__starting__block__content__recent-row__cell ng-office-app__authed__content__starting__block__content__recent-row__cell--symbol"
              key={metadataKey}
          >
            <div className="ng-office-app__authed__content__starting__block__content__recent-row__cell__label">
              {metadataKey}:
            </div>
            <div className="ng-office-app__authed__content__starting__block__content__recent-row__cell__value">
              {metadataValue}
            </div>
          </div>
        ))}
      </div>
    </ListRow>
  )
}

RecentRow.defaultProps = {
  isPatternSelected: false,
  isSelected: false
}

RecentRow.propTypes = {
  addToBasket: PropTypes.func.isRequired,
  isPatternSelected: PropTypes.bool,
  isSelected: PropTypes.bool,
  symbol: PropTypes.object.isRequired
}

export default RecentRow;