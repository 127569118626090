import { Fragment } from 'react';
import PropTypes from 'prop-types';

import { TextField } from 'basic-components';

const FillBlanks = ({ onChange, value }) => (
  <Fragment>
    <div className="ng-office-app__authed__content__body__item__expanded-settings__bottom__label">
      Fill Blanks
    </div>
    <div className="ng-office-app__authed__content__body__item__expanded-settings__bottom__value">
      <TextField
          defaultValue={value}
          name="fillBlanks"
          noError
          onChange={onChange}
          variant="border"
      />
    </div>
  </Fragment>
)

FillBlanks.defaultProps = {
  value: undefined,
}

FillBlanks.propTypes = {
  onChange: PropTypes.func.isRequired, 
  value: PropTypes.string,
}

export default FillBlanks;