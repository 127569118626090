import { Fragment, useMemo } from 'react';
import PropTypes from 'prop-types';

import { Dropdown } from 'basic-components';
import { ReduxService, TimeService } from 'dashboard-services';

const SOURCE = { label: "Source", value: undefined, tooltip: "Use source data time zone" }
const timeZonesWithDefault = [SOURCE].concat(TimeService.TIME_ZONES.map(t => ReduxService.updateObject(t, { tooltipPosition: "top" })))
const TimeZone = ({ onChange, timeZone }) => {
  const defaultTimeZone = useMemo(() => timeZonesWithDefault.find(t => t.value === timeZone), [timeZone])
  return (
    <Fragment>
      <div className="ng-office-app__authed__content__body__item__expanded-settings__bottom__label">
        Time zone
      </div>
      <div className="ng-office-app__authed__content__body__item__expanded-settings__bottom__value">
        <Dropdown
            defaultValue={defaultTimeZone}
            fixed
            menuPortalTarget={document.querySelector('.ng-office-app')}
            name="timeZone"
            noError
            onChange={onChange}
            options={timeZonesWithDefault}
            variant="border"
        />
      </div>
    </Fragment>
  )
}

TimeZone.defaultProps = {
  timeZone: undefined,
}

TimeZone.propTypes = {
  onChange: PropTypes.func.isRequired, 
  timeZone: PropTypes.string, 
}


export default TimeZone;