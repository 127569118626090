import { Fragment, useCallback, useState } from 'react';
import PropTypes from 'prop-types';

import { ArrowDown, ArrowUp } from 'basic-components';

import { Else, If, Then } from 'react-if';

import classNames from 'classnames';
import './CallSection.scss';

const CallSection = ({ children, icon, itemsCount, title }) => {
  const [isExpanded, setIsExpanded] = useState(),
        swapIsExpanded = useCallback(() => setIsExpanded(e => !e), [])
  if(itemsCount === 0) {
    return <Fragment/>
  }
  return (
    <div 
        className={classNames(
          "ng-office-app__authed__content__active-calls__section",
          { "ng-office-app__authed__content__active-calls__section--is-expanded": isExpanded },
        )}
        style={isExpanded ? { height: `${40 + 12 + 52 * itemsCount}px` } : undefined}
    >
      <div 
          className="ng-office-app__authed__content__active-calls__section__header"
          onClick={swapIsExpanded}
      >
        <div className="ng-office-app__authed__content__active-calls__section__header__left">
          <div className="ng-office-app__authed__content__active-calls__section__header__left__icon">
            {icon}
          </div>
          <div className="ng-office-app__authed__content__active-calls__section__header__left__title">
            {title}
          </div>
        </div>
        <div className="ng-office-app__authed__content__active-calls__section__header__right">
          <div className="ng-office-app__authed__content__active-calls__section__header__right__count">
            {itemsCount}
          </div>
          <div className="ng-office-app__authed__content__active-calls__section__header__right__arrow">
            <If condition={isExpanded}>
              <Then>
                {() => (
                  <ArrowUp
                      color="blue-bright"
                      height={20}
                      width={20}
                  />
                )}
              </Then>
              <Else>
                {() => (
                  <ArrowDown
                      color="blue-bright"
                      height={20}
                      width={20}
                  />
                )}
              </Else>
            </If>
          </div>
        </div>
      </div>
      <div className="ng-office-app__authed__content__active-calls__section__body">
        {children}
      </div>
    </div>
  )
}

CallSection.propTypes = {
  children: PropTypes.any.isRequired,
  icon: PropTypes.element.isRequired,
  itemsCount: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired
}

export default CallSection;