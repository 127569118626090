import { ReduxService } from 'dashboard-services';

import { basicDatalakeActions } from 'actions/datalake';

export const datalakeInitialState = {
  address: undefined,
  query: undefined,
}

export default (state = datalakeInitialState, action) => {
  switch (action.type) {
    case basicDatalakeActions.ON_CHANGE:
      return ReduxService.updateObject(state, { [action.name]: action.value })
    case basicDatalakeActions.CLEAR:
      return datalakeInitialState;
    default:
      return state;
  }
}