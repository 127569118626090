import { Fragment, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { ReduxService } from 'dashboard-services';

import Item from './Item';

const emptyArray = []
const Section = ({ label, filterFun }) => {
  const [hoveredIndex, setHoveredIndex] = useState(-1),
        [draggingIndex, setDraggingIndex] = useState(-1),
        selectedSymbols = useSelector(state => state.seriesState.creatorState.selectedSymbols) || emptyArray,
        userDefinedFilters = useSelector(state => state.seriesState.creatorState.userDefinedFilters) || emptyArray,
        filteredItems = useMemo(() => selectedSymbols.map((item, orgIndex) => ReduxService.updateObject(item, { orgIndex })).filter(filterFun), [filterFun, selectedSymbols]),
        scrollContRef = useRef()

  if(filteredItems.length === 0) {
    return <Fragment/>
  }

  return (
    <div className="ng-office-app__authed__content__basket__body__data__basket__section">
      <div className="ng-office-app__authed__content__basket__body__data__basket__section__title">
        {label}
      </div>
      <div 
          className="ng-office-app__authed__content__basket__body__data__basket__section__items"
          ref={scrollContRef}
      >
        {filteredItems.map((filterItem, filterIndex) => (
          <Item
              {...filterItem}
              draggingIndex={draggingIndex}
              hoveredIndex={hoveredIndex}
              index={filterIndex}
              key={`${filterIndex}`}
              setDraggingIndex={setDraggingIndex}
              setHoveredIndex={setHoveredIndex}
              userDefinedFilters={userDefinedFilters}
          />
        ))}
      </div>
    </div>
  )
}

Section.propTypes = {
  filterFun: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
}

export default Section;