import { Fragment, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';

import { Button } from 'basic-components';

import TooLongWarn from 'primary/TooLongWarn';

import { When } from 'react-if';

import './Footer.scss';

const emptyFun = () => {}
const Footer = ({ address, addToSheet, disabled, setShowTooLongFormula, showTooLongFormula }) => {
  const hideWarns = useCallback(() => setShowTooLongFormula(false), [setShowTooLongFormula])
        
  useEffect(() => {
    setShowTooLongFormula(false)
  }, [address, setShowTooLongFormula])

  return (
    <Fragment>
      <div className="ng-office-app__authed__content__basket__body__footer">
        <Button
            disabled={disabled}
            onClick={disabled ? emptyFun : addToSheet}
            size="small"
            variant="primary"
        >
          {address ? "Edit formula" : "Add to sheet"}
        </Button>
      </div>
      <When condition={showTooLongFormula}>
        {() => (
          <TooLongWarn onClose={hideWarns}/>
        )}
      </When>
    </Fragment>
  )
}

Footer.defaultProps = {
  address: undefined,
  disabled: false,
  showTooLongFormula: false
}

Footer.propTypes = {
  address: PropTypes.string,
  addToSheet: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  setShowTooLongFormula: PropTypes.func.isRequired,
  showTooLongFormula: PropTypes.bool,
}

export default Footer;