import { useCallback } from 'react';
import PropTypes from 'prop-types';

import { ExcelUtils } from 'utils';

import Row from '../Row';

const FileRow = ({ address, clearTable, formula, refreshCall, fileName, groupName }) => {
  const goToAddress = useCallback(() => ExcelUtils.goToAddress(address), [address])

  return (
    <Row
        address={address}
        formula={formula}
        onClear={clearTable}
        onRefresh={refreshCall}
    >
      <div className="ng-office-app__authed__content__active-calls__row__cell ng-office-app__authed__content__active-calls__row__cell--group">
        <div className="ng-office-app__authed__content__active-calls__row__cell__top">
          Group:
        </div>
        <div className="ng-office-app__authed__content__active-calls__row__cell__bottom">
          {groupName}
        </div>
      </div>
      <div className="ng-office-app__authed__content__active-calls__row__cell ng-office-app__authed__content__active-calls__row__cell--address">
        <div className="ng-office-app__authed__content__active-calls__row__cell__top">
          Cell reference:
        </div>
        <div 
            className="ng-office-app__authed__content__active-calls__row__cell__bottom"
            onClick={goToAddress}
        >        
          {address}
        </div>
      </div>
      <div className="ng-office-app__authed__content__active-calls__row__cell ng-office-app__authed__content__active-calls__row__cell--product">
        <div className="ng-office-app__authed__content__active-calls__row__cell__top">
          Name:
        </div>
        <div className="ng-office-app__authed__content__active-calls__row__cell__bottom">        
          {fileName}
        </div>
      </div>
    </Row>
  )
}

FileRow.defaultProps = {
  address: ""
}

FileRow.propTypes = {
  address: PropTypes.string,
  clearTable: PropTypes.func.isRequired,
  fileName: PropTypes.string.isRequired,
  formula: PropTypes.string.isRequired,
  groupName: PropTypes.string.isRequired,
  refreshCall: PropTypes.func.isRequired,
}

export default FileRow;