import { combineReducers } from 'redux';

import creatorState, { creatorInitialState } from './creator';
import settingsState, { getSettingsInitialState } from './settings';

export const getSeriesInitialState = () => ({ 
  creatorState: creatorInitialState,
  settingsState: getSettingsInitialState()
})

export default combineReducers({ 
  creatorState,
  settingsState
})