import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Search, SeriesIcon } from 'basic-components';

import { basicTimeseriesActions } from 'actions/timeseries';
import { routing } from 'routing';
import { GAUtils } from 'utils';

import HeaderNoSearch from 'primary/HeaderNoSearch';
import AddNewTimeSeries from './AddNewTimeSeries';

const TimeSeries = () => {
  const dispatch = useDispatch(),
        query = useSelector(state => state.timeseriesState.basicState.query),
        setQuery = useCallback(query => dispatch(basicTimeseriesActions.onChange(query, "query")), [dispatch])

  useEffect(() => {
    if(query) {
      GAUtils.sendEvent({
        category: "search",
        action: "search_ts",
        label: "search"
      })
    }
  }, [query])

  useEffect(() => {
    GAUtils.sendEvent({
      category: "open",
      action: "open_ts",
      label: "open"
    })
    GAUtils.sendPageView(routing.TS.name)
  }, [])

  return (
    <div className="ng-office-app__authed__content ng-office-app__authed__content--time-series">
      <HeaderNoSearch
          icon={(
            <SeriesIcon
                color="grey-medium"
                height={12}
                width={12}
            />
          )}
          title="SERIES CREATOR"
      >
        <div className="ng-office-app__authed__content__search">
          <Search
              autoFocus={false}
              defaultValue={query}
              onSearch={setQuery}
              placeholder="Search..."
              size="large"
              variant="outline"
          />
        </div>
      </HeaderNoSearch>
      <div className="ng-office-app__authed__content__body">
        <AddNewTimeSeries/>
      </div>
    </div>
  )
}

export default TimeSeries;