import { combineReducers } from 'redux';

import { globalInitialState, globalState, loginActions, primaryComponentsInitialState, primaryComponentsState } from 'primary-components';

import curvesState, { getCurvesInitialState } from './curves';
import datalakeState, { datalakeInitialState } from './datalake';
import seriesState, { getSeriesInitialState } from './series';
import settingsState, { settingsInitialState } from './settings';
import timeseriesState, { getTimeseriesInitialState } from './timeseries';
import uploadState, { uploadInitialState } from './upload';

export const getInitialState = () => ({
  curvesState: getCurvesInitialState(),
  datalakeState: datalakeInitialState,
  seriesState: getSeriesInitialState(),
  timeseriesState: getTimeseriesInitialState(),
  globalState: globalInitialState,
  primaryComponentsState: primaryComponentsInitialState,
  settingsState: settingsInitialState,
  uploadState: uploadInitialState
})

export const officeApp = (state, action) => {
  if (action.type === loginActions.LOG_OUT) {
    state = { globalState: state.globalState, primaryComponentsState: { apiConfigState: state.primaryComponentsState.apiConfigState } }
  }
  return combineReducers({
    curvesState,
    datalakeState,
    seriesState,
    settingsState,
    timeseriesState,
    uploadState,
    globalState,
    primaryComponentsState
  })(state, action)
}
