import { ActionService } from 'dashboard-services';

import { AuthUtils } from 'utils';

export default (() => {
  
  const ON_CHANGE = 'ON_CHANGE_BASIC_TIME_SERIES'
  const onChange = ActionService.makeActionCreator(ON_CHANGE, 'value', 'name')

  const CLEAR = 'CLEAR_BASIC_TIME_SERIES'
  const clear = ActionService.makeActionCreator(CLEAR)

  const hasTesterAccess = () => (_, getState) => {
    const uiEntitlements = getState().settingsState.uiEntitlements
    return AuthUtils.hasTesterAccess({ uiEntitlements })
  }

  return {
    onChange,
    ON_CHANGE,
    CLEAR,
    clear,
    hasTesterAccess
  }
})()