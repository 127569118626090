import { ActionService } from 'dashboard-services';

import { basicTimeseriesActions, listTimeseriesActions } from 'actions/timeseries';

export default (() => {
  
  const ON_CHANGE = 'ON_CHANGE_KEY_TIME_SERIES'
  const onChange = ActionService.makeActionCreator(ON_CHANGE, 'value', 'name')

  const CLEAR = 'CLEAR_KEY_TIME_SERIES'
  const clear = ActionService.makeActionCreator(CLEAR)

  const UPDATE_OBJECT = 'UPDATE_OBJECT_KEY_TIME_SERIES'
  const updateObject = ActionService.makeActionCreator(UPDATE_OBJECT, "fun", "name")

  const FILL_STATE_FROM_KEY = 'FILL_STATE_FROM_KEY_TIME_SERIES'
  const fillStateFromKey = ActionService.makeActionCreator(FILL_STATE_FROM_KEY, "key")

  const setKeyToEdit = keyIndex => (dispatch, getState) => {
    const keys = getState().timeseriesState.listState.keys
    dispatch(fillStateFromKey(keys[keyIndex] || {}))
    dispatch(onChange(keyIndex, "index"))
    dispatch(basicTimeseriesActions.onChange(true, "isEditingKey"))
  }

  const createFilter = () => (dispatch, getState) => {
    const { symbols, metadatas, columns, groupName } = getState().timeseriesState.keyState
    dispatch(listTimeseriesActions.addKey({ symbols, columns, groupName, metadata: metadatas }))
    dispatch(clear())
    dispatch(basicTimeseriesActions.onChange(false, "isEditingKey"))
  }

  return {
    onChange,
    ON_CHANGE,
    CLEAR,
    clear,
    updateObject,
    UPDATE_OBJECT,
    createFilter,
    setKeyToEdit,
    FILL_STATE_FROM_KEY
  }
})()