import { CommonService } from 'dashboard-services';

import properties from 'resources/constants/properties.json';

export default (() => {

  const setApiUrl = apiUrl => {
    localStorage.setItem("ng-api-url", apiUrl);
  }

  const getApiUrl = () => {
    const apiUrl = localStorage.getItem("ng-api-url");
    if(apiUrl === "undefined" || apiUrl === "null" || apiUrl === null) {
      return properties.baseUrl.api
    }
    return apiUrl;
  }

  const setTsDefaultSettings = defaultSettings => {
    localStorage.setItem("ng-default-ts-settings", JSON.stringify(defaultSettings));
  }

  const setCurveDefaultSettings = defaultSettings => {
    localStorage.setItem("ng-default-curve-settings", JSON.stringify(defaultSettings));
  }

  const getTsDefaultSettings = () => {
    const settingsFromLS = localStorage.getItem("ng-default-ts-settings")
    return CommonService.parseJson(settingsFromLS, {})
  }

  const getCurveDefaultSettings = () => {
    const settingsFromLS = localStorage.getItem("ng-default-curve-settings")
    return CommonService.parseJson(settingsFromLS, {})
  }

  const getSavedSeriesFilters = () => {
    const filtersFromLS = localStorage.getItem("ng-series-saved-filters")
    return CommonService.parseJson(filtersFromLS, [])
  }

  const setSavedSeriesFilters = savedFilters => localStorage.setItem("ng-series-saved-filters", JSON.stringify(savedFilters));
  
  const getRecentSymbols = () => {
    const recentSymbols = localStorage.getItem("ng-series-recent-symbols")
    return CommonService.parseJson(recentSymbols, [])
  }

  const setRecentSymbols = recentSymbols => localStorage.setItem("ng-series-recent-symbols", JSON.stringify(recentSymbols));

  const addSymbolToRecents = s => {
    let recentSymbols = getRecentSymbols() || []
    const currentIndex = recentSymbols.findIndex(rs => JSON.stringify(s) === JSON.stringify(rs))
    if(currentIndex === -1) {
      if(recentSymbols.length >= 3) {
        recentSymbols.pop()
      }
      recentSymbols.unshift(s)
    } else {
      recentSymbols = recentSymbols.remove(currentIndex)
      recentSymbols = recentSymbols.add(s, 0)
    }
    setRecentSymbols(recentSymbols)
  }

  const clearLocalStorage = () => {
    const apiUrl = getApiUrl()
    const tsDefaultSettings = getTsDefaultSettings()
    const curveDefaultSettings = getCurveDefaultSettings()
    const seriesDefaultFilters = getSavedSeriesFilters()
    const recentSymbols = getRecentSymbols()
    localStorage?.clear()
    setApiUrl(apiUrl)
    setTsDefaultSettings(tsDefaultSettings)
    setCurveDefaultSettings(curveDefaultSettings)
    setSavedSeriesFilters(seriesDefaultFilters)
    setRecentSymbols(recentSymbols)
  }

  return {
    setApiUrl,
    getApiUrl,
    setTsDefaultSettings,
    getTsDefaultSettings,
    clearLocalStorage,
    setCurveDefaultSettings,
    getCurveDefaultSettings,
    setSavedSeriesFilters,
    getSavedSeriesFilters,
    getRecentSymbols,
    setRecentSymbols,
    addSymbolToRecents
  }
})()