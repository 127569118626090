import { Fragment } from 'react';
import PropTypes from 'prop-types';

const ItemCell = ({ label, value }) => {
  if(value === "" || value === undefined) {
    return <Fragment/>
  }
  return (
    <div className="ng-office-app__authed__content__basket__body__data__basket__section__items__row__content__cell">
      <div className="ng-office-app__authed__content__basket__body__data__basket__section__items__row__content__cell__label">
        {label}:
      </div>
      <div className="ng-office-app__authed__content__basket__body__data__basket__section__items__row__content__cell__value">
        {value === "*" ? "All" : value}
      </div>
    </div>
  )
}

ItemCell.defaultProps = {
  value: undefined
}

ItemCell.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string
}

export default ItemCell;