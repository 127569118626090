import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import { creatorCurvesActions } from 'actions/curves';

import Row from 'primary/Basket/Item';
import ItemCell from 'primary/Basket/ItemCell';

const Item = ({ groupName, product, root, setHoveredIndex, setDraggingIndex, hoveredIndex, draggingIndex, index }) => {
  const dispatch = useDispatch(),
        removeItem = useCallback(() => dispatch(creatorCurvesActions.removeCurve(index)), [dispatch, index]),
        onDragAndDrop = useCallback(({ from, to }) => dispatch(creatorCurvesActions.onReorderItem(from, to)), [dispatch])

  return (
    <Row
        draggingIndex={draggingIndex}
        hoveredIndex={hoveredIndex}
        index={index}
        onDragAndDrop={onDragAndDrop}
        onRemove={removeItem}
        orgIndex={index}
        setDraggingIndex={setDraggingIndex}
        setHoveredIndex={setHoveredIndex}
    >
      <ItemCell
          label="Group"
          value={groupName}
      />
      <ItemCell
          label="Product"
          value={product}
      />
      <ItemCell
          label="Root"
          value={root}
      />
    </Row>
  )
}

Item.defaultProps = {
  draggingIndex: undefined,
  groupName: undefined,
  hoveredIndex: undefined,
  product: undefined,
  root: undefined,
}

Item.propTypes = {
  draggingIndex: PropTypes.number,
  groupName: PropTypes.string, 
  hoveredIndex: PropTypes.number,
  index: PropTypes.number.isRequired,
  product: PropTypes.string,
  root: PropTypes.string,
  setDraggingIndex: PropTypes.func.isRequired,
  setHoveredIndex: PropTypes.func.isRequired,
}

export default Item;