import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { useDispatch } from 'react-redux';

import { Button, Checkbox, Clear } from 'basic-components';
import { DataStorageApi } from 'dashboard-services';

import { creatorSeriesActions } from 'actions/series';
import getApiConfig from 'api/ApiConfig';
import { LocalStorageUtils } from 'utils';

import classNames from 'classnames';
import './FiltersSetUp.scss';

const emptyFun = () => {}
const FiltersSetUp = ({ onClose }) => {
  const dispatch = useDispatch(),
        [filters, setFilters] = useState([]),
        [allEnvKeys, setAllEnvKeys] = useState([]),
        [defaultEnvKeys, setDefaultEnvKeys] = useState([]),
        resetToDefault = useCallback(() => setFilters(defaultEnvKeys), [defaultEnvKeys]),
        saveUserFilters = useCallback(() => {
          dispatch(creatorSeriesActions.onChange({}, "filters"))
          dispatch(creatorSeriesActions.onChange(filters, "userDefinedFilters"))
          if(JSON.stringify(defaultEnvKeys) === JSON.stringify(filters)) {
            LocalStorageUtils.setSavedSeriesFilters("")
          } else {
            LocalStorageUtils.setSavedSeriesFilters(filters)
          }
          onClose()
        }, [defaultEnvKeys, dispatch, filters, onClose]),
        fetchEnvKeysRef = useRef(),
        fetchEnvFiltersSearchRef = useRef(),
        fetchEnvFiltersGetRef = useRef(),
        readUserFiltersGetRef = useRef(),
        readUserFiltersSearchRef = useRef(),
        fetchEnvKeys = useCallback(() => {
          fetchEnvKeysRef.current?.cancel?.()
          fetchEnvKeysRef.current = 
            new DataStorageApi(dispatch(getApiConfig()))
              .getSymbolKeys("", undefined, { from: 0, size: 1000, metadata: true })
              .cancelable(true)
              .build()
              .call()
          return fetchEnvKeysRef.current.promise
            .then(({ items }) => setAllEnvKeys(items))
        }, [dispatch]),
        availableFilters = useMemo(() => {
          let list = [].concat(defaultEnvKeys)
          list = list.concat([{
            label: "Group",
            type: creatorSeriesActions.FILTER_TYPE.GROUP_NAME,
            filterKey: creatorSeriesActions.FILTER_TYPE.GROUP_NAME
          }])
          list = list.concat(allEnvKeys.map(ek => ({ label: ek.name, type: ek.type, filterKey: ek.name })).filter(ek => defaultEnvKeys.findIndex(dek => dek.filterKey === ek.filterKey && dek.type === ek.type) === -1))
          return list;
        }, [allEnvKeys, defaultEnvKeys])

  useEffect(() => {
    dispatch(creatorSeriesActions.readUserFilters({ searchRef: readUserFiltersSearchRef, getRef: readUserFiltersGetRef })).then(setFilters)
  }, [dispatch])

  useEffect(() => {
    fetchEnvKeys()
  }, [fetchEnvKeys])

  useEffect(() => {
    dispatch(creatorSeriesActions.fetchEnvFilters({ searchRef: fetchEnvFiltersSearchRef, getRef: fetchEnvFiltersGetRef })).then(setDefaultEnvKeys)
  }, [dispatch])

  useEffect(() => () => {
    fetchEnvKeysRef.current?.cancel?.()
    fetchEnvFiltersGetRef.current?.cancel?.()
    fetchEnvFiltersSearchRef.current?.cancel?.()
    readUserFiltersSearchRef.current?.cancel?.()
    readUserFiltersGetRef.current?.cancel?.()
  }, [])

  return createPortal((
    <div className="series__creator__filters-set-up">
      <div className="series__creator__filters-set-up__headers">
        <div className="series__creator__filters-set-up__left">
          Active Filters
        </div>
        <div className="series__creator__filters-set-up__right">
          <Button
              onClick={resetToDefault}
              size="small"
              variant="text"
          >
            Default
          </Button>
          <div 
              className="series__creator__filters-set-up__right__close"
              onClick={onClose}
          >
            <Clear 
                color="grey-dark"
                height={8}
                width={8}
            />
          </div>
        </div>
      </div>
      <div className="series__creator__filters-set-up__body">
        {availableFilters.map(af => {
          const itemIndex = filters.findIndex(f => f.filterKey === af.filterKey && f.type === af.type)
          return (
            <div 
                className={classNames(
                  "series__creator__filters-set-up__body__row",
                  { "series__creator__filters-set-up__body__row--is-selected": itemIndex > -1 }
                )}
                key={af.filterKey + af.type + itemIndex}
                onClick={() => setFilters(filters => {
                  if(itemIndex > -1) {
                    return filters.remove(itemIndex)
                  }
                  return filters.add(af)
                })}
            >
              <Checkbox
                  checked={itemIndex > -1}
                  disabled={itemIndex > -1 && filters.length === 1}
                  onChange={emptyFun}
              >
                {af.label}
              </Checkbox>
            </div>
          )
        })}
      </div>
      <div className="series__creator__filters-set-up__footer">
        <Button 
            onClick={saveUserFilters}
        >
          Save
        </Button>
      </div>
    </div>
  ), document.body)
}

export default FiltersSetUp;