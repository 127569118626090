import { Fragment, useCallback, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { Remove } from 'basic-components';
import { ReduxService } from 'dashboard-services';

import Draggable from 'react-draggable';

import classNames from 'classnames';
import './Item.scss';

const getTop = ({ draggingIndex, itemIndex, hoveredIndex }) => {
  if(hoveredIndex === -1) {
    return 0
  }
  const elements = document.getElementsByClassName("ng-office-app__authed__content__basket__body__data__basket__section__items__row--is-dragging") || []
  const height = (elements[0]?.clientHeight + 8) / 2
  let baseTop = 0
  if(draggingIndex === hoveredIndex) {
    return baseTop
  }
  if(itemIndex > draggingIndex && draggingIndex !== -1) {
    baseTop -= height
  } else if(itemIndex < draggingIndex && draggingIndex !== -1) {
    baseTop += height
  }
  if(hoveredIndex === -1 || draggingIndex === itemIndex) {
    return baseTop
  }
  if(hoveredIndex > itemIndex || (hoveredIndex === itemIndex && draggingIndex < itemIndex))  {
    return baseTop - height
  } else {
    return baseTop + height
  }
}

const getTransition = ({ draggingIndex, itemIndex }) => {
  if(draggingIndex !== -1 && itemIndex !== draggingIndex) {
    return "top .2s ease-in"
  } else {
    return "none"
  }
}

const prevSiblings = target => {
  const siblings = []
  let n = target;
  while(n.previousElementSibling) {
    siblings.push(n.previousElementSibling)
    n = n.previousElementSibling
  }
  return siblings;
}

const nextSiblings = target => {
  const siblings = []
  let n = target;
  while(n.nextElementSibling) {
    siblings.push(n.nextElementSibling)
    n = n.nextElementSibling
  }
  return siblings;
}

const getSiblingsAndCurrent = target => {
   const prev = prevSiblings(target) || [],
       next = nextSiblings(target) || [];
   return prev.concat([target]).concat(next);
}

const STARTING_POSITION = ({ y: 0, x: 0 })
const Item = ({ setHoveredIndex, setDraggingIndex, hoveredIndex, draggingIndex, index, orgIndex, children, beforeChildren, onRemove, onDragAndDrop }) => {
  const [isOverflowed, setIsOverflowed] = useState(false),
        taskPaneWidth = useSelector(state => state.globalState.taskPaneWidth),
        getPositionOfElement = useCallback(element => ({ top: element?.offsetTop, bottom: element?.offsetTop + element?.offsetHeight + parseInt(window.getComputedStyle(element).marginTop) + + parseInt(window.getComputedStyle(element).marginBottom) }), []),
        childsArray = useRef(),
        rowRef = useRef(),
        onDrag = useCallback((_, data) => {
          const direction = Math.sign(data.deltaY)
          if(direction === 0) {
            return
          }
          const orgPosition = getPositionOfElement(rowRef.current)
          const currentPosition = ReduxService.updateObject(orgPosition, { top: orgPosition.top + data.y, bottom: orgPosition.bottom + data.y })
          setHoveredIndex(childsArray.current.findIndex(p => 
              (p.bottom >= currentPosition.top && p.top <= currentPosition.top && direction < 0) || 
              (currentPosition.bottom >= p.top && currentPosition.bottom <= p.bottom && direction > 0))
          )
        }, [getPositionOfElement, setHoveredIndex]),
        onDragStart = useCallback(() => {
          const children = getSiblingsAndCurrent(rowRef.current)
          const positionArrays = children.map(child => getPositionOfElement(child)).sort((a, b) => a.top - b.top)
          childsArray.current = positionArrays;
          setDraggingIndex(index)
          setHoveredIndex(index)
        }, [setDraggingIndex, index, setHoveredIndex, getPositionOfElement]),
        onDragStop = useCallback(e => {
          e.preventDefault()
          if(index === hoveredIndex) {
            return
          }
          onDragAndDrop({ from: orgIndex, to: hoveredIndex })
          setHoveredIndex(-1)
          setDraggingIndex(-1)
        }, [index, hoveredIndex, onDragAndDrop, orgIndex, setHoveredIndex, setDraggingIndex]),
        timeoutRef = useRef(),
        wrapperRef = useRef()

  useEffect(() => {
    if(wrapperRef.current) {
      setIsOverflowed(wrapperRef.current.scrollWidth > wrapperRef.current.clientWidth)
    } else {
      window.clearTimeout(timeoutRef.current)
      timeoutRef.current = window.setTimeout(() => setIsOverflowed(wrapperRef.current.scrollWidth > wrapperRef.current.clientWidth), 5)
    }
  }, [taskPaneWidth])

  useEffect(() => () => {
    window.clearTimeout(timeoutRef.current)
  }, [])

  return (
    <Draggable
        axis="y"
        bounds="parent"
        defaultClassNameDragging="ng-office-app__authed__content__basket__body__data__basket__section__items__row--is-dragging"
        handle=".ng-office-app__authed__content__basket__body__data__basket__section__items__row"
        onDrag={onDrag}
        onStart={onDragStart}
        onStop={onDragStop}
        position={STARTING_POSITION}
        scale={1}
        zIndex={1}
    >
      <div
          className={classNames(
            "ng-office-app__authed__content__basket__body__data__basket__section__items__row",
            { "ng-office-app__authed__content__basket__body__data__basket__section__items__row--is-overflowed": isOverflowed },
          )}
          ref={rowRef}
          style={{ 
            top: getTop({ draggingIndex, itemIndex: index, hoveredIndex }),
            transition: getTransition({ draggingIndex, itemIndex: index })
          }}
      >
        {beforeChildren}
        <div 
            className="ng-office-app__authed__content__basket__body__data__basket__section__items__row__wrapper"
            ref={wrapperRef}
        >
          <div className="ng-office-app__authed__content__basket__body__data__basket__section__items__row__content">
            {children}
          </div>
        </div>
        <div 
            className="ng-office-app__authed__content__basket__body__data__basket__section__items__row__remove"
            onClick={onRemove}
        >
          <Remove
              color="red-dark"
              height={16}
              width={16}
          />
        </div>
      </div>
    </Draggable>
  )
}

Item.defaultProps = {
  beforeChildren: <Fragment/>,
  draggingIndex: undefined,
  hoveredIndex: undefined
}

Item.propTypes = {
  beforeChildren: PropTypes.any,
  children: PropTypes.any.isRequired,
  draggingIndex: PropTypes.number,
  hoveredIndex: PropTypes.number,
  index: PropTypes.number.isRequired,
  onDragAndDrop: PropTypes.func.isRequired, 
  onRemove: PropTypes.func.isRequired, 
  orgIndex: PropTypes.number.isRequired,
  setDraggingIndex: PropTypes.func.isRequired,
  setHoveredIndex: PropTypes.func.isRequired
}

export default Item;