import { Fragment, useCallback } from 'react';
import PropTypes from 'prop-types';

import { TextField } from 'basic-components';
import { CommonService } from 'dashboard-services';

const LimitContracts = ({ onChange, value }) => {
  const onChangeLocal = useCallback((val, { name }) => {
          if(val === "") {
            onChange(undefined, { name })
            return;
          }
          if(!CommonService.isNumber(val)) {
            return;
          }
          const valFor = Number(val) < 0 ? Number(val) * -1 : val
          onChange(valFor, { name })
        }, [onChange])
  return (
    <Fragment>
      <div className="ng-office-app__authed__content__body__item__expanded-settings__bottom__label">
        Limit Contracts
      </div>
      <div className="ng-office-app__authed__content__body__item__expanded-settings__bottom__value">
        <TextField
            defaultValue={value}
            max={100}
            min={1}
            name="symbolSize"
            noError
            onChange={onChangeLocal}
            placeholder="All"
            type="number"
            variant="border"
        />
      </div>
    </Fragment>
  )
}

LimitContracts.defaultProps = {
  value: undefined,
}

LimitContracts.propTypes = {
  onChange: PropTypes.func.isRequired, 
  value: PropTypes.string,
}

export default LimitContracts;