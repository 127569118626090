import { Fragment, useCallback, useEffect, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';

import { DatePicker, Dropdown, Radio, TextField, TimeField } from 'basic-components';
import { dateBetweenTypes, TimeService, tsDataRanges, tsDataRangesObject, tsLastTypes } from 'dashboard-services';

import { When } from 'react-if';

const DateRange = ({ onChange, range, lastTypeAmount, lastType, from, fromTime, to, toTime, fromSelected, toSelected }) => {
  const fromTimeoutRef = useRef(),
        toTimeoutRef = useRef(),
        onChangeRadio = useCallback((value, { name }) => {
          onChange(value, { name })
          if(value === "date") {
            if(!from || from?.length < 10) {
              onChange(to, { name: "from" })
            } else if(!to || to?.length < 10) {
              onChange(to, { name: "to" })
            }
          }
        }, [from, onChange, to]),
        onChangeFrom = useCallback(val => {
          onChange(val, { name: "from" })
          if(toSelected === "date" && (!to || to?.length < 10)) {
            onChange(to, { name: "to" })
          }
        }, [onChange, to, toSelected]),
        onChangeTo = useCallback(val => {
          onChange(val, { name: "to" })
          if(fromSelected === "date" && (!from || from?.length < 10)) {
            onChange(val, { name: "from" })
          }
        }, [from, fromSelected, onChange]),
        onChangeFromTime = useCallback((value, { name }) => {
          clearTimeout(fromTimeoutRef.current)
          fromTimeoutRef.current = setTimeout(() => onChange(TimeService.getTimeWithSeconds(value), name), 500)
        }, [onChange]),
        onChangeToTime = useCallback((value, { name }) => {
          clearTimeout(toTimeoutRef.current)
          toTimeoutRef.current = setTimeout(() => onChange(TimeService.getTimeWithSeconds(value), name), 500)
        }, [onChange]),
        defaultDateRange = useMemo(() => tsDataRanges.find(d => d.value === range), [range]),
        defaultLastType = useMemo(() => tsLastTypes.find(t => t.value === lastType), [lastType])
  
  useEffect(() => () => {
    clearTimeout(fromTimeoutRef.current)
    clearTimeout(toTimeoutRef.current)
  }, [])

  return (
    <Fragment>
      <div className="ng-office-app__authed__content__body__item__expanded-settings__bottom__label">
        Date range
      </div>
      <div className="ng-office-app__authed__content__body__item__expanded-settings__bottom__value">
        <Dropdown
            defaultValue={defaultDateRange}
            fixed
            menuPortalTarget={document.querySelector('.ng-office-app')}
            name="range"
            noError
            onChange={onChange}
            options={tsDataRanges}
            variant="border"
        />
      </div>
      <When condition={range === tsDataRangesObject.last || range === tsDataRangesObject.next}>
        {() => (
          <div className="ng-office-app__authed__content__body__item__expanded-settings__bottom__row">
            <div className="ng-office-app__authed__content__body__item__expanded-settings__bottom__row__item">
              <div className="ng-office-app__authed__content__body__item__expanded-settings__bottom__label">
                Type
              </div>
              <div className="ng-office-app__authed__content__body__item__expanded-settings__bottom__value">
                <Dropdown
                    defaultValue={defaultLastType}
                    fixed
                    menuPortalTarget={document.querySelector('.ng-office-app')}
                    name="lastType"
                    noError
                    onChange={onChange}
                    options={tsLastTypes}
                    variant="border"
                />
              </div>
            </div>
            <div className="ng-office-app__authed__content__body__item__expanded-settings__bottom__row__item">
              <div className="ng-office-app__authed__content__body__item__expanded-settings__bottom__label">
                Amount
              </div>
              <div className="ng-office-app__authed__content__body__item__expanded-settings__bottom__value">
                <TextField
                    defaultValue={lastTypeAmount}
                    min={1}
                    name="lastTypeAmount"
                    noError
                    onChange={onChange}
                    type="number"
                    variant="border"
                />
              </div>
            </div>
          </div>
        )}
      </When>
      <When condition={range === tsDataRangesObject.between}>
        {() => (
          <Fragment>
            <div className="ng-office-app__authed__content__body__item__expanded-settings__bottom__row">
              <div className="ng-office-app__authed__content__body__item__expanded-settings__bottom__label">From</div>
              <div className="ng-office-app__authed__content__body__item__expanded-settings__bottom__pickers">
                <Radio
                    checked={fromSelected === dateBetweenTypes.today}
                    groupName="fromSelected"
                    name={dateBetweenTypes.today}
                    onChange={onChangeRadio}
                >
                  Today  
                </Radio>
                <div className="ng-office-app__authed__content__body__item__expanded-settings__bottom__pickers__dates">
                  <Radio checked={fromSelected === dateBetweenTypes.date}
                      groupName="fromSelected"
                      name={dateBetweenTypes.date}
                      onChange={onChangeRadio}
                  >
                    {""}
                  </Radio>
                  <DatePicker
                      defaultValue={from}
                      disabled={fromSelected !== dateBetweenTypes.date}
                      name="from"
                      noError
                      onChange={onChangeFrom}
                  />
                  <TimeField
                      defaultValue={fromTime}
                      disabled={fromSelected !== dateBetweenTypes.date}
                      name="fromTime"
                      noError
                      onChange={onChangeFromTime}
                      shouldValidateWithSeconds={false}
                      withSeconds
                  />
                </div>
              </div>
            </div>
            <div className="ng-office-app__authed__content__body__item__expanded-settings__bottom__row">
              <div className="ng-office-app__authed__content__body__item__expanded-settings__bottom__label">To</div>
              <div className="ng-office-app__authed__content__body__item__expanded-settings__bottom__pickers">
                <Radio 
                    checked={toSelected === dateBetweenTypes.today}
                    groupName="toSelected"
                    name={dateBetweenTypes.today}
                    onChange={onChangeRadio}
                >
                  Today  
                </Radio>
                <div className="ng-office-app__authed__content__body__item__expanded-settings__bottom__pickers__dates">
                  <Radio 
                      checked={toSelected === dateBetweenTypes.date}
                      groupName="toSelected"
                      name={dateBetweenTypes.date}
                      onChange={onChangeRadio}
                  >
                    {""}
                  </Radio>
                  <DatePicker
                      defaultValue={to}
                      disabled={toSelected !== dateBetweenTypes.date}
                      name="to"
                      noError
                      onChange={onChangeTo}
                  />
                  <TimeField
                      defaultValue={toTime}
                      disabled={toSelected !== dateBetweenTypes.date}
                      name="toTime"
                      noError
                      onChange={onChangeToTime}
                      shouldValidateWithSeconds={false}
                      withSeconds
                  />
                </div>
              </div>
            </div>
          </Fragment>
        )}
      </When>
    </Fragment>
  )
}

DateRange.defaultProps = {
  from: undefined,
  fromSelected: undefined,
  fromTime: undefined,
  lastType: undefined,
  lastTypeAmount: undefined,
  range: undefined,
  to: undefined,
  toSelected: undefined,
  toTime: undefined
}

DateRange.propTypes = {
  from: PropTypes.string, 
  fromSelected: PropTypes.string, 
  fromTime: PropTypes.string, 
  lastType: PropTypes.string, 
  lastTypeAmount: PropTypes.string, 
  onChange: PropTypes.func.isRequired, 
  range: PropTypes.string, 
  to: PropTypes.string, 
  toSelected: PropTypes.string, 
  toTime: PropTypes.string, 
}

export default DateRange;