import PropTypes from 'prop-types';

import { Filter } from 'basic-components';

const DropdownIndicator = ({ innerProps }) => (
  <div 
      {...innerProps}
      className="ng-dropdown__component__indicators__indicator"
  >
    <Filter
        color="blue-bright"
        height={12}
        width={12}
    />
  </div>
)

DropdownIndicator.defaultProps = {
  innerProps: {},
  selectProps: {}
}

DropdownIndicator.propTypes = {
  innerProps: PropTypes.object,
  selectProps: PropTypes.object
}

export default DropdownIndicator;