import PropTypes from 'prop-types';

import { Box } from 'basic-components';

import './EmptyList.scss';

const EmptyList = ({ title, subtitle }) => (
  <div className="empty-list">
    <div className="empty-list__icon">
      <Box
          color="grey-medium"
          height={48}
          width={48}
      />
    </div>
    <p className="empty-list__title">{title}</p>
    <p className="empty-list__subtitle">{subtitle}</p>

  </div>
)

EmptyList.propTypes = {
  subtitle: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
}

export default EmptyList;