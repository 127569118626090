import { Fragment } from 'react';
import { useSelector } from 'react-redux';

import EmptyList from 'primary/EmptyList';
import Columns from './Columns';
import DataRange from './DataRange';
import Section from './Section';

import { Else, If, Then } from 'react-if';

import './DataTab.scss';

const patternFilterFun = s => s.pattern === "true" || s.pattern === undefined
const itemFilterFun = s => s.pattern === "false"
const emptyArray = []
const DataTab = () => {
  const selectedSymbols = useSelector(state => state.seriesState.creatorState.selectedSymbols) || emptyArray
  return (
    <div className="ng-office-app__authed__content__basket__body__data">
      <Columns/>
      <div className="ng-office-app__authed__content__basket__body__data__basket">
        <If condition={selectedSymbols.length === 0}>
          <Then>
            {() => (
              <EmptyList
                  subtitle="Add data in the series creator."
                  title="Basket is empty."
              />
            )}
          </Then>
          <Else>
            {() => (
              <Fragment>
                <Section
                    filterFun={patternFilterFun}
                    label="DATA FILTERS"
                />
                <Section
                    filterFun={itemFilterFun}
                    label="DATA ITEMS"
                />
              </Fragment>
            )}
          </Else>
        </If>
      </div>
      <DataRange/>
    </div>
  )
}

export default DataTab;