import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { creatorSeriesActions } from 'actions/series';
import { LocalStorageUtils } from 'utils';

import RecentRow from './RecentRow';
import SuggestedRow from './SuggestedRow';

import { When } from 'react-if';

import './StartingResults.scss';

const StartingResults = ({ getResultsStyle }) => {
  const dispatch = useDispatch(),
        { suggested, selectedSymbols } = useSelector(state => state.seriesState.creatorState),
        [recents, setRecents] = useState(),
        setSuggested = useCallback(value => dispatch(creatorSeriesActions.onChange(value, "suggested")), [dispatch]),
        getSuggestedSymbolsRef = useRef(),
        getSuggestedSymbolsFileRef = useRef(),
        getSuggestedSymbols = useCallback(() => {
          if(suggested.length > 0) {
            return Promise.resolve();
          }
          getSuggestedSymbolsRef.current?.cancel?.()
          getSuggestedSymbolsFileRef.current?.cancel?.()
          return dispatch(creatorSeriesActions.fetchEnvSuggested({ searchRef: getSuggestedSymbolsRef, getRef: getSuggestedSymbolsFileRef }))
            .then(setSuggested)
        }, [dispatch, setSuggested, suggested.length]),
        addToBasket = useCallback(s => dispatch(creatorSeriesActions.swapSelectedSymbol(s)), [dispatch]),
        resultsStyle = useMemo(() => getResultsStyle(), [getResultsStyle])

  useEffect(() => {
    getSuggestedSymbols()
  }, [getSuggestedSymbols])

  useEffect(() => {
    setRecents(LocalStorageUtils.getRecentSymbols())
  }, [selectedSymbols?.length])

  useEffect(() => () => {
    getSuggestedSymbolsFileRef?.current?.cancel?.()
    getSuggestedSymbolsRef?.current?.cancel?.()
  }, [])

  return (
    <div 
        className="ng-office-app__authed__content__starting"
        style={resultsStyle}
    >
      <When condition={recents?.length > 0}>
        {() => (
          <div className="ng-office-app__authed__content__starting__block">
            <div className="ng-office-app__authed__content__starting__block__title">
              RECENT ITEMS
            </div>
            <div className="ng-office-app__authed__content__starting__block__content">
              {(recents || []).map((recentItem, recentIndex) => {
                const { 
                  isDirectlySelected,
                  isPatternSelected
                } = creatorSeriesActions.isInBasket(recentItem, selectedSymbols)
                return (
                  <RecentRow
                      addToBasket={addToBasket}
                      isPatternSelected={isPatternSelected}
                      isSelected={isDirectlySelected}
                      key={recentIndex}
                      symbol={recentItem}
                  />
                )
              })}
            </div>
          </div>
        )}
      </When>
      <When condition={suggested?.length > 0}>
        {() => (
          <div className="ng-office-app__authed__content__starting__block">
            <div className="ng-office-app__authed__content__starting__block__title">
              SUGGESTED ITEMS
            </div>
            <div className="ng-office-app__authed__content__starting__block__content">
              {suggested.map((s, sIndex) => {
                const { 
                  isDirectlySelected,
                  isPatternSelected
                } = creatorSeriesActions.isInBasket(s, selectedSymbols)
                return (
                  <SuggestedRow
                      addToBasket={addToBasket}
                      isPatternSelected={isPatternSelected}
                      isSelected={isDirectlySelected}
                      key={sIndex}
                      symbol={s}
                  />
                )
              })}
            </div>
          </div>
        )}
      </When>
    </div>
  )
}

StartingResults.propTypes = {
  getResultsStyle: PropTypes.func.isRequired,
}

export default StartingResults;