import { LOG_LEVEL } from 'dashboard-services';
import { globalActions } from 'primary-components';

import FORMULAS from 'resources/constants/Formulas.js';
import properties from 'resources/constants/properties.json';

import AuthUtils from './AuthUtils';
import CurvesUtils from './CurvesUtils';
import FileUtils from './FileUtils';
import TimeSeriesUtils from './TimeSeriesUtils';

const FUNCTIONS_NAMESPACE = properties.functionsNamespace
const APPROXIMATE_WEB_LIMIT = 100_000
const sendError = ({ error }) => window["ngStore" + FUNCTIONS_NAMESPACE].dispatch(AuthUtils.sendError({ error }))
export default(() => {

  const numberFormatTypesObj = {
    GENERAL: "GENERAL",
    TEXT: "TEXT",
  }

  const numberFormatTypes = [
    {
      label: "General",
      value: numberFormatTypesObj.GENERAL
    },
    {
      label: "Text",
      value: numberFormatTypesObj.TEXT
    }
  ]

  const insertTable = async ({ values: rawValues, address, tableName, shouldTranspose = false }) =>
    Excel.run(context => {
      const isInWebBrowser = Office.context.platform === "OfficeOnline"
      if(shouldTranspose && isInWebBrowser && rawValues?.[0]?.length * rawValues?.length > APPROXIMATE_WEB_LIMIT) {
        throw new CustomFunctions.Error(CustomFunctions.ErrorCode.notAvailable, `Provided data is too large (${rawValues?.[0]?.length * rawValues?.length} cells) and can not be transposed as it exceeds the web app transpose limit (${APPROXIMATE_WEB_LIMIT} cells). Disabled transpose and try again!`)
      }

      const worksheet = context.workbook.worksheets.getItem(getWorksheetFromAddress(address)),
            range = worksheet.getRange(address.split("!")[1]),
            rangeBelowFunction = range.getRowsBelow(1),
            rangeBelowHeader = rangeBelowFunction.getRowsBelow(1),
            values = shouldTranspose ? rawValues[0].map((_, colIndex) => rawValues.map(row => row[colIndex])) : rawValues,
            resizeRange = rangeBelowFunction.getAbsoluteResizedRange(values.length, values?.[0]?.length || 0),
            noHeadersResizeRagne = rangeBelowHeader.getAbsoluteResizedRange(values.length - 1 , values?.[0]?.length || 0),
            tables = resizeRange.getTables(false)
      tables.load("items")
      worksheet.comments.load("items")

      return context.sync().then(() => {
        const noHeadersResizeRagneFirstCell = noHeadersResizeRagne.getCell(0, 0),
              tableFirstCell = tables.items?.[0]?.getRange().getCell(0, 0)
        noHeadersResizeRagneFirstCell.load("address")
        tableFirstCell?.load("address")
        
        return context.sync().then(() => {
          const comment = worksheet.comments.getItemByCell(address)
          comment?.delete()
          let table = noHeadersResizeRagneFirstCell?.address === tableFirstCell?.address ? tables.items?.[0] : undefined
          return context
            .sync()
            .catch(err => {
              sendError({ error: "(Comment does not exist)" + err.stack, level: LOG_LEVEL.WARN })
              console.info("Comment does not exist")
              console.info(String(err))
            })
            .finally(() => {
              let oldRange
              if(table) {
                oldRange = table?.getRange()
                if(window["ngStore" + properties.functionsNamespace]?.getState()?.settingsState?.definedNumberFormat === numberFormatTypesObj.TEXT) {
                  oldRange.numberFormat = "General"
                }
                oldRange.load("values")
                table.load("columns")
              }
              return context
                .sync()
                .catch(err => {
                  sendError({ error: "(Table does not exist)" + err.stack, level: LOG_LEVEL.WARN })
                  console.info("Table does not exist")
                  console.info(String(err))
                })
                .finally(() => {
                  const valuesWithoutHeaders = values.slice(1)
                  if(window["ngStore" + properties.functionsNamespace]?.getState()?.settingsState?.definedNumberFormat === numberFormatTypesObj.TEXT) {
                    noHeadersResizeRagne.numberFormat = "@"
                  }
                  if(!table) {
                    table = worksheet.tables.add(resizeRange, true)
                    table.style = null
                    table.showFilterButton = false
                    table.getHeaderRowRange().values = [values?.[0]] 
                    table.getDataBodyRange().values = valuesWithoutHeaders
                    table.showHeaders = false
                  } else {
                    if(oldRange) {
                      const newValues = oldRange.values.map(val => val.map(() => ""))
                      oldRange.values = newValues
                    }
                    const oldColumnsLength = table.columns.count
                    // newColumnsLength = values[0]?.length
                    // if (oldColumnsLength > newColumnsLength) {
                    //   for (let i = oldColumnsLength - 1; i >= newColumnsLength; i--) {
                    //     table.columns.getItemAt(i).delete()
                    //   }
                    // }
                    if(oldColumnsLength > 0) {
                      const oldHeadersRange = rangeBelowFunction.getAbsoluteResizedRange(1, oldColumnsLength || 0)
                      oldHeadersRange.values = ""
                    }
                    table.resize(noHeadersResizeRagne)
                    noHeadersResizeRagne.values = valuesWithoutHeaders
                    table.showHeaders = false
                  }
                  const headersRange = rangeBelowFunction.getAbsoluteResizedRange(1, values?.[0]?.length || 0)
                  headersRange.values = [values?.[0]]
                  return context.sync().then(() => {
                    worksheet.comments.add(address, tableName)
                    if (Office.context.requirements.isSetSupported("ExcelApi", "1.2")) {
                      resizeRange.format.autofitColumns();
                      resizeRange.format.autofitRows();
                    }
                    return context.sync()
                  })
                })
            })
        }).catch(err => {
          sendError({ error: "(insertTable inner)" + err.stack })
          console.error(err)
          throw new CustomFunctions.Error(CustomFunctions.ErrorCode.notAvailable, err)
        })
      })
    }).catch(err => {
      sendError({ error: "(insertTable outer)" + err.stack })
      console.error(err)
      throw new CustomFunctions.Error(CustomFunctions.ErrorCode.notAvailable, err)
    })

  const insertValuesWithPositioning = ({ range, values }) => {
      if (range.rowCount === 1 && range.columnCount === values.length) {
        range.values = [values]
      } else if (range.columnCount === 1 && range.rowCount === values.length) {
        range.values = values.map(val => [val])
      } else {
        const message = "The selected range does not match the number of values."
        window["ngStore" + FUNCTIONS_NAMESPACE].dispatch(globalActions.getMessageStore()).error(message)
        console.warn(message)
        throw new Error(message)
      }
  }

  const handleTooLongParams = ({ paramsExceedingLength, formula, range, context }) => {
    const paramsRange = range.getColumnsAfter(paramsExceedingLength.length)
    paramsRange.load(["values", "address", "columnCount", "cellCount"])
    const propertiesToGet = paramsRange.getCellProperties({ address: true })
    return context.sync().then(() => {
      let newFormula = formula
      const arrAddress = []
      for (let iCol = 0; iCol < paramsRange.columnCount; iCol++) {
          const cellAddress = propertiesToGet.value[0][iCol]
          arrAddress.push(`${cellAddress.address}`)
      }
      paramsExceedingLength.forEach((param, index) => {
        newFormula = newFormula.replace(String(param.id), arrAddress[index])
      })
      paramsRange.values = [paramsExceedingLength.map(param => param.value)]
      range.formulas = [[newFormula]]
    }).catch(e => {
      sendError({ error: "(handleTooLongParams)" + e.stack })
      console.error(e.message)
      window["ngStore" + FUNCTIONS_NAMESPACE].dispatch(globalActions.getMessageStore()).error(e.message)
    })
  }

  const addToFormulaCell = ({ formula = undefined, address, paramsExceedingLength = [] }) =>
    Excel.run(context => {
      let range;
      if(address) {
        const worksheet = address.split("!").length > 1 ? context.workbook.worksheets.getItem(getWorksheetFromAddress(address)) : context.workbook.worksheets.getActiveWorksheet()
        range = worksheet.getRange(address.split("!").length > 1 ? address.split("!")[1] : getWorksheetFromAddress(address))
      } else {
        range = context.workbook.getSelectedRange()
      }
      range.load(["address", "rowCount", "columnCount", "values"])

      return context.sync().then(async () => {
        if(range.values[0][0] && !address) {
          window["ngStore" + FUNCTIONS_NAMESPACE].dispatch(globalActions.getMessageStore()).error("Value exists in a cell")
        } else {
          if(paramsExceedingLength.length > 0) {
            await handleTooLongParams({ paramsExceedingLength, formula, range, context })
          } else {
            range.formulas = [[formula]]
          }
        }
        return range.address
      })
    }).catch(err => {
      sendError({ error: "(addToFormulaCell)" + err.stack })
      console.error(err)
      return Promise.resolve()
    })

  const refreshFormulaWithAdress = ({ address, formula, context, worksheet }) => {
    const worksheetLocal = worksheet ? worksheet : context.workbook.worksheets.getItem(getWorksheetFromAddress(address)),
          range = worksheetLocal.getRange(address.split("!")[1])
          range.load("formulas")
    return context.sync().then(() => {
      if(range.formulas[0][0]) {
        range.calculate()
      } else {
        range.formulas = [[formula]]
      }
    }).catch(e => {
      sendError({ error: "(refreshFormulaWithAddress)" + e.stack })
      console.error(e)
    })
  }

  const getTable = ({ table, context, item, comments, validateTable }) => {
    const range = table.getRange(),
          firstTableCell = range.getCell(0, 0),
          headerCell = firstTableCell.getRowsAbove(1),
          cell = headerCell.getRowsAbove(1),
          cellComment = comments.getItemByCell(cell)
    cellComment.load("content")
    cell.load("address")
    cell.load("formulas")
    return context.sync()
      .then(async () => {
        const cellValue = cellComment.content
        return await validateTable({ item, cellValue, cell, formula: cell.formulas?.[0]?.[0] })
      })
      .catch(err => {
        sendError({ error: "(Comment does not exist getTable)" + err.stack, level: LOG_LEVEL.WARN })
        console.error("Comment does not exist", err)
        return item
      })
  }
  const getTables = ({ worksheet, context, prevTables, ...props }) => {
    worksheet.tables.load("items")
    worksheet.comments.load("items")
    return context.sync()
      .then(() => 
        prevTables.concat(
          worksheet.tables.items.reduce((prev, table) => 
            prev.then((item = []) => getTable({ table, context, item, comments: worksheet.comments, ...props }))
          , Promise.resolve())
        )
      )
      .catch(err => {
        console.error(err)
        sendError({ error: "(getTables)" + err.stack })
      })
  }

  const getTablesInSpreadSheets = ({ ...props }) => 
    Excel.run(context => {
      context.workbook.worksheets.load("items")
      return context.sync()
        .then(() => {
          const worksheetsPromise = context.workbook.worksheets.items
            .reduce((prevPromise, worksheet) => 
                prevPromise
                  .then((prevTables = []) => getTables({ worksheet, context, prevTables, ...props }).then(items => items.filter(i => i !== undefined)))
                  .catch(err => {
                    console.error(err)
                    sendError({ error: "(getTablesInSpreadSheets inner)" + err.stack })
                  })
            , Promise.resolve())
          return worksheetsPromise
        })
        .then(dps => {
          const ps = Promise.all(dps).then(dps => {
            const flatDps = dps.flatMap(d => d)
            return flatDps;
          })
          return ps;
        })
        .catch(err => {
          console.error(err)
          sendError({ error: "(getTablesInSpreadSheets outer)" + err.stack })
        })
    })

  const getSelectedRange = () => 
    Excel.run(context => {
      const range = context.workbook.getSelectedRange()
      range.load("address")

      return context.sync().then(() => {
        const addressSplit = range.address.split("!"),
              sheetName = getWorksheetFromAddress(range.address),
              cells = addressSplit[1].split(":"),
              firstCell = cells[0],
              lastCell = cells[1]

        return { sheetName, firstCell, lastCell }
      })
    })

  const getCellsArayFromRangeAddress = rangeAddress =>
    Excel.run(context => {
      const [sheetName, address] = rangeAddress.split("!"),
            sheet = context.workbook.worksheets.getItem(sheetName),
            range = sheet.getRange(address)
  
      range.load(["rowCount", "columnCount", "cellCount"])
      const propertiesToGet = range.getCellProperties({
        address: true
      })
      return context.sync().then(() => {
        const arrAddress = []
        for (let iRow = 0; iRow < range.rowCount; iRow++) {
          for (let iCol = 0; iCol < range.columnCount; iCol++) {
            const cellAddress = propertiesToGet.value[iRow][iCol]
            arrAddress.push(`${sheetName}!${cellAddress.address.slice(cellAddress.address.lastIndexOf("!") + 1)}`)
          }
        }
        return arrAddress
      })  
    }).catch(e => {
      sendError({ error: "(getCellsArayFromRangeAddress)" + e.stack })
      console.error(e.message)
      window["ngStore" + FUNCTIONS_NAMESPACE].dispatch(globalActions.getMessageStore()).error(e.message)
    })

  const refreshFormulasForEntity = async ({ worksheet, context, validateTable, getFormula }) => {
    await worksheet.tables.items.reduce((prev, tableItem) => 
      prev.then(() => 
        getTable({ table: tableItem, context, comments: worksheet.comments, validateTable, item: [] })
          .then(table => {
            if(table?.[0]) {
              return refreshFormulaWithAdress({ address: table[0].address, formula: getFormula(table[0]), context })
            }
            return Promise.resolve()
          })
    ), Promise.resolve())
  }

  const refreshAllFormulasInSheet = async ({ worksheet, context }) => {
    worksheet.tables.load("items")
    worksheet.comments.load("items")
    await context.sync().catch(e => {
      console.error(e)
      sendError({ error: "(refreshAllFormulasInSheet)" + e.stack })
    })
    // we need to handle here all formulas
    await refreshFormulasForEntity({ getFormula: item => TimeSeriesUtils.paramsToFormula(item.parsedParameters), validateTable: TimeSeriesUtils.validateTable, worksheet, context })
    await refreshFormulasForEntity({ getFormula: item => CurvesUtils.paramsToFormula(item.parsedParameters), validateTable: CurvesUtils.validateTable, worksheet, context })
    await refreshFormulasForEntity({ getFormula: item => `=${FORMULAS.LOAD_FILE}("${item.fileName}","${item.groupName}")`, validateTable: FileUtils.validateTable, worksheet, context })
  }

  const refreshAllFormulas = async ({ context }) => {
    const worksheets = context.workbook.worksheets
    worksheets.load("items")
    await context.sync().catch(e => {
      console.error(e)
      sendError({ error: "(refreshAllFormulas)" + e.stack })
    })
    await worksheets.items.reduce((prev, worksheet) => 
      prev.then(() => refreshAllFormulasInSheet({ worksheet, context }))
    , Promise.resolve())
  }

  const goToAddress = async address => 
    await Excel.run(context => {
      const worksheet = context.workbook.worksheets.getItem(getWorksheetFromAddress(address)),
            range = worksheet.getRange(address.split("!")[1])
      range.select()
      return context.sync()
    })

  const openTaskPaneComponent = componentName => {
    document.body.id = componentName
    Office.addin?.showAsTaskpane()
  }

  // const switchButton = async ({ tabId, groupId, buttonId, enabled = false }) => {
  //   if(Office.context.requirements.isSetSupported('RibbonApi', '1.1')) {
  //     await Office.ribbon
  //       .requestUpdate({
  //         tabs: [
  //           {
  //             id: tabId,
  //             groups: [
  //               {
  //                 id: groupId,
  //                 controls: [{ id: buttonId, enabled }],
  //               },
  //             ],
  //           },
  //         ],
  //       })
  //       .catch(err => console.error(err));
  //   }
  // }

  const getWorksheetFromAddress = address => address.split("!")[0].replace(/'/g, "")

  const isNGFormula = formula => formula?.startsWith(`=${FUNCTIONS_NAMESPACE}`) || formula?.includes(FUNCTIONS_NAMESPACE.replaceAll(".", "_"))

  const clearSelectedTable = ({ context, table }) => {
    const tableRange = table.getRange(),
          headerRange = tableRange.getRowsAbove(1),
          formulaRange = headerRange.getRowsAbove(1).getCell(0,0)
    formulaRange.load("formulas")

    return context.sync().then(() => {
      if(isNGFormula(formulaRange.formulas?.[0][0])) {
        table?.delete()
        headerRange.clear()
        formulaRange.clear()
      } else {
        window["ngStore" + FUNCTIONS_NAMESPACE].dispatch(globalActions.getMessageStore())?.error("This table has not been created using the add-in and it cannot be cleared.")
      }
    })
  }

  const clearTableBelowFormula = ({ context, formulaRange }) => {
    const tablesBelowFormula = formulaRange.getRowsBelow(2).getTables()
          tablesBelowFormula.load("items")

    return context.sync().then(() => {
      const tableBelowFormula = tablesBelowFormula.items?.[0]

      if(tableBelowFormula) {
        const tableRange = tableBelowFormula.getRange(),
              headerRange = tableRange.getRowsAbove(1)
        tableBelowFormula?.delete()
        headerRange.clear()
      }
      formulaRange.clear()
    })
  }

  const clearTableWithFormulaAddress = address =>
    Excel.run(context => {
      const worksheet = context.workbook.worksheets.getItem(getWorksheetFromAddress(address)),
            range = worksheet.getRange(address.split("!")[1])

      return clearTableBelowFormula({ context, formulaRange: range })
    })

  const clearActiveRangeTable = () => 
    Excel.run(context => {
      const activeRange = context.workbook.getSelectedRange(),
            tablesInActiveRange = activeRange.getTables(),
            tablesBelowHeader = activeRange.getRowsBelow(1).getTables()
      tablesInActiveRange.load("items")
      activeRange.load("formulas")
      tablesBelowHeader.load("items")

      return context.sync().then(() => {
        const tableInActiveRange = tablesInActiveRange.items?.[0],
              tableBelowHeader = tablesBelowHeader.items?.[0]
        if(tableInActiveRange) {
          return clearSelectedTable({ context, table: tableInActiveRange })
        } else if(tableBelowHeader) {
          return clearSelectedTable({ context, table: tableBelowHeader })
        } else if(isNGFormula(activeRange.formulas?.[0][0])) {
          return clearTableBelowFormula({ context, formulaRange: activeRange })
        } else {
          window["ngStore" + FUNCTIONS_NAMESPACE].dispatch(globalActions.getMessageStore())?.error("This table has not been created using the add-in and it cannot be cleared.")
        }
      })
    })

  const parseFormula = async ({ formula, extractParamsFromFormula }) => {
    const addressRegex = /(?<=\s)([^,]*![A-Za-z]+\d+)/g;
    const addresses = formula.match(addressRegex) || []
    let newFormula = formula
    if(addresses?.length > 0) {
      addresses.forEach(address => newFormula = newFormula.replace(address, `"${address}"`))
    }
    const parsedParams = extractParamsFromFormula(newFormula)
    const parsedFormula = []
    for (const param of parsedParams) {
      if (addresses.includes(param)) {
        let toReturn

        await Excel.run(async context => {
          const addressArr = param.split("!")
          const sheetName = addressArr[0]
          const cellAddress = addressArr[1]

          const worksheet = context.workbook.worksheets.getItem(sheetName)
          const range = worksheet.getRange(cellAddress)
          range.load("values")

          await context.sync()
          toReturn = String(range.values[0][0])
        })
        parsedFormula.push(toReturn)
      } else {
        parsedFormula.push(param)
      }
    }
    return parsedFormula
  }
  

  return {
    numberFormatTypesObj,
    numberFormatTypes,
    insertTable,
    addToFormulaCell,
    refreshFormulaWithAdress,
    getTablesInSpreadSheets,
    refreshAllFormulasInSheet,
    refreshAllFormulas,
    goToAddress,
    openTaskPaneComponent,
    getSelectedRange,
    getWorksheetFromAddress,
    clearActiveRangeTable,
    clearTableWithFormulaAddress,
    isNGFormula,
    getCellsArayFromRangeAddress,
    handleTooLongParams,
    parseFormula,
    insertValuesWithPositioning
  }

})()