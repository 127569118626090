import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { ReduxService } from 'dashboard-services';

import { creatorSeriesActions } from 'actions/series';
import { basicTimeseriesActions, listTimeseriesActions } from 'actions/timeseries';
import { routing } from 'routing';
import { ExcelUtils } from 'utils';

import Row from '../Row';

const checkAndPush = ({ resultsMap, value, key }) => {
  const existIndex = resultsMap.findIndex(r => r.key === key)
  const localValue = value === "*" ? "All" : value
  if(existIndex > -1) {
    resultsMap[existIndex].value.push(localValue)
  } else {
    resultsMap.push({
      key,
      value: [localValue]
    })
  }
}

const emptyArray = []
const SerieRow = ({ address, clearTable, formula, refreshCall, parsedParameters }) => {
  const dispatch = useDispatch(),
        userDefinedFilters = useSelector(state => state.seriesState.creatorState.userDefinedFilters) || emptyArray,
        goToAddress = useCallback(() => ExcelUtils.goToAddress(address), [address]),
        editSerie = useCallback(() => {
            ExcelUtils.openTaskPaneComponent(routing.TS.name)
            const groupedParams = parsedParameters.getGroupedParams(),
                  hasTesterAccess = dispatch(basicTimeseriesActions.hasTesterAccess()) 
            if(hasTesterAccess) {
              dispatch(creatorSeriesActions.editFormula({ 
                address, 
                keys: groupedParams.map(gp => ReduxService.updateObject(gp, { columns: gp.columns?.split(",") })),
                params: parsedParameters
              }))
            } else {
              dispatch(listTimeseriesActions.editFormula({ 
                address, 
                keys: groupedParams.map(gp => ReduxService.updateObject(gp, { columns: gp.columns?.split(",") })),
                params: parsedParameters
              }))
            }
        }, [address, dispatch, parsedParameters]),
        symbolsAndMetadata = useMemo(() => {
          const groupedParams = parsedParameters.getGroupedParams()
          const resultsMap = []
          groupedParams.forEach(gp => {
            userDefinedFilters.forEach(udf => {
              if(udf.type === creatorSeriesActions.FILTER_TYPE.METADATA && gp.metadatas?.[udf.filterKey]) {
                checkAndPush({ resultsMap, value: gp.metadatas[udf.filterKey], key: udf.filterKey })
              } else if(udf.type === creatorSeriesActions.FILTER_TYPE.SYMBOL && gp.symbols?.[udf.filterKey]) {
                checkAndPush({ resultsMap, value: gp.symbols[udf.filterKey], key: udf.filterKey })
              }
            })
            Object.entries(gp.symbols || {}).forEach(([key, value]) => checkAndPush({ resultsMap, value, key }))
            Object.entries(gp.metadatas || {}).forEach(([key, value]) => checkAndPush({ resultsMap, value, key }))
          })
          return resultsMap.map(r => ({
            key: r.key,
            value: r.value.filter(v => v).toUnique()
          }))
        }, [parsedParameters, userDefinedFilters]),
        groupNames = useMemo(() => parsedParameters.getGroupedParams().map(i => i.groupName).filter(i => i).toUnique(), [parsedParameters]),
        values = useMemo(() => parsedParameters.getGroupedParams().flatMap(i => i.columns?.split(","))?.toUnique(), [parsedParameters])

  return (
    <Row
        address={address}
        formula={formula}
        onClear={clearTable}
        onEdit={editSerie}
        onRefresh={refreshCall}
    >
      <div className="ng-office-app__authed__content__active-calls__row__cell ng-office-app__authed__content__active-calls__row__cell--group">
        <div className="ng-office-app__authed__content__active-calls__row__cell__top">
          {`Group${groupNames.length > 1 ? "s" : ""}:`}
        </div>
        <div className="ng-office-app__authed__content__active-calls__row__cell__bottom">
          {groupNames.join(", ") || "-"}
        </div>
      </div>
      <div className="ng-office-app__authed__content__active-calls__row__cell ng-office-app__authed__content__active-calls__row__cell--address">
        <div className="ng-office-app__authed__content__active-calls__row__cell__top">
          Cell reference:
        </div>
        <div 
            className="ng-office-app__authed__content__active-calls__row__cell__bottom"
            onClick={goToAddress}
        >        
          {address}
        </div>
      </div>
      {symbolsAndMetadata.map(({ key, value }, index) => (
        <div 
            className="ng-office-app__authed__content__active-calls__row__cell ng-office-app__authed__content__active-calls__row__cell--symbol"
            key={key + value + index}
        >
          <div className="ng-office-app__authed__content__active-calls__row__cell__top">
            {key}:
          </div>
          <div className="ng-office-app__authed__content__active-calls__row__cell__bottom">        
            {value}
          </div>
        </div>
      ))}
      <div className="ng-office-app__authed__content__active-calls__row__cell ng-office-app__authed__content__active-calls__row__cell--columns">
        <div className="ng-office-app__authed__content__active-calls__row__cell__top">
          Columns:
        </div>
        <div className="ng-office-app__authed__content__active-calls__row__cell__bottom">        
          {values?.[0] === "*" ? "All" : values.join(", ")}
        </div>
      </div>
    </Row>
  )
}

SerieRow.defaultProps = {
  address: "",
  parsedParameters: {}
}

SerieRow.propTypes = {
  address: PropTypes.string,
  clearTable: PropTypes.func.isRequired,
  formula: PropTypes.string.isRequired,
  parsedParameters: PropTypes.object,
  refreshCall: PropTypes.func.isRequired,
}

export default SerieRow;