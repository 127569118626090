import { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import { creatorSeriesActions } from 'actions/series';

import Row from 'primary/Basket/Item';
import ItemCell from 'primary/Basket/ItemCell';
import Pipi from 'primary/Pipi';

import { When } from 'react-if';

const Item = ({ metadata, symbols, groupName, pattern, userDefinedFilters, setHoveredIndex, setDraggingIndex, hoveredIndex, draggingIndex, index, orgIndex, query }) => {
  const dispatch = useDispatch(),
        filtersValues = useMemo(() => userDefinedFilters.map(udf => {
          if(udf.type === creatorSeriesActions.FILTER_TYPE.GROUP_NAME) {
            return { label: "Group", value: groupName };
          } else if(udf.type === creatorSeriesActions.FILTER_TYPE.METADATA) {
            return { label: udf.filterKey, value: metadata[udf.filterKey] }
          } else if(udf.type === creatorSeriesActions.FILTER_TYPE.SYMBOL) {
            return { label: udf.filterKey, value: symbols[udf.filterKey] }
          }
          return undefined
        }).filter(v => v !== undefined && v.value !== undefined && v.value !== ""), [groupName, metadata, symbols, userDefinedFilters]),
        removeItem = useCallback(() => dispatch(creatorSeriesActions.swapSelectedSymbol({ symbols, metadata, groupName, pattern, query })), [dispatch, groupName, metadata, pattern, symbols, query]),
        onDragAndDrop = useCallback(({ from, to }) => dispatch(creatorSeriesActions.onReorderItem(from, to)), [dispatch])

  return (
    <Row
        beforeChildren={<Pipi isPatternSelected={pattern === "true" || pattern === undefined}/>}
        draggingIndex={draggingIndex}
        hoveredIndex={hoveredIndex}
        index={index}
        onDragAndDrop={onDragAndDrop}
        onRemove={removeItem}
        orgIndex={orgIndex}
        setDraggingIndex={setDraggingIndex}
        setHoveredIndex={setHoveredIndex}
    >
      <When condition={query}>
        {() => (
          <ItemCell
              label="Query"
              value={query}
          />
        )}
      </When>
      {filtersValues.map(({ label, value }, fvIndex) => (
        <ItemCell
            key={`Filter${fvIndex}`}
            label={label}
            value={value}
        />
      ))}
      {Object.entries(symbols).filter(([symbolKey,]) => userDefinedFilters.findIndex(udf => udf.type === creatorSeriesActions.FILTER_TYPE.SYMBOL && udf.filterKey === symbolKey) === -1).map(([symbolKey,symbolValue]) => (
        <ItemCell 
            key={symbolKey}
            label={symbolKey}
            value={symbolValue}
        />
      ))}
      {Object.entries(metadata).filter(([metadataKey,]) => userDefinedFilters.findIndex(udf => udf.type === creatorSeriesActions.FILTER_TYPE.METADATA && udf.filterKey === metadataKey) === -1).map(([metadataKey,metadataValue]) => (
        <ItemCell 
            key={metadataKey}
            label={metadataKey}
            value={metadataValue}
        />
      ))}
      <When condition={userDefinedFilters.findIndex(udf => udf.type === creatorSeriesActions.FILTER_TYPE.GROUP_NAME) === -1}>
        {() => (
          <ItemCell
              label="Group"
              value={groupName}
          />
        )}
      </When>
    </Row>
  )
}

Item.defaultProps = {
  draggingIndex: undefined,
  groupName: undefined,
  hoveredIndex: undefined,
  metadata: {},
  pattern: "true",
  query: undefined,
  symbols: {},
  userDefinedFilters: []
}

Item.propTypes = {
  draggingIndex: PropTypes.number,
  groupName: PropTypes.string, 
  hoveredIndex: PropTypes.number,
  index: PropTypes.number.isRequired,
  metadata: PropTypes.object, 
  orgIndex: PropTypes.number.isRequired,
  pattern: PropTypes.string,
  query: PropTypes.string,
  setDraggingIndex: PropTypes.func.isRequired,
  setHoveredIndex: PropTypes.func.isRequired,
  symbols: PropTypes.object,
  userDefinedFilters: PropTypes.array
}

export default Item;