import { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import { Button } from 'basic-components';
import { globalActions } from 'primary-components';

import classNames from 'classnames';
import './Header.scss';

const Header = ({ isOpen, swapIsOpen, selectedSymbols, onReset, basketName, isOpenAlwaysSecondary }) => {
  const dispatch = useDispatch(),
        selectedSymbolsLength = selectedSymbols.length,
        [symbolsCount, setSymbolsCount] = useState(0),
        [nextSymbolsCount, setNextSymbolsCount] = useState(),
        [isAnimationActive, setIsAnimationActive] = useState(false),
        symbolsCountAnimation = useRef(),
        turnOffAnimation = useRef(),
        onResetLocal = useCallback(() => {
          dispatch(globalActions.getToastsStore()).warning("All changes will be discarded. Do you want to proceed?", "Reset changes",{
            withCancel: false,
            confirmText: "Reset",
            confirmAction: () => {
              onReset()
            }
          })
        }, [dispatch, onReset])

  useEffect(() => {
    setNextSymbolsCount(selectedSymbolsLength)
    setIsAnimationActive(true)
    clearTimeout(symbolsCountAnimation.current)
    symbolsCountAnimation.current = window.setTimeout(() => setSymbolsCount(selectedSymbolsLength), 250)
    clearTimeout(turnOffAnimation.current)
    turnOffAnimation.current = window.setTimeout(() => setIsAnimationActive(false), 500)
  }, [selectedSymbolsLength])

  useEffect(() => () => {
    clearTimeout(turnOffAnimation.current)
    clearTimeout(symbolsCountAnimation.current)
  }, [])

  return (
    <div className="ng-office-app__authed__content__basket__top">
      <div className="ng-office-app__authed__content__basket__top__left">
        <div className="ng-office-app__authed__content__basket__top__left__label">
          {basketName}:
        </div>
        <div className={classNames(
          "ng-office-app__authed__content__basket__top__left__carousel", 
          { "ng-office-app__authed__content__basket__top__left__carousel--is-animation-active": isAnimationActive }
        )}>
          <div className="ng-office-app__authed__content__basket__top__left__carousel__value ng-office-app__authed__content__basket__top__left__carousel__value--is-new">
            {nextSymbolsCount}
          </div>
          <div className="ng-office-app__authed__content__basket__top__left__carousel__value ng-office-app__authed__content__basket__top__left__carousel__value--is-pernament">
            {symbolsCount}
          </div>
        </div>
      </div>
      <div className="ng-office-app__authed__content__basket__top__right">
        <Button
            onClick={onResetLocal}
            size="small"
            variant="text"
        >
          Reset
        </Button>
        <Button
            onClick={swapIsOpen}
            size="small"
            variant={isOpen || isOpenAlwaysSecondary ? "secondary" : "primary"}
        >
          {isOpen ? "Close basket" : "Open basket"}
        </Button>
      </div>
    </div>
  )
}

Header.defaultProps = {
  basketName: "DATA BASKET",
  isOpen: false,
  isOpenAlwaysSecondary: false,
  selectedSymbols: []
}

Header.propTypes = {
  basketName: PropTypes.string,
  isOpen: PropTypes.bool,
  isOpenAlwaysSecondary: PropTypes.bool,
  onReset: PropTypes.func.isRequired,
  selectedSymbols: PropTypes.array,
  swapIsOpen: PropTypes.func.isRequired
}

export default Header;