import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { creatorCurvesActions, settingsCurvesActions } from 'actions/curves';

import Basket from './Basket';
import CurveCreator from './CurveCreator';

import './AddNewCurve.scss';

const AddNewCurve = () => {
  const dispatch = useDispatch()

  useEffect(() => () => {
    dispatch(creatorCurvesActions.clear())
    dispatch(settingsCurvesActions.clear())
  }, [dispatch])

  return (
    <div className="ng-office-app__authed__content__body__item ng-office-app__authed__content__body__item--add-curve">
      <CurveCreator/>
      <Basket/>
    </div>
  )
}

export default AddNewCurve;