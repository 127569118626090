import { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ArrowDown, ArrowUp, Button, Calendar } from 'basic-components';

import { settingsSeriesActions } from 'actions/series';

import DataRangeCore from 'primary/DataRange';

import classNames from 'classnames';
import './DataRange.scss';

const emptyFun = () => {}
const DataRange = () => {
  const dispatch = useDispatch(),
        [isExpanded, setIsExpanded] = useState(false),
        swapExpanded = useCallback(() => setIsExpanded(e => !e), []),
        {

          lastTypeAmount,
          lastType,
          from,
          to,
          fromSelected,
          toSelected,
          range
        } = useSelector(state => state.seriesState.settingsState),
        onChange = useCallback((val, { name }) => dispatch(settingsSeriesActions.onChange(val, name)), [dispatch])

  return (
    <div className={classNames(
      "ng-office-app__authed__content__basket__body__data__range",
      { "ng-office-app__authed__content__basket__body__data__range--is-open": isExpanded }
    )}>
      <div 
          className="ng-office-app__authed__content__basket__body__data__range__top"
          onClick={swapExpanded}
      >
        <Button
            onClick={emptyFun}
            size="small"
            variant="text"
        >
          <Calendar
              color="blue-bright"
              height={16}
              width={16}
          />
          <p>DATE RANGE</p>
        </Button>
        <div className="ng-office-app__authed__content__basket__body__data__range__top__right">
          {isExpanded ? (
            <ArrowDown
                color="blue-bright"
                height={20}
                width={20}
            />
          ) : (
            <ArrowUp
                color="blue-bright"
                height={20}
                width={20}
            />
          )}
        </div>
      </div>
      <DataRangeCore
          from={from}
          fromSelected={fromSelected}
          lastType={lastType}
          lastTypeAmount={lastTypeAmount}
          onChange={onChange}
          range={range}
          to={to}
          toSelected={toSelected}
      />
    </div>
  )
}

export default DataRange;