import { useCallback, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { ListRow } from 'basic-components';
import { ReduxService } from 'dashboard-services';

import Pipi from 'primary/Pipi';

import classNames from 'classnames';
import './SuggestedRow.scss';

const SuggestedRow = ({ symbol, addToBasket, isSelected, isPatternSelected }) => {
  const [isOverflowed, setIsOverflowed] = useState(false),
        addToBasketLocal = useCallback(() => addToBasket(ReduxService.updateObject(symbol, { pattern: "false" })), [addToBasket, symbol]),
        taskPaneWidth = useSelector(state => state.globalState.taskPaneWidth),
        timeoutRef = useRef(),
        ref = useRef()

  useEffect(() => {
    if(ref.current) {
      setIsOverflowed(ref.current.scrollWidth > ref.current.clientWidth)
    } else {
      window.clearTimeout(timeoutRef.current)
      timeoutRef.current = window.setTimeout(() => setIsOverflowed(ref.current.scrollWidth > ref.current.clientWidth), 5)
    }
  }, [taskPaneWidth])

  useEffect(() => () => {
    window.clearTimeout(timeoutRef.current)
  }, [])

  return (
    <ListRow
        beforeChildren={<Pipi isPatternSelected={isPatternSelected}/>}
        className={classNames(
          "ng-office-app__authed__content__starting__block__content__suggested-row",
          { "ng-office-app__authed__content__starting__block__content__suggested-row--is-selected": isSelected },
        )}
        height={44}
        offset={1000}
        onClick={addToBasketLocal}
        unmountIfInvisible={false}
    >
      <div 
          className="ng-office-app__authed__content__starting__block__content__suggested-row__cell"
          onMouseLeave={e => {
            e.target.scrollLeft = 0
          }}
      >
        <p>{symbol.symbols.Symbol}</p>
      </div>
      <div 
          className={classNames(
            "ng-office-app__authed__content__starting__block__content__suggested-row__cell",
            { "ng-office-app__authed__content__starting__block__content__suggested-row__cell--is-overflowed": isOverflowed }
          )}
          onMouseLeave={e => {
            e.target.scrollLeft = 0
          }}
          ref={ref}
      >
        <p>{symbol.metadata.Description}</p>
      </div>
    </ListRow>
  )
}

SuggestedRow.defaultProps = {
  isPatternSelected: false,
  isSelected: false
}

SuggestedRow.propTypes = {
  addToBasket: PropTypes.func.isRequired,
  isPatternSelected: PropTypes.bool,
  isSelected: PropTypes.bool,
  symbol: PropTypes.object.isRequired
}

export default SuggestedRow;