import { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { creatorCurvesActions, settingsCurvesActions } from 'actions/curves';

import Basket from 'primary/Basket';
import CurveSettings from './CurveSettings';
import DataTab from './DataTab';

const emptyArray = []
const BasketLocal = () => {
  const dispatch = useDispatch(),
        [showTooLongFormula, setShowTooLongFormula] = useState(false),
        address = useSelector(state => state.curvesState.creatorState.address),
        selectedCurves = useSelector(state => state.curvesState.creatorState.selectedCurves) || emptyArray,
        selectedColumns = useSelector(state => state.curvesState.creatorState.selectedColumns) || emptyArray,
        isBasketOpen = useSelector(state => state.curvesState.creatorState.isBasketOpen),
        swapIsOpen = useCallback(() => dispatch(creatorCurvesActions.onChange(!isBasketOpen, "isBasketOpen")), [dispatch, isBasketOpen]),
        addToSheet = useCallback(async () => dispatch(creatorCurvesActions.addToSheet()), [dispatch]),
        onReset = useCallback(() => {
          dispatch(creatorCurvesActions.clear())
          dispatch(settingsCurvesActions.clear())
        }, [dispatch])

  return (
    <Basket
        address={address}
        addToSheet={addToSheet}
        basketName="CURVE BASKET"
        dataTab={<DataTab/>}
        dataTabName="CURVES"
        footerDisabled={selectedColumns.length === 0 || selectedCurves.length === 0}
        isBasketOpen={isBasketOpen}
        isOpenAlwaysSecondary
        onReset={onReset}
        selectedItems={selectedCurves}
        setShowTooLongFormula={setShowTooLongFormula}
        settings={<CurveSettings/>}
        showTooLongFormula={showTooLongFormula}
        swapIsOpen={swapIsOpen}
    />
  )
}
export default BasketLocal;