import { getColor } from 'basic-components';
import { ActionService, CSV_HEADERS_TYPES, DataStorageApi, PERIOD_TYPE, tsDataRangesObject, tsKeysTypes, tsLastTypesObject, tsOrder } from 'dashboard-services';
import { globalActions } from 'primary-components';

import getApiConfig from 'api/ApiConfig';
import { CurvesUtils, LocalStorageUtils, TimeSeriesUtils } from 'utils';

import { MetadataLabel } from 'primary/TsSettings';

// series actions are not dependent on old TimeSeries actions or components
export default (() => {

  const defaultState = {
    range: tsDataRangesObject.last,
    lastTypeAmount: "1",
    lastType: tsLastTypesObject.value,
    
    fromSelected: undefined,
    from: undefined,
    
    toSelected: undefined,
    to: undefined,

    symbolSize: undefined,
    csvHeaders: CurvesUtils.DEFAULT_CSV_HEADERS,
    dateFormat: TimeSeriesUtils.DAILY_DATE_FORMAT,
    timeZone: undefined,
    periodType: PERIOD_TYPE.MONTH,
    contractSort: tsOrder.ASC,
    isRelative: "false",
    fillBlanks: ""
  }
  
  const ON_CHANGE = 'ON_CHANGE_SETTINGS_CURVES'
  const onChange = ActionService.makeActionCreator(ON_CHANGE, 'value', 'name')

  const CLEAR = 'CLEAR_LIST_SETTINGS_CURVES'
  const clear = ActionService.makeActionCreator(CLEAR)

  const saveDefaultSettings = () => (_, getState) => {
    // eslint-disable-next-line no-unused-vars
    const itemsToSave = getState().curvesState.settingsState
    LocalStorageUtils.setCurveDefaultSettings(itemsToSave)
  }

  const clearDefaultSettings = () => dispatch => {
    // eslint-disable-next-line no-unused-vars
    const itemsToSave = defaultState
    LocalStorageUtils.setCurveDefaultSettings(itemsToSave)
    dispatch(clear())
    dispatch(globalActions.getMessageStore()).success("Default settings has been restored.")
  }

  const KEYS_TYPE_ORDER = [CSV_HEADERS_TYPES.SYMBOL_KEY, CSV_HEADERS_TYPES.METADATA_KEY];
  const prepareKeysResponse = response => response
          .flatMap(p => p)
          .reduce((acc, current) => {
            if (!acc.some(obj => obj.value === current.value)) {
              acc.push(current);
            }
            return acc;
          }, [])
          .sort((a,b) => {
            const typeA = KEYS_TYPE_ORDER.indexOf(a.type)
            const typeB = KEYS_TYPE_ORDER.indexOf(b.type)
            if (typeA < typeB) return -1
            if (typeA > typeB) return 1
  
            if (a.value < b.value) return -1
            if (a.value > b.value) return 1
  
            return 0
          })

  const getKeysForGroups = ({ input, callsRefs }) => (dispatch, getState) => {
    const selectedCurves = getState().curvesState.creatorState.selectedCurves

    const promises = selectedCurves.map((curve, index) => {
      callsRefs.current[index]?.cancel()
      const filterMdObj = {
        [CurvesUtils.ROOT]: [curve.root],
        [CurvesUtils.PRODUCT]: [curve.product]
      }
      callsRefs.current[index] = new DataStorageApi(dispatch(getApiConfig()))
        .getSymbolKeys(curve.groupName || "", input ? `*${input}*` :  "*", { from: 0, size: 100, metadata: true, useEs: true, metadatas: filterMdObj })
        .cancelable(true)
        .noFetching()
        .build()
        .call()

        return callsRefs.current[index].promise.then((response = {}) => 
          (response?.items || []).filter(item => item.type !== tsKeysTypes.SYMBOL).map(item => ({ 
            value: item.name, 
            label: <MetadataLabel name={item.name}/>,
            color: getColor("grey-dark"),
            type: `${item.type}_KEY`
          }))
        )
    })
    return Promise.all(promises).then(response => prepareKeysResponse(response))
  }

  return {
    onChange,
    ON_CHANGE,
    CLEAR,
    clear,
    saveDefaultSettings,
    clearDefaultSettings,
    defaultState,
    getKeysForGroups
  }
})()