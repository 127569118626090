import { Fragment, memo } from 'react';
import PropTypes from 'prop-types';

import { Radio, RadioGroup } from 'basic-components';
import { tsOrder } from 'dashboard-services';

const ContractSort = ({ value, onChange }) => (
  <Fragment>
    <div 
        className="ng-office-app__authed__content__body__item__expanded-settings__bottom__label"
        style={{ marginTop: 12 }}
    >
      Contract sort order
    </div>
    <div className="ng-office-app__authed__content__body__item__expanded-settings__bottom__value ng-office-app__authed__content__body__item__expanded-settings__bottom__value--radio">
      <RadioGroup
          name="contractSort"
          onChange={onChange}
          selectedValue={value}
      >
        <Radio name={tsOrder.ASC}>
          Ascending
        </Radio>
        <Radio name={tsOrder.DESC}>
          Descending
        </Radio>
      </RadioGroup>
    </div>
  </Fragment>
)

ContractSort.defaultProps = {
  value: undefined,
}

ContractSort.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string,
}

export default memo(ContractSort)