import { Fragment } from 'react';
import PropTypes from 'prop-types';

import { DateFormatPicker } from 'primary-components';

const DateFormat = ({ onChange, dateFormat }) => (
  <Fragment>
    <div className="ng-office-app__authed__content__body__item__expanded-settings__bottom__label">
      Date format
    </div>
    <div className="ng-office-app__authed__content__body__item__expanded-settings__bottom__value">
      <DateFormatPicker
          dateFormat={dateFormat}
          fixed
          menuPortalTarget={document.querySelector('.ng-office-app')}
          noError
          onChange={onChange}
          variant="border"
      />
    </div>
  </Fragment>
)

DateFormat.defaultProps = {
  dateFormat: undefined,
}

DateFormat.propTypes = {
  dateFormat: PropTypes.string,
  onChange: PropTypes.func.isRequired, 
}

export default DateFormat;