import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Button } from 'basic-components';
import { formatTypes } from 'dashboard-services';

import { settingsCurvesActions } from 'actions/curves';
import { CurvesUtils } from 'utils';

import DataRange from 'primary/DataRange';
import { DateFormat, OutputType, SetAsDefaultSettings, TimeAndDate, TimeZone } from 'primary/TsSettings';
import ContractSort from './ContractSort';
import FillBlanks from './FillBlanks';
import IsRelative from './IsRelative';
import LimitContracts from './LimitContracts';
import PeriodType from './PeriodType';

import './CurveSettings.scss';

const CurveSettings = () => {
  const dispatch = useDispatch(),
        { 
          range,
          lastTypeAmount,
          lastType,
          from,
          to,
          fromSelected,
          toSelected,
          csvHeaders,
          timeZone,
          dateFormat,
          periodType,
          symbolSize,
          contractSort,
          isRelative,
          fillBlanks
        } = useSelector(state => state.curvesState.settingsState),
        onSaveDefaultSettings = useCallback(() => dispatch(settingsCurvesActions.saveDefaultSettings()), [dispatch]),
        onClearDefaultSettings = useCallback(() => dispatch(settingsCurvesActions.clearDefaultSettings()), [dispatch]),
        onChange = useCallback((val, { name }) => dispatch(settingsCurvesActions.onChange(val, name)), [dispatch]),
        getKeys = useCallback(({ input, callsRefs }) => dispatch(settingsCurvesActions.getKeysForGroups({ input, callsRefs })), [dispatch])

  return (
    <div className="ng-office-app__authed__content__basket__body__settings">
      <SetAsDefaultSettings onSave={onSaveDefaultSettings}/>
      <OutputType
          additionalCsvHeaders={CurvesUtils.CSV_HEADER_OPTIONS}
          csvHeaders={csvHeaders}
          formatType={formatTypes.CSV}
          getKeys={getKeys}
          onChange={onChange}
          withOutputType={false}
          withTranspose={false}
      >
        <LimitContracts
            onChange={onChange}
            value={symbolSize}
        />
        <FillBlanks
            onChange={onChange}
            value={fillBlanks}
        />
      </OutputType>
      <TimeAndDate>
        <PeriodType
            onChange={onChange}
            value={periodType}
        />
        <DataRange
            from={from}
            fromSelected={fromSelected}
            lastType={lastType}
            lastTypeAmount={lastTypeAmount}
            onChange={onChange}
            range={range}
            to={to}
            toSelected={toSelected}
            withAdditionalToday
        />
        <TimeZone
            onChange={onChange}
            timeZone={timeZone}
        />
        <DateFormat
            dateFormat={dateFormat}
            onChange={onChange}
        />
        <ContractSort
            onChange={onChange}
            value={contractSort}
        />
        <IsRelative
            onChange={onChange}
            value={isRelative}
        />
      </TimeAndDate>
      <div className="ng-office-app__authed__content__basket__body__settings__reset">
        <Button
            onClick={onClearDefaultSettings}
            size="small"
            variant="text"
        >
          Reset default settings
        </Button>
      </div>
    </div>
  )
}

export default CurveSettings;