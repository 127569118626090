import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { formatTypes, tsOrder } from 'dashboard-services';

import { listTimeseriesActions } from 'actions/timeseries';

import TsSettings, { ColumnsOrder, DateFormat, DateOrder, DateRange, OutputType, SetAsDefaultSettings, TimeAndDate, TimeZone } from 'primary/TsSettings';

import { When } from 'react-if';

const Settings = () => {
  const dispatch = useDispatch(),
        { 
          formatType,
          csvHeaders,
          timeZone,
          range,
          lastTypeAmount,
          lastType,
          from,
          fromTime,
          to,
          toTime,
          fromSelected,
          toSelected,
          dateFormat,
          order = tsOrder.DESC,
          shouldTranspose = "false",
          sortKeys = "false", 
          sortKeysBy,
          sortKeysOrder = tsOrder.ASC
        } = useSelector(state => state.timeseriesState.listState),
        onSaveDefaultSettings = useCallback(() => dispatch(listTimeseriesActions.saveDefaultSettings()), [dispatch]),
        onClearDefaultSettings = useCallback(() => dispatch(listTimeseriesActions.clearDefaultSettings()), [dispatch]),
        onChange = useCallback((val, { name }) => dispatch(listTimeseriesActions.onChange(val, name)), [dispatch]),
        getKeys = useCallback(({ input, callsRefs }) => dispatch(listTimeseriesActions.getKeysForGroups({ input, callsRefs })), [dispatch])
  return (
    <TsSettings onClearDefaultSettings={onClearDefaultSettings}>
      <SetAsDefaultSettings onSave={onSaveDefaultSettings}/>
      <OutputType
          csvHeaders={csvHeaders}
          formatType={formatType}
          getKeys={getKeys}
          onChange={onChange}
          shouldTranspose={shouldTranspose}
          withOutputType
          withTranspose
      />
      <TimeAndDate
          dateFormat={dateFormat}
          from={from}
          fromSelected={fromSelected}
          fromTime={fromTime}
          lastType={lastType}
          lastTypeAmount={lastTypeAmount}
          onChange={onChange}
          range={range}
          timeZone={timeZone}
          to={to}
          toSelected={toSelected}
          toTime={toTime}
      >
        <TimeZone
            onChange={onChange}
            timeZone={timeZone}
        />
        <DateFormat
            dateFormat={dateFormat}
            onChange={onChange}
        />
        <DateRange
            from={from}
            fromSelected={fromSelected}
            fromTime={fromTime}
            lastType={lastType}
            lastTypeAmount={lastTypeAmount}
            onChange={onChange}
            range={range}
            to={to}
            toSelected={toSelected}
            toTime={toTime}
        />
        <DateOrder
            onChange={onChange}
            order={order}
            range={range}
        />
      </TimeAndDate>
      <When condition={formatType === formatTypes.CSV}>
        {() => (
          <ColumnsOrder
              getKeys={getKeys}
              onChange={onChange}
              sortKeys={sortKeys}
              sortKeysBy={sortKeysBy}
              sortKeysOrder={sortKeysOrder}
          />
        )}
      </When>
    </TsSettings>
  )
}

export default Settings;