import { ReduxService } from 'dashboard-services';

import { creatorSeriesActions } from 'actions/series';

export const creatorInitialState = {
  address: undefined,
  filters: {},
  userDefinedFilters: [],
  selectedSymbols: [],
  suggested: [],
  query: undefined,

  isBasketOpen: false
}

export default (state = creatorInitialState, action) => {
  switch (action.type) {
    case creatorSeriesActions.ON_CHANGE:
      return ReduxService.updateObject(state, { [action.name]: action.value })
    case creatorSeriesActions.ON_CHANGE_OBJECT:
      return ReduxService.updateObject(state, { [action.name]: ReduxService.updateObject(state[action.name], { [action.fieldName]: action.value }) })
    case creatorSeriesActions.SWAP_SELECTED_SYMBOL:
      return ReduxService.updateObject(state, { selectedSymbols: action.index === -1 ? state.selectedSymbols.add(action.symbol) : state.selectedSymbols.remove(action.index) })
    case creatorSeriesActions.ON_REORDER_ITEM:
      return ReduxService.updateObject(state, { selectedSymbols: state.selectedSymbols.moveItem(action.from, action.to) })
    case creatorSeriesActions.CLEAR:{
      const userDefinedFilters = state.userDefinedFilters;
      const suggested = state.suggested;
      return ReduxService.updateObject(creatorInitialState, { userDefinedFilters, suggested });
    }
    default:
      return state;
  }
}