import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import classNames from 'classnames';

const SymbolCell = ({ children }) => {
  const taskPaneWidth = useSelector(state => state.globalState.taskPaneWidth),
        [isOverflowed, setIsOverflowed] = useState(false),
        timeoutRef = useRef(),
        ref = useRef()

  useEffect(() => {
    if(ref.current) {
      setIsOverflowed(ref.current.scrollWidth > ref.current.clientWidth)
    } else {
      window.clearTimeout(timeoutRef.current)
      timeoutRef.current = window.setTimeout(() => setIsOverflowed(ref.current.scrollWidth > ref.current.clientWidth), 5)
    }
  }, [taskPaneWidth])

  useEffect(() => () => {
    window.clearTimeout(timeoutRef.current)
  }, [])

  return (
    <div 
        className="ng-office-app__authed__content__results__body__scroll__row__cell"
        onMouseLeave={e => {
          e.target.scrollLeft = 0
        }}
    >
      <div 
          className={classNames(
            "ng-office-app__authed__content__results__body__scroll__row__cell__content",
            { "ng-office-app__authed__content__results__body__scroll__row__cell__content--is-overflowed": isOverflowed }
          )}
          ref={ref}
      >
        {children}
      </div>
    </div>
  )
}

SymbolCell.defaultProps = {
  children: ""
}

SymbolCell.propTypes = {
  children: PropTypes.string
}

export default SymbolCell;