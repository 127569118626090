import { ReduxService } from 'dashboard-services';

import { settingsSeriesActions } from 'actions/series';
import { LocalStorageUtils } from 'utils';

export const getSettingsInitialState = () => ReduxService.updateObject(settingsSeriesActions.defaultState, LocalStorageUtils.getTsDefaultSettings())

export default (state = getSettingsInitialState(), action) => {
  switch (action.type) {
    case settingsSeriesActions.ON_CHANGE:
      return ReduxService.updateObject(state, { [action.name]: action.value })
    case settingsSeriesActions.CLEAR: 
      return getSettingsInitialState();
    default:
      return state;
  }
}