import PropTypes from 'prop-types';

import './Pipi.scss';

const Pipi = ({ isPatternSelected }) => (
  <div 
      className="pipi"
      style={isPatternSelected ? undefined : { visibility: "hidden" }}
  />
)

Pipi.defaultProps = {
  isPatternSelected: false
}

Pipi.propTypes = {
  isPatternSelected: PropTypes.bool
}

export default Pipi;