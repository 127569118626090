import { Fragment, useCallback, useEffect, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Add, Button, Form, FormRow, Label, LoadMoreDropdown } from 'basic-components';
import { DropdownService, HooksService } from 'dashboard-services';

import { creatorCurvesActions } from 'actions/curves';
import getApiConfig from 'api/ApiConfig';
import { CurvesUtils } from 'utils';

import TooLongWarn from 'primary/TooLongWarn';

import { When } from 'react-if';

import './CurveCreator.scss';

const emptyPromise = () => Promise.resolve([])
const CurveCreator = () => {
  const dispatch = useDispatch(),
        isMounted = HooksService.useIsMounted(),
        { 
          groupName,
          product,
          root,
          showTooLongFormula,
          isBasketOpen
        } = useSelector(state => state.curvesState.creatorState),
        { searchGroups, hasMoreGroups } = DropdownService.useSearchGroups(dispatch(getApiConfig()), isMounted, { symbolsOnly: true, extraQuery: "&fields.name=Forward_Curve" }),
        onChangeRedux = useCallback((val, { name }) => dispatch(creatorCurvesActions.onChange(val, name)), [dispatch]),
        onChangeRoot = useCallback(val => onChangeRedux(val, { name: "root" }), [onChangeRedux]),
        onChangeProduct = useCallback(val => {
          onChangeRoot(undefined)
          onChangeRedux(val, { name: "product" })
        }, [onChangeRedux, onChangeRoot]),
        onChangeGroupName = useCallback(val => {
          onChangeProduct(undefined)
          onChangeRedux(val, { name: "groupName" })
        }, [onChangeProduct, onChangeRedux]),
        { hasMoreSymbolValues: hasMoreProducts, searchSymbolValues: searchProducts } = DropdownService.useSearchSymbolValues(dispatch(getApiConfig()), { 
          key: CurvesUtils.PRODUCT,
          groupName,
          metadata: true,
          useEs: true
        }),
        rootMetadatas = useMemo(() => product ? { [CurvesUtils.PRODUCT]: [product] } : {}, [product]),
        { hasMoreSymbolValues: hasMoreRoots, searchSymbolValues: searchRoots } = DropdownService.useSearchSymbolValues(dispatch(getApiConfig()), { 
          key: CurvesUtils.ROOT,
          groupName,
          metadata: true,
          metadatas: rootMetadatas,
          useEs: true
        }),
        hideWarns = useCallback(() => onChangeRedux(false, { name: "showTooLongFormula" }), [onChangeRedux]),
        addItem = useCallback(() => {
          dispatch(creatorCurvesActions.addCurve({
            groupName,
            product,
            root
          }))
          onChangeGroupName(undefined)
          dispatch(creatorCurvesActions.onChange(true, "isBasketOpen"))
        }, [dispatch, groupName, onChangeGroupName, product, root]),
        prevGroupName = HooksService.usePrevious(groupName),
        prevProduct = HooksService.usePrevious(product),
        groupRef = useRef(),
        productRef = useRef(),
        rootRef = useRef()

  useEffect(() => {
    if(groupName !== prevGroupName) {
      productRef.current?.refreshOptions?.()
      prevGroupName && productRef?.current?.dropdown?.current?.setValue()
      !groupName && groupRef?.current?.dropdown?.current?.setValue()
      !product && productRef.current?.dropdown?.current?.focus?.()
    }
  }, [groupName, prevGroupName, product])

  useEffect(() => {
    if(product !== prevProduct) {
      rootRef.current?.refreshOptions?.()
      prevProduct && rootRef?.current?.dropdown?.current?.setValue()
      !root && rootRef.current?.dropdown?.current?.focus?.()
    }
  }, [prevProduct, product, root])

  return (
    <Fragment>
      <Form className="ng-office-app__authed__content__body__item__curve-creator">
        <FormRow 
            className="ng-office-app__authed__content__body__item__curve-creator__row"
            isVertical
        >
          <Label>GROUP</Label>
          <LoadMoreDropdown
              autoFocus={!groupName && !isBasketOpen}
              defaultValue={groupName}
              hasMore={hasMoreGroups}
              loadOptions={searchGroups}
              menuPlacement="bottom"
              menuPortalTarget={document.querySelector('.ng-office-app')}
              name="groupName"
              noError
              onChange={onChangeGroupName}
              openMenuOnFocus
              placeholder="Choose source group"
              ref={groupRef}
              variant="border"
          />
        </FormRow>
        <FormRow 
            className="ng-office-app__authed__content__body__item__curve-creator__row"
            isVertical
        >
          <Label>PRODUCT</Label>
          <LoadMoreDropdown
              defaultValue={product}
              disabled={!groupName}
              fixed
              hasMore={hasMoreProducts}
              loadOptions={groupName ? searchProducts : emptyPromise}
              menuPlacement="bottom"
              menuPortalTarget={document.querySelector('.ng-office-app')}
              name="product"
              noError
              onChange={onChangeProduct}
              openMenuOnFocus
              placeholder="Choose product"
              ref={productRef}
              variant="border"
          />
        </FormRow>
        <FormRow 
            className="ng-office-app__authed__content__body__item__curve-creator__row"
            isVertical
        >
          <Label>ROOT</Label>
          <LoadMoreDropdown
              defaultValue={root}
              disabled={!product || !groupName}
              fixed
              hasMore={hasMoreRoots}
              loadOptions={groupName && product ? searchRoots : emptyPromise}
              menuPlacement="bottom"
              menuPortalTarget={document.querySelector('.ng-office-app')}
              name="root"
              noError
              onChange={onChangeRoot}
              openMenuOnFocus
              placeholder="Choose root"
              ref={rootRef}
              variant="border"
          />
        </FormRow>
        <div className="ng-office-app__authed__content__body__item__curve-creator__divider"/>
        <div className="ng-office-app__authed__content__body__item__curve-creator__submit">
          <Button
              disabled={!product || !root || !groupName}
              onClick={addItem}
              size="small"
              variant="primary"
          >
            <Add
                color={!product || !root || !groupName ? "grey-medium" : "white-base"}
                height={16}
                width={16}
            />
            <div>Add curve to the basket</div>
          </Button>
        </div>
      </Form>
      <When condition={showTooLongFormula}>
        {() => (
          <TooLongWarn onClose={hideWarns}/>
        )}
      </When>
    </Fragment>
  )
}

export default CurveCreator;