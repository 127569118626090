import { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { dateBetweenTypes, TimeService, tsDataRangesObject, tsOrder } from 'dashboard-services';

import { creatorSeriesActions, settingsSeriesActions } from 'actions/series';
import FORMULAS from 'resources/constants/Formulas.js';
import { FormulaUtils, GAUtils } from 'utils';

import Basket from 'primary/Basket';
import DataTab from './DataTab';
import Settings from './Settings';

import dayjs from 'dayjs';

const emptyArray = [],
      addAndValidateParam = FormulaUtils.addAndValidateParam
const BasketLocal = () => {
  const dispatch = useDispatch(),
        [showTooLongFormula, setShowTooLongFormula] = useState(false),
        address = useSelector(state => state.seriesState.creatorState.address),
        selectedSymbols = useSelector(state => state.seriesState.creatorState.selectedSymbols) || emptyArray,
        selectedColumns = useSelector(state => state.seriesState.creatorState.selectedColumns) || emptyArray,
        isBasketOpen = useSelector(state => state.seriesState.creatorState.isBasketOpen),
        swapIsOpen = useCallback(() => dispatch(creatorSeriesActions.onChange(!isBasketOpen, "isBasketOpen")), [dispatch, isBasketOpen]),
        addToSheet = useCallback(async () => {
          GAUtils.sendEvent({
            category: "action",
            action: "add_ts",
            label: "add"
          })
          const paramsExceedingLength = []
          let formula = `=${FORMULAS.LOAD_TS}(`
          selectedSymbols.forEach(({ groupName, symbols = {}, metadata = {}, pattern = "true", query }, index) => {
            formula += addAndValidateParam({ paramName: "groupName", paramValue: groupName || "", paramsExceedingLength, withComma: index > 0 })
            formula += addAndValidateParam({ paramName: "columns", paramValue: selectedColumns.join(","), paramsExceedingLength })
            formula += addAndValidateParam({ paramName: "symbols", paramValue: `""{${Object.entries(symbols).map(([key, value]) => `""${key}"":""${value}""`).join(", ")}}""`, paramsExceedingLength })
            formula += addAndValidateParam({ paramName: "metadatas", paramValue: `""{${Object.entries(pattern === "true" ? metadata : {}).map(([key, value]) => `""${key}"":""${value}""`).join(", ")}}""`, paramsExceedingLength })
            formula += addAndValidateParam({ paramName: "pattern", paramValue: pattern, paramsExceedingLength })
            formula += addAndValidateParam({ paramName: "query", paramValue: query || "", paramsExceedingLength })
          })
          const { timeZone, range, from, to, fromSelected, toSelected, lastType, lastTypeAmount, dateFormat, order, formatType, csvHeaders, shouldTranspose, sortKeys = "false", sortKeysBy, sortKeysOrder = tsOrder.ASC } = dispatch(creatorSeriesActions.getParams())
          if(timeZone) {
            formula += addAndValidateParam({ paramName: "timeZone", paramValue: timeZone, paramsExceedingLength })
          }
          if(dateFormat) {
            formula += addAndValidateParam({ paramName: "dateFormat", paramValue: dateFormat, paramsExceedingLength })
          }
          if(range) {
            formula += addAndValidateParam({ paramName: "range", paramValue: range, paramsExceedingLength })
          }
          if(range === tsDataRangesObject.between && (from || to)) {
            if(from) {
              const startDateValue = fromSelected === dateBetweenTypes.date && from ? dayjs(from + "T00:00:00.000").format(TimeService.ISO_DATE_TIME_FORMAT_BACKEND_WITH_SECONDS_STYLE) : undefined
              formula += addAndValidateParam({ paramName: "startDate", paramValue: startDateValue, paramsExceedingLength })
            }
            if(to) {
              const endDateValue = toSelected === dateBetweenTypes.date && to ? dayjs(to  + "T23:59:59.999").format(TimeService.ISO_DATE_TIME_FORMAT_BACKEND_WITH_SECONDS_STYLE) : undefined
              formula += addAndValidateParam({ paramName: "endDate", paramValue: endDateValue, paramsExceedingLength })
            }
          }
          if(range === tsDataRangesObject.last || range === tsDataRangesObject.next) {
            formula += addAndValidateParam({ paramName: "lastType", paramValue: lastType, paramsExceedingLength })
            formula += addAndValidateParam({ paramName: "lastTypeAmount", paramValue: lastTypeAmount, paramsExceedingLength })
          }
          if(order) {
            formula += addAndValidateParam({ paramName: "order", paramValue: order, paramsExceedingLength })
          }
          if(formatType) {
            formula += addAndValidateParam({ paramName: "formatType", paramValue: formatType, paramsExceedingLength })
          }
          if(csvHeaders) {
            const prepColumHeader = FormulaUtils.prepColumnHeaders({ csvHeaders, formatType })
            formula += addAndValidateParam({ paramName: "csvHeaders", paramValue: `""[${prepColumHeader}]""`, paramsExceedingLength })
          }
          if(sortKeys === "true") {
            formula += addAndValidateParam({ paramName: "sortKeys", paramValue: sortKeys, paramsExceedingLength })
            formula += addAndValidateParam({ paramName: "sortKeysBy", paramValue: `""{${Object.entries(sortKeysBy || {}).map(([key, value]) => `""${key}"":""${value}""`).join(", ")}}""`, paramsExceedingLength })
            formula += addAndValidateParam({ paramName: "sortKeysOrder", paramValue: sortKeysOrder, paramsExceedingLength })
          }
          if(shouldTranspose !== undefined) {
            formula += addAndValidateParam({ paramName: "shouldTranspose", paramValue: shouldTranspose, paramsExceedingLength })
          }
          formula += ")"
          const setAddress = addressLocal => dispatch(creatorSeriesActions.onChange(addressLocal, "address"))
          FormulaUtils.formulaValidator({ formula, paramsExceedingLength, address, setShowTooLongFormula, setAddress })
        }, [address, dispatch, selectedColumns, selectedSymbols]),
        onReset = useCallback(() => {
          dispatch(creatorSeriesActions.clear())
          dispatch(settingsSeriesActions.clear())
        }, [dispatch])
  return (
    <Basket
        address={address}
        addToSheet={addToSheet}
        dataTab={<DataTab/>}
        footerDisabled={selectedColumns.length === 0 || selectedSymbols.length === 0}
        isBasketOpen={isBasketOpen}
        onReset={onReset}
        selectedItems={selectedSymbols}
        setShowTooLongFormula={setShowTooLongFormula}
        settings={<Settings/>}
        showTooLongFormula={showTooLongFormula}
        swapIsOpen={swapIsOpen}
    />
  )
}
export default BasketLocal;