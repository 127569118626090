import PropTypes from 'prop-types';

import { MenuTab, MenuTabs } from 'basic-components';

import Footer from './Footer';
import Header from './Header';

import { When } from 'react-if';

import classNames from 'classnames';
import './Basket.scss';

const Basket = ({ 
  isBasketOpen,
  swapIsOpen,
  onReset,
  selectedItems,
  addToSheet, 
  footerDisabled,
  dataTabName,
  dataTab, 
  settings,
  showTooLongFormula,
  setShowTooLongFormula,
  basketName,
  isOpenAlwaysSecondary,
  address
}) => (
  <div className={classNames(
    "ng-office-app__authed__content__basket",
    { "ng-office-app__authed__content__basket--is-open": isBasketOpen }
  )}>
    <Header 
        basketName={basketName}
        isOpen={isBasketOpen}
        isOpenAlwaysSecondary={isOpenAlwaysSecondary}
        onReset={onReset}
        selectedSymbols={selectedItems}
        swapIsOpen={swapIsOpen}
    />
    <When condition={isBasketOpen}>
      {() => (
        <div className="ng-office-app__authed__content__basket__body">
          <MenuTabs>
            <MenuTab name={dataTabName}>
              {dataTab}
              <Footer
                  address={address}
                  addToSheet={addToSheet}
                  disabled={footerDisabled}
                  setShowTooLongFormula={setShowTooLongFormula}
                  showTooLongFormula={showTooLongFormula}
              />
            </MenuTab>
            <MenuTab name="SETTINGS">
              {settings}
              <Footer
                  address={address}
                  addToSheet={addToSheet}
                  disabled={footerDisabled}
                  setShowTooLongFormula={setShowTooLongFormula}
                  showTooLongFormula={showTooLongFormula}
              />
            </MenuTab>
          </MenuTabs>
        </div>
      )}
    </When>
  </div>
)

Basket.defaultProps = {
  address: undefined,
  basketName: undefined,
  dataTabName: "DATA",
  footerDisabled: false,
  isOpenAlwaysSecondary: false,
  selectedItems: [],
  showTooLongFormula: false
}
Basket.propTypes = {
  address: PropTypes.string,
  addToSheet: PropTypes.func.isRequired,
  basketName: PropTypes.string,
  dataTab: PropTypes.element.isRequired,
  dataTabName: PropTypes.string,
  footerDisabled: PropTypes.bool,
  isBasketOpen: PropTypes.bool.isRequired,
  isOpenAlwaysSecondary: PropTypes.bool,
  onReset: PropTypes.func.isRequired,
  selectedItems: PropTypes.array,
  setShowTooLongFormula: PropTypes.func.isRequired,
  settings: PropTypes.element.isRequired,
  showTooLongFormula: PropTypes.bool,
  swapIsOpen: PropTypes.func.isRequired,
}

export default Basket;