import { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Dropdown } from 'basic-components';
import { DataStorageApi, HooksService } from 'dashboard-services';

import { creatorSeriesActions } from 'actions/series';
import getApiConfig from 'api/ApiConfig';

import './Columns.scss';

const emptyArray = []
const Columns = () => {
  const dispatch = useDispatch(),
        selectedSymbols = useSelector(state => state.seriesState.creatorState.selectedSymbols) || emptyArray,
        selectedColumns = useSelector(state => state.seriesState.creatorState.selectedColumns) || emptyArray,
        [columns, setColumns] = useState([]),
        calls = useRef([]),
        onChange = useCallback((value, { name }) => dispatch(creatorSeriesActions.onChange(value, name)), [dispatch]),
        prevColumns = HooksService.usePrevious(columns),
        getColumns = useCallback(() => {
          const promises = []
          selectedSymbols.forEach(symbol => {
            if(symbol.pattern === undefined || symbol.pattern === "true" || !symbol.values) {
              const cancelablePromise = 
                new DataStorageApi(dispatch(getApiConfig()))
                  .getColumns(symbol.groupName, undefined, { symbols: symbol.symbols, metadatas: symbol.metadata, from: 0, size: 50, metadata: true, useEs: true })
                  .noFetching(true)
                  .cancelable(true)
                  .build()
                  .call()
              calls.current.push(cancelablePromise)
              promises.push(
                cancelablePromise.promise
                  .then(response => (response?.items || []).map(item => item?.name))
                  .catch(e => {
                    console.error(e)
                    return []
                  })
                )
            } else {
              promises.push(Promise.resolve().then(() => symbol.values))
            }
          })
          return Promise.all(promises).then(promisesResponses => {
            const columns = promisesResponses.flat().toUnique().sort((a, b) => a.localeCompare(b)).map(v => ({ label: v, value: v }))
            return columns;
          })
        }, [dispatch, selectedSymbols]),
        selectedColumnsLength = selectedColumns?.length,
        prevSelectedColumnsLength = HooksService.usePrevious(selectedColumnsLength),
        columnsRef = useRef()

  useEffect(() => {
    if(selectedColumns.includes("*") && columns?.length > 0) {
      columnsRef.current.dropdown.current.setValue(columns)
    } else if((!prevColumns || prevColumns?.length === 0) && columns[0] && selectedColumns.length === 0 && prevSelectedColumnsLength !== 1) {
      columnsRef.current.dropdown.current.setValue([columns[0]])
    }
  }, [columns, onChange, prevColumns, prevSelectedColumnsLength, selectedColumns])

  useEffect(() => {
    getColumns().then(items => setColumns(items))
  }, [getColumns])

  useEffect(() => () => {
    calls.current?.forEach(c => c?.cancel?.())
  }, [])

  return (
    <div className="ng-office-app__authed__content__basket__body__data__columns">
      <span className="ng-office-app__authed__content__basket__body__data__columns__label">Columns</span>
      <div className="ng-office-app__authed__content__basket__body__data__columns__dropdown">
        <Dropdown
            creatable
            defaultValue={selectedColumns}
            disabled={selectedSymbols.length === 0}
            fixed
            menuPlacement="bottom"
            menuPortalTarget={document.querySelector('.ng-office-app')}
            multi
            name="selectedColumns"
            noError
            onChange={onChange}
            options={columns}
            placeholder="Columns"
            ref={columnsRef}
            variant="border"
        />
      </div>
    </div>
  )
}

export default Columns;