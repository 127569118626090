import { useCallback, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Add, Button } from 'basic-components';

import { creatorCurvesActions } from 'actions/curves';

import Item from './Item';

const emptyArray = []
const Items = () => {
  const dispatch = useDispatch(),
        [hoveredIndex, setHoveredIndex] = useState(-1),
        [draggingIndex, setDraggingIndex] = useState(-1),
        selectedCurves = useSelector(state => state.curvesState.creatorState.selectedCurves) || emptyArray,
        closeBasket = useCallback(() => dispatch(creatorCurvesActions.onChange(false, "isBasketOpen")), [dispatch]),
        scrollContRef = useRef()

  return (
    <div 
        className="ng-office-app__authed__content__basket__body__data__basket__items"
        ref={scrollContRef}
    >
      {selectedCurves.map(({ groupName, product, root }, curveIndex) => (
        <Item
            draggingIndex={draggingIndex}
            groupName={groupName}
            hoveredIndex={hoveredIndex}
            index={curveIndex}
            key={`${curveIndex}`}
            product={product}
            root={root}
            setDraggingIndex={setDraggingIndex}
            setHoveredIndex={setHoveredIndex}
        />
      ))}
      <div className="ng-office-app__authed__content__basket__body__data__basket__items__more">
        <Button
            onClick={closeBasket}
            size="small"
            variant="text"
        >
          <Add
              color="blue-bright"
              height={12}
              width={12}
          />
          <div>Add more</div>
        </Button>
      </div>
    </div>
  )
}

export default Items;