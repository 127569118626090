import PropTypes from 'prop-types';

import './HeaderNoSearch.scss';

const HeaderNoSearch = ({ children, icon, title }) => (
  <div className="ng-office-header-no-search">
    <div className="ng-office-header-no-search__top">
      <div className="ng-office-header-no-search__top__icon">
        {icon}
      </div>
      <div className="ng-office-header-no-search__top__title">
        {title}
      </div>
    </div>
    {children}
  </div>
)

HeaderNoSearch.defaultProps = {
  children: null
}

HeaderNoSearch.propTypes = {
  children: PropTypes.element,
  icon: PropTypes.element.isRequired,
  title: PropTypes.string.isRequired,
}

export default HeaderNoSearch;