import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { InputIcon, Search, SeriesIcon } from 'basic-components';
import { CommonService } from 'dashboard-services';

import { creatorSeriesActions, settingsSeriesActions } from 'actions/series';
import { routing } from 'routing';
import { GAUtils } from 'utils';

import HeaderNoSearch from 'primary/HeaderNoSearch';
import Basket from './Basket';
import FiltersSetUp from './FiltersSetUp';
import Results from './Results';
import StartingResults from './StartingResults';
import { UserDefinedGroupFilter, UserDefinedStandardFilter } from './UserDefinedFilter';

import { Else, If, Then, When } from 'react-if';

import classNames from 'classnames';
import './Series.scss';

const Series = () => {
  const dispatch = useDispatch(),
        { query, filters, userDefinedFilters } = useSelector(state => state.seriesState.creatorState),
        [isFiltersSetUpOpen, setIsFiltersSetUpOpen] = useState(false),
        [areFiltersChanged, setAreFiltersChanged] = useState(),
        [filtersHeight, setFiltersHeight] = useState(),
        setQuery = useCallback(query => dispatch(creatorSeriesActions.onChange(query, "query")), [dispatch]),
        setFilterValue = useCallback(({ value, name }) => dispatch(creatorSeriesActions.onChangeObject(value, name, "filters")), [dispatch]),
        symbolsFilter = useMemo(() => creatorSeriesActions.getSymbolsFilter({ filters, userDefinedFilters }), [filters, userDefinedFilters]),
        metadatasFilter = useMemo(() => creatorSeriesActions.getMetadatasFilter({ filters, userDefinedFilters }), [filters, userDefinedFilters]),
        groupFilter = useMemo(() => creatorSeriesActions.getGroupFilter({ filters, userDefinedFilters }), [filters, userDefinedFilters]),
        openFiltersSetUp = useCallback(() => setIsFiltersSetUpOpen(true), []),
        closeFiltersSetUp = useCallback(() => setIsFiltersSetUpOpen(false), []),
        getResultsStyle = useCallback((additionalHeight = 0) => {
          let defaultHeight = `calc(100% - 32px - 12px - 8px - 40px - 8px - 1px - 44px - ${additionalHeight}px`
          defaultHeight += ` - ${filtersHeight || (32 + 40 * (Number(CommonService.fixedNumber(userDefinedFilters.length % 3, 0))))}px`
          defaultHeight += ")"
          return {
            height: defaultHeight 
          }
        }, [filtersHeight, userDefinedFilters?.length]),
        observer = useRef(),
        timeoutRef = useRef(),
        filtersRef = useRef()

  useEffect(() => {
    if(query) {
      GAUtils.sendEvent({
        category: "search",
        action: "search_ts",
        label: "search"
      })
    }
  }, [query])

  useEffect(() => {
    GAUtils.sendEvent({
      category: "open",
      action: "open_ts",
      label: "open"
    })
    GAUtils.sendPageView(routing.TS.name)
  }, [])

  useEffect(() => {
    setAreFiltersChanged(creatorSeriesActions.areFiltersChanged())
  }, [userDefinedFilters])

  useEffect(() => {
    const initObserver = () => {
      setFiltersHeight(filtersRef.current?.clientHeight)
      observer.current = new ResizeObserver(entries => {
        entries.forEach(() => {
          setFiltersHeight(filtersRef.current?.clientHeight)
        });
      });
      observer.current.observe(filtersRef.current, { attributes: true })
    }
    if(filtersRef.current) {
      initObserver()
    } else {
      window.clearTimeout(timeoutRef.current)
      timeoutRef.current = window.setTimeout(() => initObserver(), 5)
    }
    return () => {
      window.clearTimeout(timeoutRef.current)
      observer.current.disconnect()
    }
  }, [])

  useEffect(() => () => {
    dispatch(creatorSeriesActions.clear())
    dispatch(settingsSeriesActions.clear())
  }, [dispatch])

  return (
    <div className="ng-office-app__authed__content ng-office-app__authed__content--series">
      <HeaderNoSearch
          icon={(
            <SeriesIcon
                color="grey-medium"
                height={12}
                width={12}
            />
          )}
          title="SERIES CREATOR"
      />
      <div className="ng-office-app__authed__content__filters">
        <div className="ng-office-app__authed__content__filters__search">
          <Search
              autoFocus={false}
              defaultValue={query}
              onSearch={setQuery}
              placeholder="Search..."
              size="large"
              variant="outline"
          />
          <div 
              className={classNames(
                "ng-office-app__authed__content__filters__search__more",
                { "ng-office-app__authed__content__filters__search__more--is-changed": areFiltersChanged }
              )}
              onClick={openFiltersSetUp}
          >
            <InputIcon
                color="blue-bright"
                height={16}
                width={16}
            />
          </div>
        </div>
        <div 
            className="ng-office-app__authed__content__filters__filters"
            ref={filtersRef}
        >
          {userDefinedFilters.map((filter, filterIndex) => (
            <If 
                condition={filter.type === creatorSeriesActions.FILTER_TYPE.GROUP_NAME}
                key={filterIndex + filter.filterKey + userDefinedFilters?.length}
            >
              <Then>
                {() => (
                  <UserDefinedGroupFilter
                      {...filter}
                      filterKey={filter.filterKey}
                      groupFilter={groupFilter}
                      index={filterIndex}
                      metadatas={metadatasFilter}
                      // query={query}
                      setValue={setFilterValue}
                      symbols={symbolsFilter}
                      value={filters[filter.filterKey]}
                  />
                )}
              </Then>
              <Else>
                {() => (
                  <UserDefinedStandardFilter
                      {...filter}
                      filterKey={filter.filterKey}
                      groupFilter={groupFilter}
                      index={filterIndex}
                      metadatas={metadatasFilter}
                      setValue={setFilterValue}
                      symbols={symbolsFilter}
                      value={filters[filter.filterKey]}
                  />
                )}
              </Else>
            </If>
          ))}
        </div>
      </div>
      <If condition={!(query || Object.keys(metadatasFilter || {}).length > 0 || Object.keys(symbolsFilter || {}).length > 0 || groupFilter)}>
        <Then>
          {() => (
            <StartingResults getResultsStyle={getResultsStyle}/>
          )}
        </Then>
        <Else>
          {() => (
            <Results
                getResultsStyle={getResultsStyle}
                groupFilter={groupFilter}
                metadatasFilter={metadatasFilter}
                symbolsFilter={symbolsFilter}
            />
          )}
        </Else>
      </If>
      <Basket/>
      <When condition={isFiltersSetUpOpen}>
        {() => (
          <FiltersSetUp onClose={closeFiltersSetUp}/>
        )}
      </When>
    </div>
  )
}

export default Series;