import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import { LoadMoreDropdown } from 'basic-components';

import { creatorSeriesActions } from 'actions/series';
import getApiConfig from 'api/ApiConfig';

import DropdownIndicator from './DropdownIndicator';

import './UserDefinedFilter.scss';

const dropdownComponents = {
  DropdownIndicator
}

const useSearchSymbolGroups = (apiConfig, { query, symbolsFilter, metadatasFilter }) => {
  const [hasMoreSymbolGroups, setHasMoreSymbolGroups] = useState(false),
        searchSymbolGroupsRef = useRef()
        const searchSymbolGroups = useCallback((input, size) => {
          searchSymbolGroupsRef.current?.cancel?.()
          searchSymbolGroupsRef.current = creatorSeriesActions.searchGroupsBySymbols(apiConfig, { query, groupName: input, symbolsFilter, metadatasFilter, from: 0, size: size === 0 ? 20 : size })

          return searchSymbolGroupsRef.current.promise.then((response = {}) => {
            setHasMoreSymbolGroups(response.totalSize > size)
            return (response.items || []).map(item => ({ value: item.groupName, label: item.groupName }))
          })
        }, [apiConfig, query, symbolsFilter, metadatasFilter])

  useEffect(() => () => {
    searchSymbolGroupsRef.current?.cancel()
  }, [])

  const toReturn = useMemo(() => ({ searchSymbolGroups, hasMoreSymbolGroups }), [searchSymbolGroups, hasMoreSymbolGroups]);
  return toReturn;
}

const UserDefinedGroupFilter = ({ filterKey, label, value, setValue, symbols, metadatas, groupFilter, index }) => {
  const dispatch = useDispatch(),
        { hasMoreSymbolGroups, searchSymbolGroups } = useSearchSymbolGroups(dispatch(getApiConfig()), { 
          query: undefined,  // other dropdowns does not support it
          symbolsFilter: symbols, 
          metadatasFilter: metadatas
        }),
        onChange = useCallback(value => setValue({ value, name: filterKey }), [filterKey, setValue]),
        dropdownRef = useRef()

  useEffect(() => {
    dropdownRef.current?.refreshOptions?.()
  }, [groupFilter, metadatas, symbols])

  return (
    <div className="user-defined-filter">
      <LoadMoreDropdown
          className="user-defined-filter__dropdown"
          components={dropdownComponents}
          creatable
          defaultValue={value}
          fixed
          hasMore={hasMoreSymbolGroups}
          key={index + filterKey}
          loadOptions={searchSymbolGroups}
          menuPlacement="bottom"
          menuPortalTarget={document.querySelector('.ng-office-app__authed__content--series')}
          name={filterKey}
          noError
          onChange={onChange}
          placeholder={label}
          ref={dropdownRef}
          variant="border"
      />
    </div>
  )    
}

UserDefinedGroupFilter.defaultProps = {
  groupFilter: undefined,
  metadatas: undefined,
  symbols: undefined,
  value: undefined
}

UserDefinedGroupFilter.propTypes = {
  filterKey: PropTypes.string.isRequired,
  groupFilter: PropTypes.string,
    index: PropTypes.number.isRequired,
  label: PropTypes.string.isRequired,
  metadatas: PropTypes.object,
  setValue: PropTypes.func.isRequired,
  symbols: PropTypes.object,
  value: PropTypes.string
}

export default UserDefinedGroupFilter;