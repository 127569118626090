import { Fragment, memo, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';

import { Info, Radio, RadioGroup, WithTooltip } from 'basic-components';
import { tsDataRangesObject, tsOrder } from 'dashboard-services';

const DateOrder = ({ order, range, onChange }) => {
  const onChangeLocal = useCallback(value => onChange(value, { name: 'order' }), [onChange])
  useEffect(() => {
    if(range === tsDataRangesObject.last || range === tsDataRangesObject.next) {
      onChangeLocal(tsOrder.DESC)
    }
  }, [onChange, onChangeLocal, range])

  if(range === tsDataRangesObject.last || range === tsDataRangesObject.next) {

    return (
      <Fragment/>
    )
  }
  return (
    <Fragment>
      <div 
          className="ng-office-app__authed__content__body__item__expanded-settings__bottom__label ng-office-app__authed__content__body__item__expanded-settings__bottom__label--with-info"
          style={{ marginTop: 12 }}
      >
        Date sort order
        <WithTooltip
            data-place="top"
            style="max-width: 200px"
            text={(
              <p>For Date range 'Last'<br/>the only available data<br/>sorting is descending.</p>
            )}
        >
          <div className="ng-office-app__authed__content__body__item__expanded-settings__bottom__label__info">
            <Info
                color="blue-bright"
                height={16}
                width={16}
            />
          </div>
        </WithTooltip>
      </div>
      <div className="ng-office-app__authed__content__body__item__expanded-settings__bottom__value ng-office-app__authed__content__body__item__expanded-settings__bottom__value--radio">
        <RadioGroup
            name="sort-order"
            onChange={onChangeLocal}
            selectedValue={order}
        >
          <Radio name={tsOrder.ASC}>
            Ascending
          </Radio>
          <Radio name={tsOrder.DESC}>
            Descending
          </Radio>
        </RadioGroup>
      </div>
    </Fragment>
  )
}

DateOrder.defaultProps = {
  order: undefined,
  range: undefined
}

DateOrder.propTypes = {
  onChange: PropTypes.func.isRequired,
  order: PropTypes.string,
  range: PropTypes.string,
}

export default memo(DateOrder)