import { ReduxService } from 'dashboard-services';

import { listTimeseriesActions } from 'actions/timeseries';
import { LocalStorageUtils } from 'utils';

// add non settigns one to CLEAR_SETTINGS reducer
// default state in actions cause of clear actions state
export const getListInitialState = () => ReduxService.updateObject(listTimeseriesActions.defaultState, LocalStorageUtils.getTsDefaultSettings())

export default (state = getListInitialState(), action) => {
  switch (action.type) {
    case listTimeseriesActions.ON_CHANGE:
      return ReduxService.updateObject(state, { [action.name]: action.value })
    case listTimeseriesActions.CLEAR: 
      return getListInitialState();
    case listTimeseriesActions.CLEAR_SETTINGS: {
      const keys = state.keys
      return ReduxService.updateObject(state, ReduxService.updateObject(listTimeseriesActions.defaultState, { keys }));
    }
    case listTimeseriesActions.ADD_KEY:
      return ReduxService.updateObject(state, { keys: state.keys.add(action.key) })
    case listTimeseriesActions.REMOVE_KEY:
      return ReduxService.updateObject(state, { keys: state.keys.remove(action.index) })
    case listTimeseriesActions.DUPLICATE_KEY:
      return ReduxService.updateObject(state, { keys: state.keys.add(ReduxService.updateObject(state.keys[action.index]), action.index) })
    case listTimeseriesActions.REPLACE_KEY:
      return ReduxService.updateObject(state, { keys: state.keys.replace(action.key, action.index) })
    case listTimeseriesActions.ON_REORDER_KEYS:
      return ReduxService.updateObject(state, { keys: state.keys.moveItem(action.from, action.to) })
    default:
      return state;
  }
}