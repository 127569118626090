import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ActiveCallsIcon, CurvesIcon, FilesIcon, NoResults, Search, SeriesIcon } from 'basic-components';
import { CommonService } from 'dashboard-services';
import { globalActions } from 'primary-components';

import { routing } from 'routing';
import { AuthUtils, CurvesUtils, ExcelUtils, FileUtils, GAUtils, TimeSeriesUtils } from 'utils';

import EmptyList from 'primary/EmptyList';
import HeaderNoSearch from 'primary/HeaderNoSearch';
import CallSection from './CallSection';
import CurveRow from './CurveRow';
import FileRow from './FileRow';
import SerieRow from './SerieRow';

import { When } from 'react-if';

import './Active.scss';

const validateTable = async ({ item = [], cellValue, cell, formula }) => {
  let localItem = await TimeSeriesUtils.validateTable({ item, cellValue, cell, formula })
  localItem = await FileUtils.validateTable({ item: localItem, cellValue, cell, formula })
  localItem = await CurvesUtils.validateTable({ item: localItem, cellValue, cell, formula })
  return localItem;
}
const REFRESH_PREFIX = "ActiveCalls_Refresh_"
const Active = () => {
  const [query, setQuery] = useState(),
        [activeCalls, setActiveCalls] = useState([]),
        dispatch = useDispatch(),
        isFetching = useSelector(state => state.globalState.fetchingRequests.filter(f => f.id?.startsWith(REFRESH_PREFIX)).length > 0),
        isLoadingRef = useRef(false),
        loadActiveCalls = useCallback(async () => {
          if(isLoadingRef.current) {
            return Promise.resolve()
          }
          isLoadingRef.current = true;
          const requestId = REFRESH_PREFIX + CommonService.generateUID()
          dispatch(globalActions.handleRequest({ id: requestId }))
          return ExcelUtils.getTablesInSpreadSheets({ validateTable })
            .then(items => items.filter(item => item !== undefined))
            .then(items => setActiveCalls(items))
            .catch(err => {
              dispatch(globalActions.getMessageStore()).error(String(err))
              console.error(String(err))
              dispatch(AuthUtils.sendError({ error: "(ActiveCalls)" + err.stack }))
            })
            .finally(() => {
              isLoadingRef.current = false;
              dispatch(globalActions.handleRequest({ id: requestId }))
            })
        }, [dispatch]),
        isMatchingQuery = useCallback((value = "") => {
          const localQuery = String(query || "").trim().toUpperCase()
          if(!localQuery || localQuery === "") {
            return true;
          }
          return value.toUpperCase().match(localQuery)
        }, [query]),
        serieItems = useMemo(() => activeCalls.filter(a => a.prefix === TimeSeriesUtils.TS_PARAMS_PREFIX).filter(a => {
          const groupedParams = a.parsedParameters.getGroupedParams()
          return groupedParams.some(gp => {
            if(isMatchingQuery(gp.groupName)) {
              return true;
            }
            return Object.values(gp.symbols || {}).some(v => isMatchingQuery(v)) || Object.values(gp.metadatas || {}).some(v => isMatchingQuery(v))
          })
        }), [activeCalls, isMatchingQuery]),
        curveItems = useMemo(() => activeCalls.filter(a => a.prefix === CurvesUtils.FC_PARAMS_PREFIX).filter(a => isMatchingQuery(a.product) || isMatchingQuery(a.root) || isMatchingQuery(a.groupName)), [activeCalls, isMatchingQuery]),
        fileItems = useMemo(() => activeCalls.filter(a => a.prefix === FileUtils.FILE_PREFIX).filter(a => isMatchingQuery(a.fileName) || isMatchingQuery(a.groupName)), [activeCalls, isMatchingQuery]),
        refreshCall = useCallback(({ formula, address }) => {
          GAUtils.sendEvent({
            category: "action",
            action: "refresh_call",
            label: "refresh"
          })
          return Excel.run(context => 
            ExcelUtils
              .refreshFormulaWithAdress({ address, formula, context })
              .catch(err => {
                dispatch(AuthUtils.sendError({ error: "(RefreshCall)" + err.stack }))
                dispatch(globalActions.getMessageStore()).error(String(err))
                console.error(String(err))
                return Promise.resolve()
              })
              .finally(() => loadActiveCalls())
          )
        }, [dispatch, loadActiveCalls]),
        clearTable = useCallback(async address => {
          await ExcelUtils.clearTableWithFormulaAddress(address)
          loadActiveCalls()
        }, [loadActiveCalls])

  useEffect(() => {
    if(query) {
      GAUtils.sendEvent({
        category: "search",
        action: "search_active",
        label: "search"
      })
    }
  }, [query])

  useEffect(() => {
    GAUtils.sendEvent({
      category: "open",
      action: "open_active",
      label: "open"
    })
    GAUtils.sendPageView(routing.ACTIVE.name)
  }, [])

  useEffect(() => {
    loadActiveCalls()
  }, [loadActiveCalls])

  return (
    <div className="ng-office-app__authed__content ng-office-app__authed__content--active">
      <HeaderNoSearch
          icon={(
            <ActiveCallsIcon
                color="grey-medium"
                height={12}
                width={12}
            />
          )}
          title="ACTIVE CALLS"
      >
        <div className="ng-office-app__authed__content__search">
          <Search
              defaultValue={query}
              onSearch={setQuery}
              placeholder="Search in active calls"
              size="large"
              variant="outline"
          />
        </div>
      </HeaderNoSearch>
      <div className="ng-office-app__authed__content__active-calls">
        <CallSection
            icon={<SeriesIcon color="grey-medium"/>}
            itemsCount={serieItems.length}
            title="SERIES"
        >
          {serieItems.map((item, index) => (
            <SerieRow
                {...item}
                clearTable={clearTable}
                key={index}
                refreshCall={refreshCall}
            />
          ))}
        </CallSection>
        <CallSection
            icon={<CurvesIcon color="grey-medium"/>}
            itemsCount={curveItems.length}
            title="CURVES"
        >
          {curveItems.map((item, index) => (
            <CurveRow
                {...item}
                clearTable={clearTable}
                key={index}
                refreshCall={refreshCall}
            />
          ))}
        </CallSection>
        <CallSection
            icon={<FilesIcon color="grey-medium"/>}
            itemsCount={fileItems.length}
            title="FILES"
        >
          {fileItems.map((item, index) => (
            <FileRow
                {...item}
                clearTable={clearTable}
                key={index}
                refreshCall={refreshCall}
            />
          ))}
        </CallSection>
        <When condition={activeCalls.length === 0 && !isFetching}>
          {() => (
            <EmptyList
                subtitle="No active calls."
                title="Create data calls in Series, Curve or Files."
            />
          )}
        </When>
        <When condition={serieItems.length === 0 && curveItems.length === 0 && fileItems.length === 0 && activeCalls.length > 0}>
          {() => (
            <NoResults/>
          )}
        </When>
      </div>
    </div>
  )
}

export default Active;