import { Fragment, useMemo } from 'react';
import PropTypes from 'prop-types';

import { Dropdown } from 'basic-components';
import { PERIOD_TYPE_ARRAY } from 'dashboard-services';

const PeriodTypeLocal = ({ onChange, value }) => {
  const defaultValue = useMemo(() => PERIOD_TYPE_ARRAY.find(p => p.value === value), [value])
  return (
    <Fragment>
      <div className="ng-office-app__authed__content__body__item__expanded-settings__bottom__label">
        Period Type
      </div>
      <div className="ng-office-app__authed__content__body__item__expanded-settings__bottom__value">
        <Dropdown
            defaultValue={defaultValue}
            fixed
            menuPortalTarget={document.querySelector('.ng-office-app')}
            name="periodType"
            noError
            onChange={onChange}
            options={PERIOD_TYPE_ARRAY}
            variant="border"
        />
      </div>
    </Fragment>
  )
}

PeriodTypeLocal.defaultProps = {
  value: undefined,
}

PeriodTypeLocal.propTypes = {
  onChange: PropTypes.func.isRequired, 
  value: PropTypes.string,
}

export default PeriodTypeLocal;