import { ActionService, DataLakeApi, DataStorageApi, dateBetweenTypes, ReduxService, SymbolSearchQuery, SymbolSearchSortBy, tsKeysTypes } from 'dashboard-services';
import { globalActions } from 'primary-components';

import getApiConfig from 'api/ApiConfig';
import properties from 'resources/constants/properties.json';
import { LocalStorageUtils, TimeSeriesUtils } from 'utils';

import { settingsSeriesActions } from './';
import suggestedSymbols from './SuggestedSymbols.json';

export default (() => {
  const FILTER_TYPE = {
    GROUP_NAME: "groupName",
    METADATA: tsKeysTypes.METADATA,
    SYMBOL: tsKeysTypes.SYMBOL
  }
  
  const ON_CHANGE = 'ON_CHANGE_CREATOR_SERIES'
  const onChange = ActionService.makeActionCreator(ON_CHANGE, 'value', 'name')
  
  const ON_CHANGE_OBJECT = 'ON_CHANGE_OBJECT_CREATOR_SERIES'
  const onChangeObject = ActionService.makeActionCreator(ON_CHANGE_OBJECT, 'value', 'fieldName', 'name')

  const getIndexOfOneSymbolInBasket = (symbol, selectedSymbols) => selectedSymbols.findIndex(sel => JSON.stringify(sel.symbols) === JSON.stringify(symbol.symbols) && sel.groupName === symbol.groupName && sel.pattern === "false")
  const getIndexOfPatternSymbolInBasket = (symbol, selectedSymbols) => selectedSymbols.findIndex(sel => JSON.stringify(sel.symbols) === JSON.stringify(symbol.symbols) && sel.groupName === symbol.groupName && sel.query === symbol.query && JSON.stringify(sel.metadata) === JSON.stringify(symbol.metadata))

  const isMatchingQuery = ({ query, value = "" }) => value.match(query)
  const isMatchingQueryObj = ({ query, value = {} }) => Object.values(value).some(v => isMatchingQuery({ query, value: v }))

  const isInBasket = (symbol, selectedSymbols = []) => {
    const isDirectlySelected = selectedSymbols.findIndex(sel => {
      if(sel.pattern === "false") {
        return JSON.stringify(sel.symbols) === JSON.stringify(symbol.symbols) && sel.groupName === symbol.groupName 
      }
      return false;
    }) > -1
    const isPatternSelected = selectedSymbols.findIndex(sel => {
      if(sel.pattern !== "false") {
        if(sel.groupName !== symbol.groupName && sel.groupName) {
          return false;
        }
        const symbolMatch = !Object.entries(sel?.symbols || {}).some(([key, value]) => symbol.symbols[key] !== value && value !== "*");
        if(symbolMatch) {
          const metadataMatch = !Object.entries(sel?.metadata || {}).some(([key, value]) => symbol.metadata[key] !== value && value !== "*");
          if(metadataMatch) {
            if(sel.query) {
              const matchQuery = (isMatchingQuery({ query: sel.query, value: sel.groupName }) || isMatchingQueryObj({ query: sel.query, val: sel.symbols }) || isMatchingQueryObj({ query: sel.query, value: sel.metadata }))
              return matchQuery;
            }
            return true;
          }
        }
        return false;
      }
      return false;
    }) > -1

    return {
      isDirectlySelected,
      isPatternSelected
    }
  }

  const SWAP_SELECTED_SYMBOL = 'SWAP_SELECTED_SYMBOL_CREATOR_SERIEES'
  const swapSelectedSymbol = symbol => (dispatch, getState) => {
    if(symbol.pattern === "false") {
      const index = getIndexOfOneSymbolInBasket(symbol, getState().seriesState.creatorState.selectedSymbols)
      const localSymbol = ReduxService.updateObject(symbol, { pattern: "false" })
      if(index === -1) {
        LocalStorageUtils.addSymbolToRecents(localSymbol)
      }
      dispatch(swapSelectedSymbolReducer(localSymbol, index))
    } else {
      // only for removing
      const index = getIndexOfPatternSymbolInBasket(symbol, getState().seriesState.creatorState.selectedSymbols)
      if(index === -1) {
        return;
      }
      dispatch(swapSelectedSymbolReducer(symbol, index))
    }
  }
  const swapSelectedSymbolReducer = ActionService.makeActionCreator(SWAP_SELECTED_SYMBOL, 'symbol', 'index')

  const CLEAR = 'CLEAR_CREATOR_SERIES'
  const clear = ActionService.makeActionCreator(CLEAR)

  const getSortString = userDefinedFilters => {
    const builder = userDefinedFilters.reduce((prev, curr) => {
      if(curr) {
        if(curr.type === FILTER_TYPE.GROUP_NAME) {
          return prev
        }
        return curr.type === FILTER_TYPE.METADATA ? prev.withMetadataSort(curr.filterKey) : prev.withSort(curr.filterKey)
      }
      return prev;
    }, new SymbolSearchSortBy.Builder())
    return builder.withSort("Symbol").build().getSortBy()
  }

  const getSearchQuery = ({ query, groupName, symbolsFilter = {}, metadatasFilter = {} }) => {
    let builder = new SymbolSearchQuery.Builder()
      .withSymbols(Object.entries(symbolsFilter).map(([key, value]) => ({ key, value })))
      .withMetadatas(Object.entries(metadatasFilter).map(([key, value]) => ({ key, value })))
      .withQueryString(query ? `${query}*` : "")
    if(groupName) {
      builder = builder.withGroupName(`*${String(groupName).toLowerCase()}*`, false)
    }
    return builder
      .build()
      .getQuery()
  }

  const SEARCH_ID = "SEARCH_SYMBOLS_CREATOR_SERIES",
        SCROLL_ID = "SCROLL_SYMBOLS_CREATOR_SERIES"

  const terminateSearchSymbolsRequests = () => (dispatch, getState) => {
    const fetchingRequests = getState().globalState.fetchingRequests,
          searchSymbolsRequests = fetchingRequests.filter(f => f.id?.startsWith(SEARCH_ID) || f.id?.startsWith(SCROLL_ID))
    searchSymbolsRequests.forEach(f => {
      f.abortController?.abort()
      dispatch(globalActions.handleRequest({ id: f.id, isResponse: true }));
    })
  }

  const searchSymbols = ({ query, symbolsFilter, metadatasFilter, groupFilter, userDefinedFilters, from = 0, requestId = SEARCH_ID, withTerminate = true }) => dispatch => {
    if(!(query || Object.keys(metadatasFilter || {}).length > 0 || Object.keys(symbolsFilter).length > 0 || groupFilter)) {
      return Promise.resolve().then(() => ({ items: [], from: 0, totalSize: 0 }));
    }
    if(withTerminate) {
      dispatch(terminateSearchSymbolsRequests())
    }
    const buildedQuery = getSearchQuery({ query, symbolsFilter, metadatasFilter, groupName: groupFilter }),
          sortBy = getSortString(userDefinedFilters)

    return new DataStorageApi(dispatch(getApiConfig()))
        .searchSymbols(
          buildedQuery,
          from, 
          100, 
          { 
            sortBy, 
            withValuesOnly: true
          }
        )
        .withRequestIdPrefix(requestId)
        .build()
        .call()
  }

  const searchGroupsBySymbols = (apiConfig, { groupName, query, symbolsFilter, metadatasFilter, from = 0, size }) => {
    const buildedQuery = getSearchQuery({ query, symbolsFilter, metadatasFilter, groupName })

    return new DataStorageApi(apiConfig)
      .searchSymbols(
        buildedQuery,
        from, 
        size, 
        { 
          collapse: "groupName", 
          sortBy: "groupName.keyword=asc",
          withValuesOnly: true
        }
      )
      .noFetching(true)
      .cancelable(true)
      .build()
      .call()
  }


  const scrollSymbols = ({ query, symbolsFilter, metadatasFilter, groupFilter, userDefinedFilters, from }) => dispatch => dispatch(searchSymbols({ query, symbolsFilter, metadatasFilter, groupFilter, userDefinedFilters, from, requestId: SCROLL_ID }))

  const getConfigFile = ({ fileName, getDefaultFile, searchRef, getRef }) => dispatch => {
    searchRef.current = 
      new DataLakeApi(dispatch(getApiConfig()))
        .searchFiles({ query: `groupName=${properties.baseGroup}&name.keyword=${fileName}&latest=true`, from: 0, size: 1 })
        .noErrorMessage(true)
        .cancelable(true)
        .build()
        .call()
    return searchRef.current.promise
        .then(({ items = [] } = {}) => {
          if(items[0]) {
            getRef.current =
               new DataLakeApi(dispatch(getApiConfig()))
                .getFile(items[0].fid)
                .noErrorMessage(true)
                .cancelable(true)
                .build()
                .call()

            return getRef.current.promise.then(response => response.json())
          }
          console.info("No config file found. Using default one.")
          return getDefaultFile();
        })
        .catch(e => {
          console.error(e)
          return getDefaultFile()
        })
  }

  const getDefaultFilters = () => Promise.resolve().then(() => 
    [
      {
          label: "Product",
          filterKey: "Product",
          type: FILTER_TYPE.METADATA
      },
      {
          label: "Source",
          filterKey: "Source",
          type: FILTER_TYPE.METADATA
      },
      {
          "label": "Region",
          "filterKey": "SourceRegion",
          "type": "METADATA"
      }
  ])

  const fetchEnvFilters = ({ searchRef, getRef }) => dispatch => dispatch(getConfigFile({ fileName: "defaultFilters.json", getDefaultFile: getDefaultFilters, searchRef, getRef }))

  const getDefaultSuggested = () => Promise.resolve().then(() => suggestedSymbols.sort((a, b) => a.symbols.Symbol.localeCompare(b.symbols.Symbol)))
  const fetchEnvSuggested = ({ searchRef, getRef }) => dispatch => dispatch(getConfigFile({ fileName: "suggestedSymbols.json", getDefaultFile: getDefaultSuggested, searchRef, getRef }))

  const readUserFilters = ({ searchRef, getRef }) => dispatch => {
    const localStorageUserFilters = LocalStorageUtils.getSavedSeriesFilters() || []
    if(localStorageUserFilters.length > 0) {
      return Promise.resolve().then(() => localStorageUserFilters);
    } else {
      return dispatch(fetchEnvFilters({ searchRef, getRef }));
    }
  }

  const areFiltersChanged = () => !!LocalStorageUtils.getSavedSeriesFilters()

  const getSymbolsFilter = ({ filters, userDefinedFilters }) => {
    const toReturn = {}
    const notMd = userDefinedFilters.filter(udf => udf.type === FILTER_TYPE.SYMBOL).filter(udf => filters[udf.filterKey] !== undefined)
    if(notMd.length === 0) {
      return undefined;
    }
    notMd.forEach(udf => toReturn[udf.filterKey] = filters[udf.filterKey])
    return toReturn
  }

  const getMetadatasFilter = ({ filters, userDefinedFilters }) => {
    const toReturn = {}
    const mds = userDefinedFilters.filter(udf => udf.type === FILTER_TYPE.METADATA).filter(udf => filters[udf.filterKey] !== undefined)
    if(mds.length === 0) {
      return undefined;
    }
    mds.forEach(udf => toReturn[udf.filterKey] = filters[udf.filterKey])
    return toReturn
  }

  const getGroupFilter = ({ filters, userDefinedFilters }) => {
    const groupName = userDefinedFilters.find(udf => udf.type === FILTER_TYPE.GROUP_NAME)
    return groupName ? filters[groupName.filterKey] : undefined
  }

  const createPattern = () => (dispatch, getState) => {
    const { query, filters = {}, userDefinedFilters = [] } = getState().seriesState.creatorState
    const key = {
      query,
      symbols: getSymbolsFilter({ filters, userDefinedFilters }) || {},
      metadata: getMetadatasFilter({ filters, userDefinedFilters }) || {},
      groupName: getGroupFilter({ filters, userDefinedFilters }),
      pattern: "true"
    }
    dispatch(swapSelectedSymbolReducer(key, -1))
  }

  const ON_REORDER_ITEM = 'ON_REORDER_ITEM_CREATOR_SERIES'
  const onReorderItem = ActionService.makeActionCreator(ON_REORDER_ITEM, 'from', 'to', 'pattern')

  const getParams = () => (_, getState) => getState().seriesState.settingsState

  const editFormula = ({ params, keys, address }) => (dispatch, getState) => {
    const userDefinedFilters = getState().seriesState.creatorState.userDefinedFilters
    dispatch(clear())
    dispatch(settingsSeriesActions.clear())
    TimeSeriesUtils.PLAIN_PARSED_TS_PARAMS.filter(param => params[param] && params[param] !== "undefined").forEach(param => dispatch(settingsSeriesActions.onChange(params[param], param)))
    if(params.startDate === dateBetweenTypes.today) {
      dispatch(settingsSeriesActions.onChange(dateBetweenTypes.today, "fromSelected"))
      dispatch(settingsSeriesActions.onChange(undefined, "from"))
    } else if(params.startDate) {
      dispatch(settingsSeriesActions.onChange(dateBetweenTypes.date, "fromSelected"))
      dispatch(settingsSeriesActions.onChange(String(params.startDate).split("T")[0], "from"))
    }
    if(params.endDate === dateBetweenTypes.today) {
      dispatch(settingsSeriesActions.onChange(dateBetweenTypes.today, "toSelected"))
      dispatch(settingsSeriesActions.onChange(undefined, "to"))
    } else if(params.endDate) {
      dispatch(settingsSeriesActions.onChange(dateBetweenTypes.date, "toSelected"))
      dispatch(settingsSeriesActions.onChange(String(params.endDate).split("T")[0], "to"))
    }
    const selectedSymbolsPromises = [],
          selectedColumns = new Set()
    keys.forEach(key => {
      key.columns.forEach(c => selectedColumns.add(c))
      if(key.pattern === "true" || key.pattern === undefined) {
        key.metadata = key.metadatas
        delete key.metadatas
        selectedSymbolsPromises.push(Promise.resolve().then(() => key))
      } else {
        selectedSymbolsPromises.push(
          dispatch(searchSymbols({ symbolsFilter: key.symbols, userDefinedFilters, withTerminate: false }))
            .then(({ items = [] } = {}) => items?.[0] ? ReduxService.updateObject(items[0], { pattern: "false" }) : undefined)
        )
      }
    })
    Promise.all(selectedSymbolsPromises).then(items => {
      dispatch(onChange(items.filter(i => i !== undefined), "selectedSymbols"))
    }).catch(e => console.error(e))
    dispatch(onChange(Array.from(selectedColumns), "selectedColumns"))
    dispatch(onChange(address, "address"))
  }

  return {
    onChange,
    ON_CHANGE,
    onChangeObject,
    ON_CHANGE_OBJECT,
    CLEAR,
    clear,
    searchSymbols,
    scrollSymbols,
    terminateSearchSymbolsRequests,
    FILTER_TYPE,
    SEARCH_ID,
    readUserFilters,
    fetchEnvFilters,
    searchGroupsBySymbols,
    areFiltersChanged,
    SWAP_SELECTED_SYMBOL,
    swapSelectedSymbol,
    isInBasket,
    createPattern,
    getSymbolsFilter,
    getMetadatasFilter,
    getGroupFilter,
    ON_REORDER_ITEM,
    onReorderItem,
    getParams,
    editFormula,
    fetchEnvSuggested
  }
})()