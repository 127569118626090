import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Button } from 'basic-components';
import { formatTypes, tsOrder } from 'dashboard-services';

import { settingsSeriesActions } from 'actions/series';

import { ColumnsOrder, DateFormat, DateOrder, OutputType, SetAsDefaultSettings, TimeAndDate, TimeZone } from 'primary/TsSettings';

import { When } from 'react-if';

import 'primary/TsSettings/ExpandedSettings/Components.scss';
import './Settings.scss';

const Settings = () => {
  const dispatch = useDispatch(),
        { 
          formatType,
          csvHeaders,
          timeZone,
          range,
          dateFormat,
          order = tsOrder.DESC,
          shouldTranspose = "false",
          sortKeys = "false", 
          sortKeysBy,
          sortKeysOrder = tsOrder.ASC
        } = useSelector(state => state.seriesState.settingsState),
        onSaveDefaultSettings = useCallback(() => dispatch(settingsSeriesActions.saveDefaultSettings()), [dispatch]),
        onClearDefaultSettings = useCallback(() => dispatch(settingsSeriesActions.clearDefaultSettings()), [dispatch]),
        onChange = useCallback((val, { name }) => dispatch(settingsSeriesActions.onChange(val, name)), [dispatch]),
        getKeys = useCallback(({ input, callsRefs }) => dispatch(settingsSeriesActions.getKeysForGroups({ input, callsRefs })), [dispatch])
  return (
    <div className="ng-office-app__authed__content__basket__body__settings">
      <SetAsDefaultSettings onSave={onSaveDefaultSettings}/>
      <OutputType
          csvHeaders={csvHeaders}
          formatType={formatType}
          getKeys={getKeys}
          onChange={onChange}
          shouldTranspose={shouldTranspose}
          withOutputType
          withTranspose
      />
      <TimeAndDate>
        <TimeZone
            onChange={onChange}
            timeZone={timeZone}
        />
        <DateFormat
            dateFormat={dateFormat}
            onChange={onChange}
        />
        <DateOrder
            onChange={onChange}
            order={order}
            range={range}
        />
      </TimeAndDate>
      <When condition={formatType === formatTypes.CSV}>
        {() => (
          <ColumnsOrder
              getKeys={getKeys}
              onChange={onChange}
              sortKeys={sortKeys}
              sortKeysBy={sortKeysBy}
              sortKeysOrder={sortKeysOrder}
          />
        )}
      </When>
      <div className="ng-office-app__authed__content__basket__body__settings__reset">
        <Button
            onClick={onClearDefaultSettings}
            size="small"
            variant="text"
        >
          Reset default settings
        </Button>
      </div>
    </div>
  )
}

export default Settings;