import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { FilesIcon, Search } from 'basic-components';

import { basicDatalakeActions } from 'actions/datalake';
import { routing } from 'routing';
import { GAUtils } from 'utils';

import HeaderNoSearch from 'primary/HeaderNoSearch';
import AddNewFile from './AddNewFile';

const DataLake = () => {
  const dispatch = useDispatch(),
        query = useSelector(state => state.datalakeState.query),
        setQuery = useCallback(query => dispatch(basicDatalakeActions.onChange(query, "query")), [dispatch])

  useEffect(() => {
    if(query) {
      GAUtils.sendEvent({
        category: "search",
        action: "search_file",
        label: "search"
      })
    }
  }, [query])

  useEffect(() => {
    GAUtils.sendEvent({
      category: "open",
      action: "open_dl",
      label: "open"
    })
    GAUtils.sendPageView(routing.LAKE.name)
  }, [])

  return (
    <div className="ng-office-app__authed__content ng-office-app__authed__content--data-lake">
      <HeaderNoSearch
          icon={(
            <FilesIcon
                color="grey-medium"
                height={12}
                width={12}
            />
          )}
          title="FILE BROWSER"
      >
        <div className="ng-office-app__authed__content__search">
          <Search
              autoFocus={false}
              defaultValue={query}
              onSearch={setQuery}
              placeholder="Search by name..."
              size="large"
              variant="outline"
          />
        </div>
      </HeaderNoSearch>
      <div className="ng-office-app__authed__content__body">
        <AddNewFile/>
      </div>
    </div>
  )
}

export default DataLake;