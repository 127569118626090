import { ReduxService, TimeService } from 'dashboard-services';

import { basicSettingsActions } from 'actions/settings';
import { ExcelUtils } from 'utils';

export const settingsInitialState = {
  company: undefined,
  definedTime: TimeService.UTC,
  definedNumberFormat: ExcelUtils.numberFormatTypesObj.GENERAL,
  userName: undefined,

  uiEntitlements: {}
}

export default (state = settingsInitialState, action) => {
  switch (action.type) {
    case basicSettingsActions.ON_CHANGE:
      return ReduxService.updateObject(state, { [action.name]: action.value })
    default:
      return state;
  }
}