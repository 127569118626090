import { useSelector } from 'react-redux';

import EmptyList from 'primary/EmptyList';
import Columns from './Columns';
import Items from './Items';

import { Else, If, Then } from 'react-if';

import './DataTab.scss';

const emptyArray = []
const DataTab = () => {
  const selectedCurves = useSelector(state => state.curvesState.creatorState.selectedCurves) || emptyArray
  return (
    <div className="ng-office-app__authed__content__basket__body__data ng-office-app__authed__content__basket__body__data--curve">
      <Columns/>
      <div className="ng-office-app__authed__content__basket__body__data__basket">
        <If condition={selectedCurves.length === 0}>
          <Then>
            {() => (
              <EmptyList
                  subtitle="Add curves to the basket in the creator."
                  title="Basket is empty."
              />
            )}
          </Then>
          <Else>
            {() => (
              <Items/>
            )}
          </Else>
        </If>
      </div>
    </div>
  )
}

export default DataTab;