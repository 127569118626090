import { useCallback, useEffect, useMemo, useRef } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import { LoadMoreDropdown } from 'basic-components';
import { DropdownService, ReduxService } from 'dashboard-services';

import { creatorSeriesActions } from 'actions/series';
import getApiConfig from 'api/ApiConfig';

import DropdownIndicator from './DropdownIndicator';

import './UserDefinedFilter.scss';

const dropdownComponents = {
  DropdownIndicator
}

const UserDefinedStandardFilter = ({ filterKey, label, value, setValue, symbols, metadatas, type, groupFilter, index }) => {
  const dispatch = useDispatch(),
        metadataFilter = useMemo(() => {
          if(metadatas === undefined) {
            return;
          }
          const mdO = type === creatorSeriesActions.FILTER_TYPE.METADATA ? ReduxService.updateObject(metadatas, { [filterKey]: undefined }) : metadatas
          return Object.fromEntries(Object.entries(mdO).filter(([,v]) => !!v).map(([k, v]) => [k, [v]]))
        }, [filterKey, metadatas, type]),
        symbolFilter = useMemo(() => {
          if(symbols === undefined) {
            return;
          }
          const sO = creatorSeriesActions.FILTER_TYPE.SYMBOL ? ReduxService.updateObject(symbols, { [filterKey]: undefined }) : symbols
          return Object.fromEntries(Object.entries(sO).filter(([,v]) => !!v).map(([k, v]) => [k, [v]]))
        }, [filterKey, symbols]),
        { hasMoreSymbolValues, searchSymbolValues } = DropdownService.useSearchSymbolValues(dispatch(getApiConfig()), { 
          key: filterKey,
          groupName: groupFilter,
          metadata: type === creatorSeriesActions.FILTER_TYPE.METADATA,
          metadatas: metadataFilter, 
          symbols: symbolFilter,
          useEs: true
        }),
        onChange = useCallback(value => setValue({ value, name: filterKey }), [filterKey, setValue]),
        dropdownRef = useRef()

  useEffect(() => {
    dropdownRef.current?.refreshOptions?.()
  }, [groupFilter, metadataFilter, symbolFilter])

  useEffect(() => {
    if(!value) {
      dropdownRef.current.dropdown.current.setValue(value)
    }
  }, [value])

  return (
    <div className="user-defined-filter">
      <LoadMoreDropdown
          className="user-defined-filter__dropdown"
          components={dropdownComponents}
          creatable
          defaultValue={value}
          fixed
          hasMore={hasMoreSymbolValues}
          key={index}
          loadOptions={searchSymbolValues}
          menuPlacement="bottom"
          menuPortalTarget={document.querySelector('.ng-office-app__authed__content--series')}
          name={filterKey}
          noError
          onChange={onChange}
          placeholder={label}
          ref={dropdownRef}
          variant="border"
      />
    </div>
  )    
}

UserDefinedStandardFilter.defaultProps = {
  groupFilter: "", // BE requirement
  metadatas: undefined,
  symbols: undefined,
  type: undefined,
  value: undefined
}

UserDefinedStandardFilter.propTypes = {
  filterKey: PropTypes.string.isRequired,
  groupFilter: PropTypes.string,
  index: PropTypes.number.isRequired,
  label: PropTypes.string.isRequired,
  metadatas: PropTypes.object,
  setValue: PropTypes.func.isRequired,
  symbols: PropTypes.object,
  type: PropTypes.string,
  value: PropTypes.string
}

export default UserDefinedStandardFilter;