import { getColor } from 'basic-components';
import { ActionService, CSV_HEADERS_TYPES, DataStorageApi, formatTypes, tsDataRangesObject, tsKeysTypes, tsLastTypesObject, tsOrder } from 'dashboard-services';
import { globalActions } from 'primary-components';

import getApiConfig from 'api/ApiConfig';
import { LocalStorageUtils, TimeSeriesUtils } from 'utils';

import { MetadataLabel } from 'primary/TsSettings';

// series actions are not dependent on old TimeSeries actions or components
export default (() => {

  const defaultState = {
    csvHeaders: TimeSeriesUtils.DEFAULT_CSV_HEADERS,
    dateFormat: TimeSeriesUtils.DAILY_DATE_FORMAT,
    formatType: formatTypes.CSV,
    order: tsOrder.DESC,
    shouldTranspose: "false",
    timeZone: undefined,
    
    range: tsDataRangesObject.last,
    lastTypeAmount: "500",
    lastType: tsLastTypesObject.value,
    
    fromSelected: undefined,
    from: undefined,
    
    toSelected: undefined,
    to: undefined,
    
    sortKeys: "false",
    sortKeysBy: undefined,
    sortKeysOrder: tsOrder.ASC,
  }
  
  const ON_CHANGE = 'ON_CHANGE_SETTINGS_SERIES'
  const onChange = ActionService.makeActionCreator(ON_CHANGE, 'value', 'name')

  const CLEAR = 'CLEAR_LIST_SETTINGS_SERIES'
  const clear = ActionService.makeActionCreator(CLEAR)

  const saveDefaultSettings = () => (_, getState) => {
    // eslint-disable-next-line no-unused-vars
    const itemsToSave = getState().seriesState.settingsState
    LocalStorageUtils.setTsDefaultSettings(itemsToSave)
  }

  const clearDefaultSettings = () => dispatch => {
    // eslint-disable-next-line no-unused-vars
    const itemsToSave = defaultState
    LocalStorageUtils.setTsDefaultSettings(itemsToSave)
    dispatch(clear())
    dispatch(globalActions.getMessageStore()).success("Default settings has been restored.")
  }

  const KEYS_TYPE_ORDER = [CSV_HEADERS_TYPES.SYMBOL_KEY, CSV_HEADERS_TYPES.METADATA_KEY];
  const prepareKeysResponse = response => response
          .flatMap(p => p)
          .reduce((acc, current) => {
            if (!acc.some(obj => obj.value === current.value)) {
              acc.push(current);
            }
            return acc;
          }, [])
          .sort((a,b) => {
            const typeA = KEYS_TYPE_ORDER.indexOf(a.type)
            const typeB = KEYS_TYPE_ORDER.indexOf(b.type)
            if (typeA < typeB) return -1
            if (typeA > typeB) return 1
  
            if (a.value < b.value) return -1
            if (a.value > b.value) return 1
  
            return 0
          })

  const getKeysForGroups = ({ input, callsRefs }) => (dispatch, getState) => {
    const selectedSymbols = getState().seriesState.creatorState.selectedSymbols

    const promises = selectedSymbols.map((s, index) => {
      callsRefs.current[index]?.cancel()
      const filterSymbolObj = {}
      Object.entries(s.symbols || {}).forEach(([key, value]) => {
        filterSymbolObj[key] = value === "*" ? [] : (typeof value === "string" ? [value] : value);
      })
      const filterMetadataObj = {}
      Object.entries(s.metadata || {}).forEach(([key, value]) => {
        filterMetadataObj[key] = value === "*" ? [] : (typeof value === "string" ? [value] : value);
      })
      callsRefs.current[index] = new DataStorageApi(dispatch(getApiConfig()))
        .getSymbolKeys(s.groupName || "", input ? `*${input}*` :  "*", { from: 0, size: 100, metadata: true, useEs: true, symbols: filterSymbolObj, metadatas: filterMetadataObj })
        .cancelable(true)
        .noFetching()
        .build()
        .call()

        return callsRefs.current[index].promise.then((response = {}) => 
          (response?.items || []).map(item => ({ 
            value: item.name, 
            label: item.type === tsKeysTypes.METADATA ? (<MetadataLabel name={item.name}/>) : item.name,
            color: item.type === tsKeysTypes.METADATA ? getColor("grey-dark") : undefined,
            type: `${item.type}_KEY`
          }))
        )
    })
    return Promise.all(promises).then(response => prepareKeysResponse(response))
  }


  return {
    onChange,
    ON_CHANGE,
    CLEAR,
    clear,
    saveDefaultSettings,
    clearDefaultSettings,
    defaultState,
    getKeysForGroups
  }
})()