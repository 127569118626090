import { Fragment, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Add as AddIcon, Button } from 'basic-components';
import { dateBetweenTypes, TimeService, tsDataRangesObject, tsOrder } from 'dashboard-services';

import { basicTimeseriesActions, listTimeseriesActions } from 'actions/timeseries';
import FORMULAS from 'resources/constants/Formulas.js';
import { FormulaUtils, GAUtils } from 'utils';

import TooLongWarn from 'primary/TooLongWarn';
import Footer from './Footer';
import KeyRow from './KeyRow';
import Settings from './Settings';

import dayjs from 'dayjs';

import { When } from 'react-if';

import './ListView.scss';

const addAndValidateParam = FormulaUtils.addAndValidateParam
const prepColumnHeaders = FormulaUtils.prepColumnHeaders
const ListView = () => {
  const dispatch = useDispatch(),
        keys = useSelector(state => state.timeseriesState.listState.keys),
        address = useSelector(state => state.timeseriesState.basicState.address),
        openEditView = useCallback(() => dispatch(basicTimeseriesActions.onChange(true, "isEditingKey")), [dispatch]),
        [showTooLongFormula, setShowTooLongFormula] = useState(false),
        [hoveredIndex, setHoveredIndex] = useState(-1),
        [draggingIndex, setDraggingIndex] = useState(-1),
        hideWarns = useCallback(() => setShowTooLongFormula(false), []),
        addToSheet = useCallback(async () => {
          GAUtils.sendEvent({
            category: "action",
            action: "add_ts",
            label: "add"
          })
          const paramsExceedingLength = []
          let formula = `=${FORMULAS.LOAD_TS}(`
          keys.forEach(({ groupName, symbols = {}, columns = [], metadatas = {} }, index) => {
            formula += addAndValidateParam({ paramName: "groupName", paramValue: groupName, paramsExceedingLength, withComma: index > 0 })
            formula += addAndValidateParam({ paramName: "columns", paramValue: columns.join(","), paramsExceedingLength })
            formula += addAndValidateParam({ paramName: "symbols", paramValue: `""{${Object.entries(symbols).map(([key, value]) => `""${key}"":""${value}""`).join(", ")}}""`, paramsExceedingLength })
            formula += addAndValidateParam({ paramName: "metadatas", paramValue: `""{${Object.entries(metadatas).map(([key, value]) => `""${key}"":""${value}""`).join(", ")}}""`, paramsExceedingLength })
          })
          const { timeZone, range, from, to, fromSelected, toSelected, fromTime, toTime, lastType, lastTypeAmount, dateFormat, order, formatType, csvHeaders, shouldTranspose, sortKeys = "false", sortKeysBy, sortKeysOrder = tsOrder.ASC } = dispatch(listTimeseriesActions.getParams())
          if(timeZone) {
            formula += addAndValidateParam({ paramName: "timeZone", paramValue: timeZone, paramsExceedingLength })
          }
          if(dateFormat) {
            formula += addAndValidateParam({ paramName: "dateFormat", paramValue: dateFormat, paramsExceedingLength })
          }
          if(range) {
            formula += addAndValidateParam({ paramName: "range", paramValue: range, paramsExceedingLength })
          }
          if(range === tsDataRangesObject.between) {
            const startDateValue = fromSelected === dateBetweenTypes.date ? dayjs(from + (fromTime ? "T" + fromTime : "T00:00:00.000")).format(TimeService.ISO_DATE_TIME_FORMAT_BACKEND_WITH_SECONDS_STYLE) : fromSelected
            formula += addAndValidateParam({ paramName: "startDate", paramValue: startDateValue, paramsExceedingLength })
            const endDateValue = toSelected === dateBetweenTypes.date ? dayjs(to  + (toTime ? "T" + toTime : "T23:59:59.999")).format(TimeService.ISO_DATE_TIME_FORMAT_BACKEND_WITH_SECONDS_STYLE) : toSelected
            formula += addAndValidateParam({ paramName: "endDate", paramValue: endDateValue, paramsExceedingLength })
          }
          if(range === tsDataRangesObject.last || range === tsDataRangesObject.next) {
            formula += addAndValidateParam({ paramName: "lastType", paramValue: lastType, paramsExceedingLength })
            formula += addAndValidateParam({ paramName: "lastTypeAmount", paramValue: lastTypeAmount, paramsExceedingLength })
          }
          if(order) {
            formula += addAndValidateParam({ paramName: "order", paramValue: order, paramsExceedingLength })
          }
          if(formatType) {
            formula += addAndValidateParam({ paramName: "formatType", paramValue: formatType, paramsExceedingLength })
          }
          if(csvHeaders) {
            const prepColumHeader = prepColumnHeaders({ csvHeaders, formatType })
            formula += addAndValidateParam({ paramName: "csvHeaders", paramValue: `""[${prepColumHeader}]""`, paramsExceedingLength })
          }
          if(sortKeys === "true") {
            formula += addAndValidateParam({ paramName: "sortKeys", paramValue: sortKeys, paramsExceedingLength })
            formula += addAndValidateParam({ paramName: "sortKeysBy", paramValue: `""{${Object.entries(sortKeysBy || {}).map(([key, value]) => `""${key}"":""${value}""`).join(", ")}}""`, paramsExceedingLength })
            formula += addAndValidateParam({ paramName: "sortKeysOrder", paramValue: sortKeysOrder, paramsExceedingLength })
          }
          if(shouldTranspose !== undefined) {
            formula += addAndValidateParam({ paramName: "shouldTranspose", paramValue: shouldTranspose, paramsExceedingLength })
          }
          formula += ")"
          const setAddress = addressLocal => dispatch(basicTimeseriesActions.onChange(addressLocal, "address"))
          FormulaUtils.formulaValidator({ formula, paramsExceedingLength, address, setShowTooLongFormula, setAddress })
        }, [address, dispatch, keys]),
        onDragAndDrop = useCallback(({ from, to }) => dispatch(listTimeseriesActions.onReorderKeys(from, to)), [dispatch])

  useEffect(() => {
    setShowTooLongFormula(false)
  }, [address])

  return (
    <Fragment>
      <Settings/>
      <div className="ng-office-app__authed__content__body__item__creator ng-office-app__authed__content__body__item__creator--with-setting">
        <div className="ng-office-app__authed__content__body__item__creator__title ng-office-app__authed__content__body__item__creator__title--with-border">
          FILTERS
        </div>
        <div className="ng-office-app__authed__content__body__item__creator__row ng-office-app__authed__content__body__item__creator__row--with-rec-button">
          <Button
              onClick={openEditView}
              size="small"
              variant="text"
          >
            <AddIcon
                color="blue-bright"
                height={16}
                width={16}
            />
            Add data
          </Button>
        </div>
        <div className="ng-office-app__authed__content__body__item__creator__list">
          {(keys || []).map((key, index) => (
            <KeyRow
                draggingIndex={draggingIndex}
                groupName={key.groupName}
                hoveredIndex={hoveredIndex}
                index={index}
                key={index}
                metadatas={key.metadatas}
                onDragAndDrop={onDragAndDrop}
                setDraggingIndex={setDraggingIndex}
                setHoveredIndex={setHoveredIndex}
                symbols={key.symbols}
            />
          ))}
        </div>
      </div>
      <When condition={showTooLongFormula}>
        {() => (
          <TooLongWarn onClose={hideWarns}/>
        )}
      </When>
      <Footer addToSheet={addToSheet}/>
    </Fragment>
  )
}

export default ListView;