import { Fragment, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';

import { DatePicker, Dropdown, Switch, TextField } from 'basic-components';
import { CommonService, dateBetweenTypes, tsDataRanges, tsDataRangesObject, tsLastTypes } from 'dashboard-services';

import properties from 'resources/constants/properties.json';

import { When } from 'react-if';

import './DataRange.scss';

const DataRange = ({ onChange, from, fromSelected, to, toSelected, range, lastType, lastTypeAmount, withAdditionalToday }) => {
  const onChangeNumber = useCallback((val, { name }) => {
          if(!CommonService.isNumber(val)) {
            return;
          }
          const valFor = Number(val) < 0 ? Number(val) * -1 : val
          onChange(valFor, { name })
        }, [onChange]),
        onChangeFrom = useCallback(val => {
          const localVal = val === "" ? undefined : val
          onChange(localVal, { name: "from" })
          onChange(dateBetweenTypes.date, { name: "fromSelected" })
          if(toSelected === dateBetweenTypes.date && (!to || to?.length < 10)) {
            onChange(localVal, { name: "to" })
            onChange(dateBetweenTypes.date, { name: "toSelected" })
          }
        }, [onChange, to, toSelected]),
        onChangeTo = useCallback(val => {
          const localVal = val === "" ? undefined : val
          onChange(localVal, { name: "to" })
          onChange(dateBetweenTypes.date, { name: "toSelected" })
          if(fromSelected === dateBetweenTypes.date && (!from || from?.length < 10)) {
            onChange(localVal, { name: "from" })
            onChange(dateBetweenTypes.date, { name: "fromSelected" })
          }
        }, [from, fromSelected, onChange]),
        onChangeToday = useCallback((val, { name }) => onChange(val ? dateBetweenTypes.today : dateBetweenTypes.date, { name }), [onChange]),
        tsDataRangesLocal = useMemo(() => withAdditionalToday ? tsDataRanges.concat([{ label: "Today", value: "TODAY" }]) : tsDataRanges, [withAdditionalToday]),
        defaultRange = useMemo(() => tsDataRangesLocal.find(d => d.value === range), [range, tsDataRangesLocal]),
        tsLastTypesFilterd = useMemo(() => {
          if(properties.warehouse_only !== "true") {
            return tsLastTypes.filter(t => !t.warehouseOnly)
          } else {
            return tsLastTypes
          }
        }, []),
        defaultLastType = useMemo(() => tsLastTypesFilterd.find(t => t.value === lastType), [lastType, tsLastTypesFilterd])

  return (
    <div className="data-range">
      <div className="data-range__row data-range__row--is-full">
        <div className="data-range__row__label">
          Data range type
        </div>
        <div className="data-range__row__value">
          <Dropdown
              defaultValue={defaultRange}
              fixed
              menuPlacement="top"
              menuPortalTarget={document.querySelector('.ng-office-app')}
              name="range"
              noError
              onChange={onChange}
              options={tsDataRangesLocal}
              placeholder="Select data range"
              variant="border"
          />
        </div>
      </div>
      <When condition={range === tsDataRangesObject.last || range === tsDataRangesObject.next}>
        {() => (
          <Fragment>
            <div className="data-range__row">
              <div className="data-range__row__label">
                Type
              </div>
              <div className="data-range__row__value">
                <Dropdown
                    defaultValue={defaultLastType}
                    fixed
                    menuPortalTarget={document.querySelector('.ng-office-app')}
                    name="lastType"
                    noError
                    onChange={onChange}
                    options={tsLastTypesFilterd}
                    variant="border"
                />
              </div>
            </div>
            <div className="data-range__row">
              <div className="data-range__row__label">
                Amount
              </div>
              <div className="data-range__row__value">
                <TextField
                    defaultValue={lastTypeAmount}
                    min={1}
                    name="lastTypeAmount"
                    noError
                    onChange={onChangeNumber}
                    type="number"
                    variant="border"
                />
              </div>
            </div>
          </Fragment>
        )}
      </When>
      <When condition={range === tsDataRangesObject.between}>
        {() => (
          <Fragment>
            <div className="data-range__row">
              <div className="data-range__row__label">
                From
              </div>
              <div className="data-range__row__value">
                <DatePicker
                    defaultValue={dateBetweenTypes.today === fromSelected ? "Today" : from}
                    disabled={dateBetweenTypes.today === fromSelected}
                    isPortal
                    name="from"
                    noError
                    onChange={onChangeFrom}
                    onClickOpen
                    variant="border"
                />
              </div>
              <div className="data-range__row__today">
                <p>Today</p>
                <Switch
                    name="fromSelected"
                    noError
                    on={dateBetweenTypes.today === fromSelected}
                    onChange={onChangeToday}
                />
              </div>
            </div>
            <div className="data-range__row">
              <div className="data-range__row__label">
                To
              </div>
              <div className="data-range__row__value">
                <DatePicker
                    defaultValue={dateBetweenTypes.today === toSelected ? "Today" : to}
                    disabled={dateBetweenTypes.today === toSelected}
                    isPortal
                    name="to"
                    noError
                    onChange={onChangeTo}
                    onClickOpen
                    variant="border"
                />
              </div>
              <div className="data-range__row__today">
                <p>Today</p>
                <Switch
                    name="toSelected"
                    noError
                    on={dateBetweenTypes.today === toSelected}
                    onChange={onChangeToday}
                />
              </div>
            </div>
          </Fragment>
        )}
      </When>
    </div>
  )
}

DataRange.defaultProps = {
  from: undefined, 
  fromSelected: undefined, 
  lastType: undefined, 
  lastTypeAmount: undefined,
  range: undefined,
  to: undefined, 
  toSelected: undefined, 
  withAdditionalToday: false
}

DataRange.propTypes = {
  from: PropTypes.string, 
  fromSelected: PropTypes.string, 
  lastType: PropTypes.string, 
  lastTypeAmount: PropTypes.string,
  onChange: PropTypes.func.isRequired, 
  range: PropTypes.string, 
  to: PropTypes.string, 
  toSelected: PropTypes.string, 
  withAdditionalToday: PropTypes.bool, 
}

export default DataRange;