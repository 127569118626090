import { ActionService } from 'dashboard-services';

export default (() => {
  
  const ON_CHANGE = 'ON_CHANGE_BASIC_DATA_LAKE'
  const onChange = ActionService.makeActionCreator(ON_CHANGE, 'value', 'name')

  const CLEAR = 'CLEAR_BASIC_DATA_LAKE'
  const clear = ActionService.makeActionCreator(CLEAR)

  const editFormula = ({ address }) => dispatch => {
    dispatch(clear())
    dispatch(onChange(address, "address"))
  }

  return {
    onChange,
    ON_CHANGE,
    CLEAR,
    clear,
    editFormula
  }
})()