import { Fragment, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { Add, Button, NoResults } from 'basic-components';
import { CommonService, ReduxService } from 'dashboard-services';
import { globalActions } from 'primary-components';

import { creatorSeriesActions } from 'actions/series';

import SymbolRow from './SymbolRow';

import { When } from 'react-if';
import InfiniteScroll from 'react-infinite-scroller';
import { createSelector } from 'reselect';

import './Results.scss';

const getFetchingRequests = state => state.globalState.fetchingRequests
const isSearchFetchingSelector = createSelector([getFetchingRequests], fetchingRequests => {
  const searchRequests = fetchingRequests.filter(f => f.id?.startsWith(creatorSeriesActions.SEARCH_ID))?.length
  return searchRequests > 0
})

const emptyArray = []
const Results = ({ metadatasFilter, symbolsFilter, groupFilter, getResultsStyle }) => {
  const dispatch = useDispatch(),
        { query, userDefinedFilters, selectedSymbols } = useSelector(state => state.seriesState.creatorState),
        isSearchFetching = useSelector(isSearchFetchingSelector),
        [symbols, setSymbols] = useState(emptyArray),
        [totalSize, setTotalSize] = useState(),
        [hasNextPage, setHasNextPage] = useState(false),
        searchSymbolsCall = useCallback(() => {
          const id = CommonService.generateUID()
          dispatch(globalActions.handleRequest({ id }))
          return dispatch(creatorSeriesActions.searchSymbols({ query, symbolsFilter, metadatasFilter, groupFilter, userDefinedFilters }))
            .then(({ items, totalSize, from }) => {
              setHasNextPage(totalSize > from + items?.length)
              setTotalSize(totalSize)
              setSymbols(items)
            })
            .finally(() => dispatch(globalActions.handleRequest({ id, isResponse: true })))
        }, [dispatch, groupFilter, metadatasFilter, query, symbolsFilter, userDefinedFilters]),
        onScroll = useCallback(() => 
          dispatch(creatorSeriesActions.scrollSymbols({ query, symbolsFilter, metadatasFilter, groupFilter, userDefinedFilters, from: symbols.length }))
            .then(({ items, totalSize, from }) => {
              if(from === symbols.length) {
                setSymbols(symbols => symbols.concat(items || []))
                setHasNextPage(totalSize > from + items?.length)
                setTotalSize(totalSize)
              }
            })
        , [dispatch, groupFilter, metadatasFilter, query, symbols.length, symbolsFilter, userDefinedFilters]),
        onRowClick = useCallback(symbolIndex => {
          const symbol = symbols[symbolIndex]
          if(!symbol) {
            return;
          }
          dispatch(creatorSeriesActions.swapSelectedSymbol(ReduxService.updateObject(symbol, { pattern: "false" })))
        }, [dispatch, symbols]),
        createPattern = useCallback(() => {
          dispatch(creatorSeriesActions.createPattern())
          dispatch(globalActions.getMessageStore()).success("Matching data added to the basket.")
        }, [dispatch]),
        openBasket = useCallback(() => dispatch(creatorSeriesActions.onChange(true, "isBasketOpen")), [dispatch]),
        resultsStyle = useMemo(() => getResultsStyle(40), [getResultsStyle]),
        scrollContRef = useRef()

  useEffect(() => {
    searchSymbolsCall()
  }, [searchSymbolsCall])

  useEffect(() => () => {
    dispatch(creatorSeriesActions.terminateSearchSymbolsRequests())
  }, [dispatch])

  return (
    <div 
        className="ng-office-app__authed__content__results"
        style={resultsStyle}
    >
      <div className="ng-office-app__authed__content__results__headers">
        <When condition={symbols.length > 0}>
          {() => (
            <Fragment>
              {userDefinedFilters.map(udf => (
                <div 
                    className="ng-office-app__authed__content__results__headers__header"
                    key={udf.filterKey}
                >
                  {udf.type === creatorSeriesActions.FILTER_TYPE.GROUP_NAME ? "Group" : udf.label}
                </div>
              ))}
              {Object.keys(symbols[0]?.symbols || {}).filter(symbolKey => userDefinedFilters.findIndex(udf => udf.type === creatorSeriesActions.FILTER_TYPE.SYMBOL && udf.filterKey === symbolKey) === -1).map(symbolKey => (
                <div 
                    className="ng-office-app__authed__content__results__headers__header"
                    key={symbolKey}
                >
                  {symbolKey}
                </div>
              ))}
              {Object.keys(symbols[0]?.metadata || {}).filter(metadataKey => userDefinedFilters.findIndex(udf => udf.type === creatorSeriesActions.FILTER_TYPE.METADATA && udf.filterKey === metadataKey) === -1).map(metadataKey => (
                <div 
                    className="ng-office-app__authed__content__results__headers__header"
                    key={metadataKey}
                >
                  {metadataKey}
                </div>
              ))}
              <When condition={userDefinedFilters.findIndex(udf => udf.type === creatorSeriesActions.FILTER_TYPE.GROUP_NAME) === -1}>
                {() => (
                  <div className="ng-office-app__authed__content__results__headers__header">
                    Group
                  </div>
                )}
              </When>
            </Fragment>
          )}
        </When>
      </div>
      <div 
          className="ng-office-app__authed__content__results__body"
          ref={scrollContRef}
      >
        <InfiniteScroll
            className="ng-office-app__authed__content__results__body__scroll"
            hasMore={hasNextPage}
            initialLoad={false}
            loadMore={onScroll}
            threshold={800}
            useWindow={false}
        >
          {symbols.map((symbol, symbolIndex) => {
            const { 
              isDirectlySelected,
              isPatternSelected
            } = creatorSeriesActions.isInBasket(symbol, selectedSymbols)
            return (
              <SymbolRow
                  {...symbol}
                  isPatternSelected={isPatternSelected}
                  isSelected={isDirectlySelected}
                  key={symbolIndex}
                  onRowClick={isPatternSelected && !isDirectlySelected ? openBasket : onRowClick}
                  scrollContRef={scrollContRef}
                  symbolIndex={symbolIndex}
                  userDefinedFilters={userDefinedFilters}
              />
            )
          })}
        </InfiniteScroll>
        <When condition={symbols.length === 0 && (query || Object.keys(metadatasFilter || {}).length > 0 || Object.keys(symbolsFilter || {}).length > 0 || groupFilter) && !isSearchFetching}>
            {() => (
              <NoResults/>
            )}
          </When>
      </div>
      <div className="ng-office-app__authed__content__results__footer">
        <Button
            onClick={createPattern}
            size="small"
            variant="secondary"
        >
          <Add
              color="blue-bright"
              height={12}
              width={12}
          />
          <div>Add all matching data</div>
        </Button>
        <div className="ng-office-app__authed__content__results__footer__counter">
          {isSearchFetching ? "" : `${Number(symbols.length > totalSize ? totalSize : symbols.length).toLocaleString("en-EN")} of ${Number(totalSize).toLocaleString("en-EN")}`}
        </div>
      </div>
    </div>
  )
}

Results.defaultProps = {
  groupFilter: undefined,
  metadatasFilter: {},
  symbolsFilter: {}
}
Results.propTypes = {
  getResultsStyle: PropTypes.func.isRequired,
  groupFilter: PropTypes.string,
  metadatasFilter: PropTypes.object,
  symbolsFilter: PropTypes.object,
}

export default Results;