import { ActionService, dateBetweenTypes, formatTypes, TimeService, tsDataRangesObject } from 'dashboard-services';

import FORMULAS from 'resources/constants/Formulas.js';
import { CurvesUtils, FormulaUtils, GAUtils } from 'utils';

import { settingsCurvesActions } from './';

import dayjs from 'dayjs';

export default (() => {
  const ON_CHANGE = 'ON_CHANGE_CREATOR_CURVES'
  const onChange = ActionService.makeActionCreator(ON_CHANGE, 'value', 'name')
  
  const ADD_CURVE = 'ADD_CURVE_CREATOR_CURVES'
  const addCurve = ActionService.makeActionCreator(ADD_CURVE, 'curve')

  const REMOVE_CURVE = 'REMOVE_CURVE_CREATOR_CURVES'
  const removeCurve = ActionService.makeActionCreator(REMOVE_CURVE, 'index')

  const ON_REORDER_ITEM = 'ON_REORDER_ITEM_CREATOR_CURVES'
  const onReorderItem = ActionService.makeActionCreator(ON_REORDER_ITEM, 'from', 'to', 'pattern')

  const CLEAR = 'CLEAR_CREATOR_CURVES'
  const clear = ActionService.makeActionCreator(CLEAR)

  const getParams = () => (_, getState) => getState().seriesState.settingsState

  const addToSheet = () => async (dispatch, getState) => {
    GAUtils.sendEvent({
      category: "action",
      action: "add_curve",
      label: "add"
    })
    const paramsExceedingLength = []
    let formula = `=${FORMULAS.LOAD_FC}(`
    const { range, from, fromTime, fromSelected, to, toTime, toSelected, lastType, lastTypeAmount, csvHeaders, ...settings } = getState().curvesState.settingsState
    const { address, selectedCurves, selectedColumns } = getState().curvesState.creatorState

    selectedCurves.forEach(({ groupName, product, root }, index) => {
      formula += FormulaUtils.addAndValidateParam({ paramName: "groupName", paramValue: groupName, paramsExceedingLength, withComma: index > 0 })
      formula += FormulaUtils.addAndValidateParam({ paramName: "product", paramValue: product, paramsExceedingLength })
      formula += FormulaUtils.addAndValidateParam({ paramName: "root", paramValue: root, paramsExceedingLength })
      formula += FormulaUtils.addAndValidateParam({ paramName: "column", paramValue: selectedColumns, paramsExceedingLength })
    })
    formula += FormulaUtils.addAndValidateParam({ paramName: "range", paramValue: range, paramsExceedingLength })
    if(range === tsDataRangesObject.between) {
      const startDateValue = fromSelected === dateBetweenTypes.date ? dayjs(from + (fromTime ? "T" + fromTime : "T00:00:00.000")).format(TimeService.ISO_DATE_TIME_FORMAT_BACKEND_WITH_SECONDS_STYLE) : fromSelected
      formula += FormulaUtils.addAndValidateParam({ paramName: "startDate", paramValue: startDateValue, paramsExceedingLength })
      const endDateValue = toSelected === dateBetweenTypes.date ? dayjs(to  + (toTime ? "T" + toTime : "T23:59:59.999")).format(TimeService.ISO_DATE_TIME_FORMAT_BACKEND_WITH_SECONDS_STYLE) : toSelected
      formula += FormulaUtils.addAndValidateParam({ paramName: "endDate", paramValue: endDateValue, paramsExceedingLength })
    }
    if(range === tsDataRangesObject.last || range === tsDataRangesObject.next) {
      formula += FormulaUtils.addAndValidateParam({ paramName: "lastType", paramValue: lastType, paramsExceedingLength })
      formula += FormulaUtils.addAndValidateParam({ paramName: "lastTypeAmount", paramValue: lastTypeAmount, paramsExceedingLength })
    }
    Object.entries(settings || {}).filter(([paramName, paramValue]) => paramName !== undefined && paramName !== "undefined" && paramValue !== undefined).forEach(([paramName, paramValue]) => {
      formula += FormulaUtils.addAndValidateParam({ paramName, paramValue, paramsExceedingLength })
    })
    if(csvHeaders) {
      const prepColumHeader = FormulaUtils.prepColumnHeaders({ csvHeaders, formatType: formatTypes.CSV })
      formula += FormulaUtils.addAndValidateParam({ paramName: "csvHeaders", paramValue: `""[${prepColumHeader}]""`, paramsExceedingLength })
    }
    formula += ")"
    const setShowTooLongFormula = val => dispatch(onChange(val, "showTooLongFormula"))
    const setAddress = addressLocal => dispatch(onChange(addressLocal, "address"))
    await FormulaUtils.formulaValidator({ formula, paramsExceedingLength, address, setShowTooLongFormula, setAddress })
  }

  const editFormula = ({ address, keys, ...params }) => dispatch => {
    dispatch(clear())
    dispatch(settingsCurvesActions.clear())
    CurvesUtils.PLAIN_PARSED_CURVE_PARAMS.filter(param => params[param] && params[param] !== "undefined").forEach(param => dispatch(settingsCurvesActions.onChange(params[param], param)))
    if(params.startDate === dateBetweenTypes.today) {
      dispatch(settingsCurvesActions.onChange(dateBetweenTypes.today, "fromSelected"))
      dispatch(settingsCurvesActions.onChange(undefined, "from"))
    } else if(params.startDate) {
      dispatch(settingsCurvesActions.onChange(dateBetweenTypes.date, "fromSelected"))
      dispatch(settingsCurvesActions.onChange(String(params.startDate).split("T")[0], "from"))
    }
    if(params.endDate === dateBetweenTypes.today) {
      dispatch(settingsCurvesActions.onChange(dateBetweenTypes.today, "toSelected"))
      dispatch(settingsCurvesActions.onChange(undefined, "to"))
    } else if(params.endDate) {
      dispatch(settingsCurvesActions.onChange(dateBetweenTypes.date, "toSelected"))
      dispatch(settingsCurvesActions.onChange(String(params.endDate).split("T")[0], "to"))
    }
    const selectedColumns = new Set()
    keys.forEach(key => selectedColumns.add(key.column))
    dispatch(onChange(keys, "selectedCurves"))
    dispatch(onChange(Array.from(selectedColumns), "selectedColumns"))
    dispatch(onChange(address, "address"))
    dispatch(onChange(true, "isBasketOpen"))
  }

  return {
    onChange,
    ON_CHANGE,
    ON_REORDER_ITEM,
    onReorderItem,
    getParams,
    ADD_CURVE,
    addCurve,
    REMOVE_CURVE,
    removeCurve,
    CLEAR,
    clear,
    addToSheet,
    editFormula
  }
})()