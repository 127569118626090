import { Fragment } from 'react';
import PropTypes from 'prop-types';

import SectionTitle from '../SectionTitle';

const TimeAndDate = ({ children }) => (
  <Fragment>
    <SectionTitle>
      TIME AND DATE
    </SectionTitle>
    {children}
  </Fragment>
)

TimeAndDate.propTypes = {
  children: PropTypes.any.isRequired, 
}

export default TimeAndDate;