import PropTypes from 'prop-types';

import { ListRow, WithActions } from 'basic-components';

import Actions from './Actions';

const AddFileRow = ({ scrollRef, groupName, id, name, ...rest }) => (
  <WithActions
      actions={(
        <Actions
            {...rest}
            groupName={groupName}
            id={id}
            name={name}
        />
      )}
  >
    <ListRow 
        className="ng-office-app__authed__content__body__item__body__scroll__row ng-office-app__authed__content__body__item__body__scroll__row--add-file"
        scrollContRef={scrollRef}
    >
      <div className="ng-office-app__authed__content__body__item__body__scroll__row__cell ng-office-app__authed__content__body__item__body__scroll__row__cell--name">
        {name}
      </div>
      <div className="ng-office-app__authed__content__body__item__body__scroll__row__cell ng-office-app__authed__content__body__item__body__scroll__row__cell--group">
        {groupName}
      </div>
    </ListRow>
  </WithActions>
)

AddFileRow.propTypes = {
  groupName: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  scrollRef: PropTypes.any.isRequired
}

export default AddFileRow;