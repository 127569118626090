import { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import { creatorCurvesActions } from 'actions/curves';
import { routing } from 'routing';
import { ExcelUtils } from 'utils';

import Row from '../Row';

const CurveRow = ({ address, clearTable, formula, refreshCall, parsedParameters }) => {
  const dispatch = useDispatch(),
        goToAddress = useCallback(() => ExcelUtils.goToAddress(address), [address]),
        editSerie = useCallback(() => {
          const groupedParams = parsedParameters.getGroupedParams()
          ExcelUtils.openTaskPaneComponent(routing.CURVES.name)
          dispatch(creatorCurvesActions.editFormula({ 
            address, 
            keys: groupedParams,
            ...groupedParams.parsedParameters
          }))
        }, [address, dispatch, parsedParameters]),
        groupNames = useMemo(() => parsedParameters.getGroupedParams().map(i => i.groupName).filter(i => i).toUnique(), [parsedParameters]),
        products = useMemo(() => parsedParameters.getGroupedParams().map(i => i.product).filter(i => i).toUnique(), [parsedParameters]),
        roots = useMemo(() => parsedParameters.getGroupedParams().map(i => i.root).filter(i => i).toUnique(), [parsedParameters]),
        column = useMemo(() => parsedParameters.getGroupedParams()?.[0].column, [parsedParameters])

  return (
    <Row
        address={address}
        formula={formula}
        onClear={clearTable}
        onEdit={editSerie}
        onRefresh={refreshCall}
    >
      <div className="ng-office-app__authed__content__active-calls__row__cell ng-office-app__authed__content__active-calls__row__cell--group">
        <div className="ng-office-app__authed__content__active-calls__row__cell__top">
          {`Group${groupNames.length > 1 ? "s" : ""}:`}
        </div>
        <div className="ng-office-app__authed__content__active-calls__row__cell__bottom">
          {groupNames.join(", ") || "-"}
        </div>
      </div>
      <div className="ng-office-app__authed__content__active-calls__row__cell ng-office-app__authed__content__active-calls__row__cell--address">
        <div className="ng-office-app__authed__content__active-calls__row__cell__top">
          Cell reference:
        </div>
        <div 
            className="ng-office-app__authed__content__active-calls__row__cell__bottom"
            onClick={goToAddress}
        >        
          {address}
        </div>
      </div>
      <div className="ng-office-app__authed__content__active-calls__row__cell ng-office-app__authed__content__active-calls__row__cell--product">
        <div className="ng-office-app__authed__content__active-calls__row__cell__top">
          {`Product${products.length > 1 ? "s" : ""}:`}
        </div>
        <div className="ng-office-app__authed__content__active-calls__row__cell__bottom">        
          {products.join(", ") || "-"}
        </div>
      </div>
      <div className="ng-office-app__authed__content__active-calls__row__cell ng-office-app__authed__content__active-calls__row__cell--root">
        <div className="ng-office-app__authed__content__active-calls__row__cell__top">
          {`Root${roots.length > 1 ? "s" : ""}:`}
        </div>
        <div className="ng-office-app__authed__content__active-calls__row__cell__bottom">        
          {roots.join(", ") || "-"}
        </div>
      </div>
      <div className="ng-office-app__authed__content__active-calls__row__cell ng-office-app__authed__content__active-calls__row__cell--columns">
        <div className="ng-office-app__authed__content__active-calls__row__cell__top">
          Column:
        </div>
        <div className="ng-office-app__authed__content__active-calls__row__cell__bottom">        
          {column}
        </div>
      </div>
    </Row>
  )
}

CurveRow.defaultProps = {
  address: "",
  parsedParameters: {}
}

CurveRow.propTypes = {
  address: PropTypes.string,
  clearTable: PropTypes.func.isRequired,
  formula: PropTypes.string.isRequired,
  parsedParameters: PropTypes.object,
  refreshCall: PropTypes.func.isRequired,
}

export default CurveRow;