import { ReduxService } from 'dashboard-services';

import { creatorCurvesActions } from 'actions/curves';

export const creatorInitialState = {
  address: undefined,
  selectedCurves: [],
  selectedColumns: [],

  isBasketOpen: false,
  showTooLongFormula: false
}

export default (state = creatorInitialState, action) => {
  switch (action.type) {
    case creatorCurvesActions.ON_CHANGE:
      return ReduxService.updateObject(state, { [action.name]: action.value })
    case creatorCurvesActions.ADD_CURVE:
      return ReduxService.updateObject(state, { selectedCurves: state.selectedCurves.add(action.curve) })
    case creatorCurvesActions.REMOVE_CURVE:
      return ReduxService.updateObject(state, { selectedCurves: state.selectedCurves.remove(action.index) })
    case creatorCurvesActions.ON_REORDER_ITEM:
      return ReduxService.updateObject(state, { selectedCurves: state.selectedCurves.moveItem(action.from, action.to) })
    case creatorCurvesActions.CLEAR:
      return creatorInitialState;
    default:
      return state;
  }
}