import { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { ClearTable as ClearTableIcon, Edit, Refresh, WithTooltip } from 'basic-components';
import { globalActions } from 'primary-components';

import { When } from 'react-if';

import classNames from 'classnames';
import './Row.scss';

const Row = ({ address, onClear, formula, onRefresh, onEdit, children }) => {
  const dispatch = useDispatch(),
        [isOverflowed, setIsOverflowed] = useState(false),
        taskPaneWidth = useSelector(state => state.globalState.taskPaneWidth),
        refreshCallLocal = useCallback(() => 
          onRefresh({ formula, address })
            .then(() => dispatch(globalActions.getMessageStore()).success("Formula refreshed."))
        , [address, dispatch, formula, onRefresh]),
        clearTableLocal = useCallback(() => onClear(address), [address, onClear]),
        timeoutRef = useRef(),
        ref = useRef()

  useEffect(() => {
    if(ref.current) {
      setIsOverflowed(ref.current.scrollWidth > ref.current.clientWidth)
    } else {
      window.clearTimeout(timeoutRef.current)
      timeoutRef.current = window.setTimeout(() => setIsOverflowed(ref.current.scrollWidth > ref.current.clientWidth), 5)
    }
  }, [taskPaneWidth])

  useEffect(() => () => {
    window.clearTimeout(timeoutRef.current)
  }, [])

  return (
    <div className={classNames(
      "ng-office-app__authed__content__active-calls__row",
      { "ng-office-app__authed__content__active-calls__row--with-edit": !!onEdit } 
    )}>
      <div 
          className={classNames(
            "ng-office-app__authed__content__active-calls__row__content",
            { "ng-office-app__authed__content__active-calls__row__content--is-overflowed": isOverflowed }
          )}
          ref={ref}
      >
        {children}
      </div>
      <div className="ng-office-app__authed__content__active-calls__row__cell ng-office-app__authed__content__active-calls__row__cell--icons">
        <WithTooltip
            data-place="left"
            text="Download latest data."
        >
          <div 
              className="ng-office-app__authed__content__active-calls__row__cell__icon"
              onClick={refreshCallLocal}
              style={{ marginRight: "16px" }}
          >
            <Refresh
                color="blue-bright"
                height={16}
                width={16}
            />
          </div>
        </WithTooltip>
        <When condition={!!onEdit}>
          {() => (
            <WithTooltip
                data-place="left"
                text="Edit formula."
            >
              <div 
                  className="ng-office-app__authed__content__active-calls__row__cell__icon"
                  onClick={onEdit}
                  style={{ marginRight: "16px" }}
              >
                <Edit
                    color="blue-bright"
                    height={16}
                    width={16}
                />
              </div>
            </WithTooltip>
          )}
        </When>
        <WithTooltip
            data-place="left"
            text="Clear table and formula."
        >
          <div 
              className="ng-office-app__authed__content__active-calls__row__cell__icon"
              onClick={clearTableLocal}
          >
            <ClearTableIcon
                color="red-dark"
                height={16}
                width={16}
            />
          </div>
        </WithTooltip>
      </div>
    </div>
  )
}
Row.defaultProps = {
  address: "",
  onEdit: undefined
}

Row.propTypes = {
  address: PropTypes.string,
  children: PropTypes.any.isRequired,
  formula: PropTypes.string.isRequired,
  onClear: PropTypes.func.isRequired,
  onEdit: PropTypes.func,
  onRefresh: PropTypes.func.isRequired,
}

export default Row;