import { ActionService, DataLakeApi } from 'dashboard-services';
import { globalActions } from 'primary-components';

import getApiConfig from 'api/ApiConfig';
import { ExcelUtils } from 'utils';

export default (() => {

  const ON_CHANGE = 'ON_CHANGE_UPLOAD'
  const onChange = ActionService.makeActionCreator(ON_CHANGE, 'value', 'name')

  const SET_ERRORS = 'SET_ERRORS_DL_GROUPS'
  const setErrors = ActionService.makeActionCreator(SET_ERRORS, 'errors')

  const CLEAR = 'CLEAR_UPLOAD'
  const clear = ActionService.makeActionCreator(CLEAR)

  const onGroupCreate = val => dispatch =>
    new DataLakeApi(dispatch(getApiConfig()))
      .addGroup(val)
      .withValidationHandler(response => dispatch(setErrors(response.validationErrors)))
      .withSuccessMessage("Group has been created.")
      .build()
      .call()

  const defineFileRange = () => dispatch =>
    ExcelUtils.getSelectedRange().then(response => {
      const { 
        sheetName,
        firstCell,
        lastCell
      } = response
      
      dispatch(onChange(sheetName, 'sheetName'))
      dispatch(onChange(firstCell, 'firstCell'))
      dispatch(onChange(lastCell, 'lastCell'))
      if(firstCell && !lastCell) {
        dispatch(globalActions.getMessageStore()).info("Only one cell selected.")
      }
    })

  return {
    onChange,
    onGroupCreate,
    setErrors,
    ON_CHANGE,
    SET_ERRORS,
    clear,
    CLEAR,
    defineFileRange
  }
})()