import { applyMiddleware, createStore } from 'redux';
import thunkMiddleware from 'redux-thunk';

import { tsDataRangesObject } from 'dashboard-services';

import { getInitialState, officeApp } from 'reducers';
import FORMULAS from 'resources/constants/Formulas.js';
import properties from 'resources/constants/properties.json';
import { AuthUtils, CurvesUtils, ExcelUtils, FileUtils, GAUtils, Parameters, TimeSeriesUtils } from 'utils';

const FUNCTIONS_NAMESPACE = properties.functionsNamespace

if (module.hot) {
  module.hot.accept();
} 

if(!window["ngStore" + FUNCTIONS_NAMESPACE]) window["ngStore" + FUNCTIONS_NAMESPACE] = createStore((state, action) => officeApp(state, action), getInitialState(), applyMiddleware(thunkMiddleware))

const sendError = async ({ error }) => await window["ngStore" + FUNCTIONS_NAMESPACE].dispatch(AuthUtils.sendError({ error }))

if(!window.onNgError) {
  addEventListener('error',  e => {
    const error = "(ErrorListener)" + e.error.stack
    sendError({ error })
  })
  window.onNgError = true;
}

/**
 * Gets the content of the latest file with the given name and given group from Datalake. Inserts it as the table beneath the formula field.
 * @customfunction GET.FILE GET.FILE
 * @requiresAddress
 * @param {string} fileName Name of the Data Lake file that will be downloaded. Exact match.
 * @param {string} groupName Name of the Data Lake group, in which the file is.
 * @param {string[]} [parameters] Optional parameters
 * @param {CustomFunctions.Invocation} invocation Invocation object.
 * @return {string} Name of the file and table beneath.
 */

export async function fileLoad(fileName, groupName, parameters, invocation) {
  try {
    const parsedParameters = new Parameters.Builder()
      .withRequiredParameter("fileName", fileName)
      .withRequiredParameter("groupName", groupName)
      .withUserParameters(parameters)
      .build();
    await window["ngStore" + FUNCTIONS_NAMESPACE].dispatch(AuthUtils.validateToken())
    GAUtils.sendEvent({
      category: "view_item",
      action: FORMULAS.LOAD_FILE,
      label: "function"
    })
    const address = invocation.address
    const searchResponse = await window["ngStore" + FUNCTIONS_NAMESPACE].dispatch(FileUtils.searchFilesCore({ query: `name.keyword=${parsedParameters.fileName}&groupName=${parsedParameters.groupName}&latest=true`, size: 1, noFetching: false }))
    const fid = searchResponse.items?.[0]?.fid
    if(!fid) {
      throw new CustomFunctions.Error(CustomFunctions.ErrorCode.notAvailable, "File not found!")
    }
    const response = await window["ngStore" + FUNCTIONS_NAMESPACE].dispatch(FileUtils.readFile({ fileId: fid }))
    await ExcelUtils.insertTable({ values: response, address, tableName: FileUtils.FILE_PREFIX + parsedParameters.fileName + FileUtils.GROUP_PREFIX + parsedParameters.groupName })
    return parsedParameters.fileName
  } catch(e) {
    console.error(e)
    sendError({ error: "(GET.FILE Function)" + e.stack })
    // throw new CustomFunctions.Error(CustomFunctions.ErrorCode.notAvailable, e.message)
    return e.message
  }
}

/**
 * Gets the latest data for the given symbols pattern from given group. Inserts it as the table beneath the formula field.
 * @customfunction GET.TS GET.TS
 * @requiresAddress
 * @param {string[]} parameters groupName, symbols, columns, metadatas or other supported parameters
 * @param {CustomFunctions.Invocation} invocation Invocation object.
 * @return {string} Name
 */

export async function getTimeSeries(parameters, invocation) {
  try {
    const parsedParameters = new Parameters.Builder()
      .withUserParameters(parameters)
      .withGrouping(["groupName", "columns", "symbols", "metadatas", "pattern", "query"])
      .build();
    // .withGroupingWithIndex(["metadata", "offsetType", "offsetAmount", "fillType"])
    console.info("PARAMETERS", parsedParameters)
    const keys = parsedParameters.getGroupedParams() 
    if(keys.length < 1 || !keys[0].columns) {
      throw new CustomFunctions.Error(CustomFunctions.ErrorCode.notAvailable, "Columns has to be provided.")
    }
    if(String(parsedParameters.order).toLowerCase() === "asc" && String(parsedParameters.range).toLowerCase() === String(tsDataRangesObject.last).toLowerCase()) {
      throw new CustomFunctions.Error(CustomFunctions.ErrorCode.notAvailable, "Ascending order can not be used with range 'last'.")
    }
    await window["ngStore" + FUNCTIONS_NAMESPACE].dispatch(AuthUtils.validateToken())
    GAUtils.sendEvent({
      category: "view_item",
      action: FORMULAS.LOAD_TS,
      label: "function"
    })
    const address = invocation.address
    const dataResponse = await window["ngStore" + FUNCTIONS_NAMESPACE].dispatch(await TimeSeriesUtils.getData({
      ...parsedParameters,
      keys
    }))
    if(!dataResponse) {
      throw new CustomFunctions.Error(CustomFunctions.ErrorCode.notAvailable, "No data returned in the response!")
    }
    const unique = keys.filter(k => Object.keys(k.symbols || {}).length > 0 || Object.keys(k.metadatas || {}).length > 0).map(k => Object.values(k.symbols || {}).concat(Object.values(k.metadatas || {}))).flat().join(", ")
    await ExcelUtils.insertTable({ values: dataResponse, address, tableName: TimeSeriesUtils.TS_PARAMS_PREFIX + unique, shouldTranspose: parsedParameters.shouldTranspose === "true" })
    return unique;
  } catch (e) {
    console.error(e)
    sendError({ error: "(GET.FILE Function)" + e.stack })
    return e.message
    // throw new CustomFunctions.Error(CustomFunctions.ErrorCode.notAvailable, e.message)
  }
}

/**
 * Gets the curve data for the given symbols pattern from given group. Inserts it as the table beneath the formula field.
 * @customfunction GET.FC GET.FC
 * @requiresAddress
 * @param {string[]} parameters groupName, column, product, root and other supported parameters i.e. timeZone, dateFormat
 * @param {CustomFunctions.Invocation} invocation Invocation object.
 * @return {string} Root
 */

export async function getForwardCurve(parameters, invocation) {
  try {
    const parsedParameters = new Parameters.Builder()
      .withUserParameters(parameters)
      .withGrouping(["groupName", "column", "product", "root"])
      .build();
    console.info("PARAMETERS", parsedParameters)
    await window["ngStore" + FUNCTIONS_NAMESPACE].dispatch(AuthUtils.validateToken())
    GAUtils.sendEvent({
      category: "view_item",
      action: FORMULAS.LOAD_FC,
      label: "function"
    })
    const address = invocation.address
    const keys = parsedParameters.getGroupedParams() 
    const dataResponse = await window["ngStore" + FUNCTIONS_NAMESPACE].dispatch(await CurvesUtils.getForwardCurve({
      keys,
      ...parsedParameters
    }))
    if(!dataResponse) {
      throw new CustomFunctions.Error(CustomFunctions.ErrorCode.notAvailable, "No data returned in the response!")
    }
    const unique = keys.map(k => k.groupName + ", " + k.product + ", " + k.root).flat().join(", ")
    await ExcelUtils.insertTable({ values: dataResponse, address, tableName: CurvesUtils.FC_PARAMS_PREFIX + unique })
    return unique;
  } catch (e) {
    console.error(e)
    sendError({ error: "(GET.FC Function)" + e.stack })
    // throw new CustomFunctions.Error(CustomFunctions.ErrorCode.notAvailable, e.message)
    return e.message
  }
}

/**
 * Gets the latest data for the given symbols pattern from given group. Inserts it as the table beneath the formula field.
 * @customfunction REFRESH REFRESH
 * @param {string} [address] Address of cell or sheet that should be refreshed. Active sheet if left empty.
 * @return {string} Done
 */
export async function refresh(address) {
  try {
    await window["ngStore" + FUNCTIONS_NAMESPACE].dispatch(AuthUtils.validateToken())
    GAUtils.sendEvent({
      category: "view_item",
      action: FORMULAS.REFRESH,
      label: "function"
    })
    if(address) {
      if(String(address).split("!")?.length > 1) {
        await Excel.run(async context => {
          const worksheet = context.workbook.worksheets.getItem(ExcelUtils.getWorksheetFromAddress(address)),
                range = worksheet.getRange(address.split("!")[1])
          range.calculate();
          await context.sync();
        })
      } else {
        await Excel.run(async context => {
          const worksheet = context.workbook.worksheets.getItem(address)
          await ExcelUtils.refreshAllFormulasInSheet({ worksheet, context })
        });
      }
    } else {
      await Excel.run(async context => {
        const activeWorksheet = context.workbook.worksheets.getActiveWorksheet();
        await ExcelUtils.refreshAllFormulasInSheet({ worksheet: activeWorksheet, context })
      });
    }
    return "Done";
  } catch (e) {
    console.error(e)
    sendError({ error: "(REFRESH Function)" + e.stack })
    throw new CustomFunctions.Error(CustomFunctions.ErrorCode.notAvailable, e.message)
  }
}


/**
 * Gets the latest data for the given symbols pattern from given group. Inserts it as the table beneath the formula field.
 * @customfunction REFRESH.ALL REFRESH.ALL
 * @return {string} Done
 */
export async function refreshAll() {
  try {
    await window["ngStore" + FUNCTIONS_NAMESPACE].dispatch(AuthUtils.validateToken())
    GAUtils.sendEvent({
      category: "view_item",
      action: FORMULAS.REFRESH_ALL,
      label: "function"
    })
    await Excel.run(async context => {
      await ExcelUtils.refreshAllFormulas({ context })
    });
    return "Done";
  } catch (e) {
    console.error(e)
    sendError({ error: "(REFRESH.ALL Function)" + e.stack })
    throw new CustomFunctions.Error(CustomFunctions.ErrorCode.notAvailable, e.message)
  }
}
CustomFunctions.associate("GET.FILE", fileLoad);
CustomFunctions.associate("GET.TS", getTimeSeries);
CustomFunctions.associate("GET.FC", getForwardCurve);
CustomFunctions.associate("REFRESH", refresh);
CustomFunctions.associate("REFRESH.ALL", refreshAll);